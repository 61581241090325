/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const FilterOperation = {
    EqOrContains: 'EQ_OR_CONTAINS',
    Lt: 'LT',
    Gt: 'GT',
    Lte: 'LTE',
    Gte: 'GTE',
    Substr: 'SUBSTR',
    RegexMatch: 'REGEX_MATCH'
} as const;

export type FilterOperation = typeof FilterOperation[keyof typeof FilterOperation];



