import * as d3 from 'd3';
import { format } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useChartResize } from 'src/utils/common';
import { getCompactNumber } from 'src/utils/common';

interface GovernanceTrendChartProps {
  data: {
    timestamp: number;
    total: number;
    approved: number;
    unapproved: number;
    unvetted: number;
    restricted: number;
  }[];
  labelFormat: string;
  yAxisLabel: string;
}

const toolTipConfig = { width: 180, height: 164 };

const GovernanceTrendChart = ({
  data,
  labelFormat,
  yAxisLabel,
}: GovernanceTrendChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const updateCount = useChartResize({ ref: svgRef, data });

  useEffect(() => {
    if (svgRef.current) {
      const svg = d3.select(svgRef.current);
      svg.selectAll('*').remove();
      const axes = svg.append('g').attr('transform', 'translate(0,0)');

      const { height, width } = svgRef.current?.getBoundingClientRect();
      const yMin = 0;
      const yMax = Math.max(...data.map((d) => d.total)) * 1.2;

      const xScale = d3
        .scaleBand()
        .padding(0.81)
        .domain(data.map((d) => d.timestamp.toString()))
        .range([50, width]);

      const legendBandHeight = 32;

      const yScale = d3
        .scaleLinear()
        .domain([Math.max(0, yMin), yMax])
        .range([height - legendBandHeight, 0]);

      // x-Axis - First set of ticks (even indices)
      axes
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0,${height - legendBandHeight + 6})`)
        .call(
          d3
            .axisBottom(xScale)
            .tickSize(2)
            .tickFormat((d: string) => {
              return format(+d, 'd');
            })
        );

      // x-Axis - Second set of ticks (odd indices)
      axes
        .append('g')
        .attr('class', 'x-axis-secondary text-[11px]')
        .attr('transform', `translate(0,${height - legendBandHeight + 6})`)
        .call(
          d3
            .axisBottom(xScale)
            .tickSize(16)
            .tickValues(
              xScale.domain().filter(function (_, i) {
                if (width < 900) return !(i % 2);

                return true;
              })
            )
            .tickFormat((d: string) => {
              return format(+d, 'MMM');
            })
        );

      let tooltip: d3.Selection<SVGGElement, unknown, null, undefined>;
      // y-Axis
      axes
        .append('g')
        .attr('class', ' y-axis')
        .attr('transform', `translate(50,0)`)
        .call(
          d3
            .axisLeft(yScale)
            .ticks(5)
            .tickSizeOuter(0)
            .tickSize(50 - width)
            .tickFormat((d) => String(getCompactNumber(d.valueOf(), 1)))
        );
      axes
        .append('g')
        .attr('transform', `translate(-10,${height / 2})`)
        .append('text')
        .text(yAxisLabel)
        .attr('x', 0)
        .attr('y', 0)
        .attr('transform', `rotate(-90 0 0) translate(-25,25)`)
        .attr('font-size', '12px')
        .attr('class', '!fill-muted-foreground');
      svg
        .append('g')
        .selectAll()
        .data(data)
        .join('g')
        .attr(
          'transform',
          (d) =>
            `translate(${xScale(d.timestamp.toString()) ?? 0},${yScale(d.total)})`
        )
        .each(function (d) {
          let howManyBars = 0;
          if (d.approved > 0) {
            howManyBars++;
          }
          if (d.unapproved > 0) {
            howManyBars++;
          }
          if (d.restricted > 0) {
            howManyBars++;
          }

          let yOffset = -howManyBars * 2;
          d3.select(this)
            .append('rect')
            .attr('x', 0)
            .attr('y', yOffset)
            .attr('rx', 1)
            .attr('height', yScale(0) - yScale(d.approved))
            .attr('width', 6)
            .attr('class', `!fill-teal-500 approved-bar`);

          if (d.approved > 0) {
            yOffset += yScale(0) - yScale(d.approved) + 2;
          }

          d3.select(this)
            .append('rect')
            .attr('x', 0)
            .attr('y', yOffset)
            .attr('rx', 1)
            .attr('height', yScale(0) - yScale(d.unapproved))
            .attr('width', 6)
            .attr('class', `!fill-rose-400 unapproved-bar`);

          if (d.unapproved > 0) {
            yOffset += yScale(0) - yScale(d.unapproved) + 2;
          }

          d3.select(this)
            .append('rect')
            .attr('x', 0)
            .attr('y', yOffset)
            .attr('rx', 1)
            .attr('height', yScale(0) - yScale(d.restricted))
            .attr('width', 6)
            .attr('class', `!fill-slate-300 restricted-bar`);

          if (d.restricted > 0) {
            yOffset += yScale(0) - yScale(d.restricted) + 2;
          }
          d3.select(this)
            .append('rect')
            .attr('x', 0)
            .attr('y', Math.max(yOffset, 0))
            .attr('rx', 1)
            .attr('height', yScale(0) - yScale(d.unvetted))
            .attr('width', 6)
            .attr('class', `!stroke-blue-500 unvetted-bar`);
        })
        .on('mouseenter', function (ev: any, d) {
          const toolTipY = Math.min(ev.offsetY, height - toolTipConfig.height);
          let toolTipX = (xScale(d.timestamp.toString()) ?? 0) + 16;
          if (toolTipX + toolTipConfig.width > width) {
            toolTipX -= toolTipConfig.width + 24;
          }
          tooltip = svg.append('g').attr('id', 'tooltip');
          tooltip.attr('transform', `translate(${toolTipX},${toolTipY})`);

          tooltip
            .append('rect')
            .style('filter', 'drop-shadow(0px 4px 4px rgb(0 0 0 / 0.4)')
            .attr('x', 0)
            .attr('y', 0.5)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('class', 'fill-gray-900')
            .attr('stroke', 'rgba(29, 40, 58, 0.6)')
            .attr('stroke-width', 1)
            .attr('width', toolTipConfig.width)
            .attr('height', toolTipConfig.height);

          tooltip
            .append('text')
            .attr('font-size', 12)
            .text(`${format(d.timestamp, 'd MMM')} (${d.total})`)
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'hanging')
            .attr('x', 12)
            .attr('y', 16)
            .attr('class', '!fill-foreground');
          const rows = [
            {
              label: 'Approved',
              color: '!fill-teal-500 !stroke-teal-500',
              value: d.approved,
            },
            {
              label: 'Unapproved',
              color: '!fill-rose-400 !stroke-rose-400',
              value: d.unapproved,
            },
            {
              label: 'Restricted',
              color: '!fill-gray-300 !stroke-gray-300',
              value: d.restricted,
            },
            { label: 'Unvetted', color: '!stroke-blue-500', value: d.unvetted },
          ];

          rows.forEach((d, i) => {
            const row = tooltip
              .append('g')
              .attr('transform', `translate(12,${40 + i * 30})`);
            row
              .append('rect')
              .attr('x', 0)
              .attr('y', 0.5)
              .attr('rx', 2)
              .attr('ry', 2)
              .attr('width', 12)
              .attr('height', 12)
              .attr('class', d.color);
            row
              .append('text')
              .attr('font-size', 12)
              .text(`${d.label} : `)
              .attr('text-anchor', 'start')
              .attr('alignment-baseline', 'hanging')
              .attr('x', 20)
              .attr('y', 0.5)
              .attr('class', '!fill-muted-foreground');
            row
              .append('text')
              .attr('font-size', 12)
              .text(getCompactNumber(d.value, 2))
              .attr('text-anchor', 'end')
              .attr('alignment-baseline', 'hanging')
              .attr('x', 130)
              .attr('y', 0.5)
              .attr('class', '!fill-txt-primary');
          });
        })
        .on('mouseleave', function (_: any, d) {
          d3.select(this).attr(
            'transform',
            `translate(${xScale(d.timestamp.toString()) ?? 0},${yScale(d.total)})`
          );
          d3.select('#tooltip').remove();
        })
        .on('mousemove', function (ev: any, d) {
          const toolTipY = Math.min(ev.offsetY, height - toolTipConfig.height);
          let toolTipX = (xScale(d.timestamp.toString()) ?? 0) + 16;
          if (toolTipX + toolTipConfig.width > width) {
            toolTipX -= toolTipConfig.width + 24;
          }
          d3.select('#tooltip').attr(
            'transform',
            `translate(${toolTipX},${toolTipY})`
          );
        });
    }
  }, [JSON.stringify(data), labelFormat, updateCount, yAxisLabel]);
  return (
    <svg
      className="h-full w-full governance-chart overflow-visible"
      ref={svgRef}
    />
  );
};

export default GovernanceTrendChart;
