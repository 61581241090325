import { IconProps } from '..';

function EXTRAHOP(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      {...props}
    >
      <g id="surface1">
        <path d="M 0 0 C 13.199219 0 26.398438 0 40 0 C 40 13.199219 40 26.398438 40 40 C 26.800781 40 13.601562 40 0 40 C 0 26.800781 0 13.601562 0 0 Z M 0 0 " />
        <path
          fill="rgb(99.215686%,99.215686%,99.215686%)"
          d="M 32.699219 12.398438 C 33 12.625 33 12.625 33.199219 12.898438 C 33.25 12.957031 33.296875 13.015625 33.347656 13.074219 C 33.546875 13.367188 33.648438 13.660156 33.761719 13.992188 C 33.804688 14.113281 33.847656 14.234375 33.890625 14.359375 C 34.628906 16.960938 34.347656 20.769531 33.074219 23.132812 C 32.730469 23.714844 32.320312 24.203125 31.699219 24.5 C 31.410156 24.527344 31.140625 24.542969 30.851562 24.539062 C 30.726562 24.539062 30.726562 24.539062 30.597656 24.539062 C 30.320312 24.542969 30.042969 24.539062 29.765625 24.539062 C 29.574219 24.539062 29.378906 24.539062 29.1875 24.539062 C 28.78125 24.542969 28.378906 24.539062 27.972656 24.539062 C 27.453125 24.539062 26.9375 24.539062 26.417969 24.539062 C 26.019531 24.542969 25.621094 24.539062 25.222656 24.539062 C 25.03125 24.539062 24.839844 24.539062 24.648438 24.539062 C 24.382812 24.542969 24.113281 24.539062 23.847656 24.539062 C 23.730469 24.539062 23.730469 24.539062 23.609375 24.539062 C 23.152344 24.535156 22.863281 24.480469 22.5 24.199219 C 22.199219 23.871094 22.007812 23.546875 21.835938 23.136719 C 21.796875 23.042969 21.757812 22.949219 21.714844 22.851562 C 21.382812 21.835938 21.363281 20.816406 21.367188 19.757812 C 21.367188 19.679688 21.367188 19.605469 21.371094 19.527344 C 21.378906 17.625 21.394531 15.363281 22.800781 13.898438 C 23.144531 13.667969 23.453125 13.617188 23.855469 13.550781 C 23.96875 13.53125 23.96875 13.53125 24.082031 13.511719 C 24.703125 13.40625 25.328125 13.335938 25.953125 13.269531 C 26.960938 13.164062 27.957031 12.992188 28.953125 12.8125 C 29.5625 12.703125 30.171875 12.621094 30.78125 12.542969 C 30.976562 12.515625 31.167969 12.484375 31.359375 12.445312 C 31.804688 12.359375 32.246094 12.339844 32.699219 12.398438 Z M 32.699219 12.398438 "
        />
        <path
          fill="rgb(98.431373%,98.431373%,98.431373%)"
          d="M 20.199219 20.601562 C 20.210938 20.699219 20.21875 20.796875 20.230469 20.902344 C 20.394531 22.453125 20.703125 24.332031 21.925781 25.429688 C 22.355469 25.773438 22.703125 25.8125 23.25 25.820312 C 23.316406 25.820312 23.378906 25.824219 23.445312 25.824219 C 23.664062 25.828125 23.878906 25.828125 24.09375 25.832031 C 24.246094 25.832031 24.394531 25.835938 24.542969 25.839844 C 24.9375 25.84375 25.332031 25.847656 25.726562 25.851562 C 26.128906 25.859375 26.53125 25.863281 26.933594 25.871094 C 27.722656 25.878906 28.511719 25.890625 29.300781 25.898438 C 29.285156 26.042969 29.269531 26.183594 29.253906 26.324219 C 29.246094 26.402344 29.238281 26.480469 29.230469 26.5625 C 29.066406 27.890625 28.648438 29.222656 27.636719 30.136719 C 27.167969 30.457031 26.824219 30.53125 26.257812 30.53125 C 26.148438 30.535156 26.148438 30.535156 26.035156 30.535156 C 25.789062 30.539062 25.546875 30.539062 25.300781 30.539062 C 25.128906 30.542969 24.960938 30.542969 24.789062 30.542969 C 24.433594 30.546875 24.074219 30.546875 23.71875 30.546875 C 23.261719 30.550781 22.804688 30.554688 22.347656 30.558594 C 21.996094 30.5625 21.644531 30.5625 21.292969 30.566406 C 21.125 30.566406 20.957031 30.566406 20.789062 30.570312 C 19.308594 30.589844 19.308594 30.589844 18.855469 30.21875 C 18.203125 29.507812 18.074219 28.621094 17.898438 27.699219 C 17.882812 27.625 17.863281 27.546875 17.84375 27.46875 C 17.542969 25.609375 17.683594 22.828125 18.792969 21.242188 C 19.222656 20.726562 19.53125 20.585938 20.199219 20.601562 Z M 20.199219 20.601562 "
        />
        <path
          fill="rgb(98.823529%,98.823529%,98.823529%)"
          d="M 17.664062 14.570312 C 18.574219 15.652344 18.382812 17.386719 18.300781 18.699219 C 18.203125 19.675781 17.929688 20.53125 17.265625 21.257812 C 16.808594 21.628906 16.285156 21.574219 15.722656 21.570312 C 15.609375 21.570312 15.496094 21.570312 15.378906 21.570312 C 15.140625 21.574219 14.898438 21.574219 14.660156 21.570312 C 14.292969 21.570312 13.929688 21.574219 13.5625 21.578125 C 13.332031 21.578125 13.097656 21.578125 12.867188 21.578125 C 12.703125 21.578125 12.703125 21.578125 12.535156 21.582031 C 11.796875 21.570312 11.796875 21.570312 11.523438 21.375 C 10.863281 20.605469 10.847656 19.757812 10.851562 18.792969 C 10.851562 18.589844 10.847656 18.386719 10.839844 18.1875 C 10.839844 17.207031 10.9375 16.144531 11.597656 15.367188 C 11.882812 15.132812 12.125 15.101562 12.488281 15.042969 C 12.589844 15.027344 12.589844 15.027344 12.695312 15.007812 C 13.492188 14.878906 14.289062 14.773438 15.089844 14.667969 C 15.582031 14.605469 16.070312 14.523438 16.554688 14.433594 C 17.296875 14.332031 17.296875 14.332031 17.664062 14.570312 Z M 17.664062 14.570312 "
        />
        <path
          fill="rgb(98.823529%,98.823529%,98.823529%)"
          d="M 10.5 9.601562 C 10.875 9.933594 10.941406 10.214844 11 10.699219 C 11.136719 13.371094 11.136719 13.371094 10.300781 14.398438 C 9.8125 14.644531 9.066406 14.53125 8.527344 14.535156 C 8.351562 14.535156 8.175781 14.539062 8 14.542969 C 7.746094 14.546875 7.496094 14.550781 7.242188 14.550781 C 7.164062 14.554688 7.085938 14.558594 7.003906 14.558594 C 6.621094 14.558594 6.441406 14.539062 6.152344 14.273438 C 5.8125 13.671875 5.875 13.050781 5.867188 12.375 C 5.867188 12.265625 5.863281 12.152344 5.859375 12.039062 C 5.855469 11.34375 5.925781 10.894531 6.300781 10.300781 C 6.820312 9.78125 7.917969 9.820312 8.613281 9.738281 C 8.71875 9.722656 8.820312 9.710938 8.929688 9.695312 C 10.007812 9.5625 10.007812 9.5625 10.5 9.601562 Z M 10.5 9.601562 "
        />
        <path
          fill="rgb(93.333333%,93.333333%,93.333333%)"
          d="M 21.699219 25.300781 C 21.996094 25.464844 22.292969 25.628906 22.601562 25.800781 C 22.535156 25.867188 22.46875 25.933594 22.398438 26 C 22.050781 25.988281 22.050781 25.988281 21.699219 25.898438 C 21.554688 25.695312 21.554688 25.695312 21.5 25.5 C 21.566406 25.433594 21.632812 25.367188 21.699219 25.300781 Z M 21.699219 25.300781 "
        />
        <path
          fill="rgb(90.980392%,90.980392%,90.980392%)"
          d="M 18.800781 21.199219 C 18.933594 21.234375 19.0625 21.265625 19.199219 21.300781 C 19.199219 21.367188 19.199219 21.433594 19.199219 21.5 C 19.132812 21.566406 19.066406 21.632812 19 21.699219 C 18.96875 21.765625 18.933594 21.832031 18.898438 21.898438 C 18.734375 21.972656 18.570312 22.039062 18.398438 22.101562 C 18.507812 21.757812 18.601562 21.5 18.800781 21.199219 Z M 18.800781 21.199219 "
        />
        <path
          fill="rgb(93.72549%,93.72549%,93.72549%);fi"
          d="M 33.199219 22.101562 C 33.320312 22.261719 33.320312 22.261719 33.398438 22.5 C 33.261719 22.824219 33.261719 22.824219 33.101562 23.101562 C 33.035156 23.101562 32.96875 23.101562 32.898438 23.101562 C 32.910156 22.953125 32.925781 22.804688 32.9375 22.65625 C 32.945312 22.574219 32.953125 22.492188 32.957031 22.40625 C 32.972656 22.339844 32.984375 22.269531 33 22.199219 C 33.066406 22.167969 33.132812 22.132812 33.199219 22.101562 Z M 33.199219 22.101562 "
        />
        <path
          fill="rgb(85.882353%,85.882353%,85.882353%)"
          d="M 23.101562 13.699219 C 23.164062 13.734375 23.230469 13.765625 23.300781 13.800781 C 23.300781 13.867188 23.300781 13.933594 23.300781 14 C 23.234375 14 23.167969 14 23.101562 14 C 23.066406 14.066406 23.035156 14.132812 23 14.199219 C 22.933594 14.199219 22.867188 14.199219 22.800781 14.199219 C 22.660156 14.394531 22.527344 14.597656 22.398438 14.800781 C 22.335938 14.765625 22.269531 14.734375 22.199219 14.699219 C 22.292969 14.570312 22.386719 14.441406 22.480469 14.3125 C 22.535156 14.242188 22.585938 14.167969 22.640625 14.09375 C 22.800781 13.898438 22.800781 13.898438 23.101562 13.699219 Z M 23.101562 13.699219 "
        />
        <path
          fill="rgb(95.686275%,95.686275%,95.686275%)"
          d="M 18.957031 29.59375 C 19.078125 29.597656 19.078125 29.597656 19.199219 29.601562 C 19.167969 29.765625 19.132812 29.929688 19.101562 30.101562 C 19 30.101562 18.902344 30.101562 18.800781 30.101562 C 18.734375 29.96875 18.667969 29.835938 18.601562 29.699219 C 18.699219 29.601562 18.699219 29.601562 18.957031 29.59375 Z M 18.957031 29.59375 "
        />
        <path
          fill="rgb(95.294118%,95.294118%,95.294118%)"
          d="M 11.65625 20.894531 C 11.777344 20.898438 11.777344 20.898438 11.898438 20.898438 C 11.867188 21 11.835938 21.097656 11.800781 21.199219 C 11.832031 21.265625 11.867188 21.332031 11.898438 21.398438 C 11.710938 21.367188 11.710938 21.367188 11.5 21.300781 C 11.433594 21.199219 11.367188 21.101562 11.300781 21 C 11.398438 20.898438 11.398438 20.898438 11.65625 20.894531 Z M 11.65625 20.894531 "
        />
      </g>
    </svg>
  );
}

export default EXTRAHOP;
