import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/shadcn/card';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useQuery } from '@tanstack/react-query';
import { getUnixTime, subDays } from 'date-fns';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { allApi } from 'src/api';
import { assetsVendorServicesIconMap } from 'src/constants/Assets';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { getEntityTypeIcon } from 'src/pages/Dashboards/appDetails/app-topology/shared';
import SuiLoader from 'src/utils/sui-loader';
const mapState = (state: ReduxRootState) => {
  return {
    entityList: state.appCommon.entityList,
    venderList: state.appCommon.enumLabelList
      ?.find((item) => item?.name === 'VendorName')
      ?.values?.reduce(
        (acc, item) => {
          acc[item?.name || ''] = item?.display_name || '';
          return acc;
        },
        {} as Record<string, string>
      ),
  };
};
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function RecentChanges(props: PropsFromRedux) {
  const { entityList } = props;
  const startTime = getUnixTime(subDays(new Date(), 7)) * 1000;

  const { data: recentChanges, isLoading } = useQuery({
    queryKey: ['recent-changes-environment'],
    queryFn: async () =>
      allApi
        .getRecentChanges({
          startTime,
          endTime: Date.now(),
        })
        .then((res) => {
          return res?.data.map((item) => {
            const venderId = item.filters?.find(
              (filter) => filter.column === 'vendorName'
            )?.values?.[0];

            return {
              ...item,
              venderId,
            };
          });
        }),
  });

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-base font-medium text-txt-primary">
          Recent Changes in the last 7 days
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <SuiLoader />
        ) : !recentChanges || recentChanges.length === 0 ? (
          <div className="text-center text-sm text-txt-label py-4">
            No recent changes found
          </div>
        ) : (
          <div className="flex flex-col space-y-3 max-h-[600px] overflow-y-auto">
            {recentChanges.map((change, index) => {
              const Icon =
                assetsVendorServicesIconMap[change?.venderId || ''] ??
                getEntityTypeIcon(change?.entity_type || 0);
              const entity = entityList?.find(
                (item) => item?.entityType === change?.entity_type
              );
              const displayName = entity?.display_name || '';
              const queryId = entity?.query_id || '';

              return (
                <NavLink
                  key={index + displayName + change?.change_count}
                  to={`/all-assets/${queryId}?filters=${encodeURIComponent(
                    JSON.stringify(change?.filters)
                  )}`}
                  className="text-sm flex p-4 border bg-page hover:bg-slate-800/50 rounded-md items-center gap-2"
                >
                  <Icon className="size-5 text-txt-secondary" />
                  <div className="flex items-center gap-1">
                    <p className="text-txt-primary font-semibold">
                      {change?.change_count || 0}
                    </p>
                    <p className="text-txt-label">{displayName} added</p>
                  </div>
                </NavLink>
              );
            })}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default connector(RecentChanges);
