/* eslint-disable react/style-prop-object */
import { IconProps } from '..';

function HUBSPOT(props: IconProps) {
  return (
    <svg
      version="1.0"
      id="katman_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 449.53 500"
      // style="enable-background:new 0 0 449.53 500;"
      xmlSpace="preserve"
      fill="#F8761F"
      {...props}
    >
      <path
        className="st0"
        d="M433.59,237.49c-11.14-19.01-27.03-34.8-46.12-45.81c-14.57-8.47-29.64-13.85-46.65-16.17v-60.92
	c8.25-3.42,15.29-9.23,20.2-16.69c4.91-7.46,7.47-16.22,7.35-25.15c0.03-6.03-1.13-12.01-3.42-17.59
	c-2.29-5.58-5.65-10.65-9.91-14.93c-4.25-4.27-9.31-7.67-14.88-9.98c-5.57-2.31-11.54-3.51-17.57-3.51
	c-12.21,0-23.93,4.84-32.58,13.46c-8.65,8.62-13.53,20.32-13.57,32.54c0,18.69,9.92,34.64,26.97,41.85v60.57
	c-14.12,2.04-27.83,6.35-40.59,12.74L99.75,64.43c1.14-4.31,2.14-8.77,2.14-13.47C101.88,22.81,79.07,0,50.92,0
	C22.77,0,0,22.81,0,50.96c0,28.15,22.81,50.96,50.96,50.96c9.61,0,18.5-2.82,26.17-7.44l10.68,8.09l146.28,105.47
	c-7.74,7.1-14.95,15.18-20.71,24.26c-11.67,18.5-18.81,38.83-18.81,61.03v4.58c0.05,15.05,2.76,29.98,8.01,44.1
	c4.43,12.05,10.91,23.04,18.96,32.96l-48.6,48.71c-14.38-5.34-30.51-1.79-41.39,9.08c-7.4,7.36-11.56,17.39-11.52,27.85
	c0.04,10.45,4.12,20.41,11.56,27.85c7.44,7.44,17.39,11.56,27.85,11.56s20.48-4.12,27.85-11.56c7.36-7.44,11.56-17.39,11.52-27.85
	c0-4.01-0.62-8-1.83-11.82l50.2-50.2c6.87,4.77,14.3,8.77,22.31,12.21c16.46,7.22,34.24,10.96,52.22,10.99h3.43
	c21.06,0,40.93-4.96,59.58-15.07c19.17-10.28,35.28-25.45,46.69-43.98c11.71-18.69,18.12-39.4,18.12-62.18v-1.14
	c0-22.39-5.19-43.03-16.02-61.95L433.59,237.49z M372.29,342.84c-13.62,15.14-29.29,24.49-46.99,24.49h-3.36
	c-10.11,0-20.03-2.79-29.68-7.9c-10.58-5.5-19.55-13.67-26.02-23.69c-7.02-9.92-10.83-20.75-10.83-32.23v-3.43
	c0-11.29,2.17-22.01,7.63-32.12c5.84-11.44,13.73-19.64,24.22-26.47s21.29-10.11,33.57-10.11h1.15c11.06,0,21.63,2.18,31.54,7.25
	c10.05,5.28,18.58,13.04,24.79,22.54c6.15,9.53,9.96,20.38,11.1,31.66l0.27,7.13c0,15.52-5.95,29.9-17.81,42.95L372.29,342.84z"
      />
    </svg>
  );
}

export default HUBSPOT;
