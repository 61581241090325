import { Card, CardContent, CardHeader } from '@/components/shadcn/card';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useQueries } from '@tanstack/react-query';
import { getUnixTime, subDays } from 'date-fns';
import { CheckCircle2, ShieldCheck } from 'lucide-react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { allApi } from 'src/api';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { getNormalizedText } from 'src/pages/aiService/ai-service-dashboard/selected-service/usage/activity/activity-utils';
import { DURATION_OPTIONS, TDurationKeys } from 'src/pages/aiService/shared';
import { getCompactNumber } from 'src/utils/common';
import { CountLink } from 'src/utils/common-components';
import SuiLoader from 'src/utils/sui-loader';
import { PercentageChange } from '../utils';

const mapState = (state: ReduxRootState) => ({
  duration: state.execDashboard.duration,
});
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ProtectionContainer(props: PropsFromRedux) {
  const { duration } = props;
  const startTime = getUnixTime(
    subDays(new Date(), DURATION_OPTIONS[duration as TDurationKeys] || 30)
  );
  const changeStartTime = getUnixTime(
    subDays(
      new Date(startTime * 1000),
      DURATION_OPTIONS[duration as TDurationKeys] || 30
    )
  );

  const results = useQueries({
    queries: [
      {
        queryKey: ['protection-container', duration],
        queryFn: async () =>
          allApi
            .getRawEventFacets({
              endTime: Math.round(Date.now() / 1000),
              eventTypes: ['POLICY_ENFORCEMENT_EVENT'],
              startTime,
              property: 'ENFORCEMENT_ACTION',
              sourceNames: ['SINGULR_CHROME_EXTENSION'],
            })
            .then((resp) => {
              const list = resp?.data?.events
                ?.filter((item) => item?.value)
                .sort((a, b) => (b?.count || 0) - (a?.count || 0));
              const total = list?.reduce(
                (acc, item) => acc + (item?.count || 0),
                0
              );
              return { list, total };
            }),
      },
      {
        queryKey: ['protection-container-change', duration],
        queryFn: async () =>
          allApi
            .getRawEventFacets({
              endTime: startTime,
              eventTypes: ['POLICY_ENFORCEMENT_EVENT'],
              sourceNames: ['SINGULR_CHROME_EXTENSION'],
              startTime: changeStartTime,
              property: 'ENFORCEMENT_ACTION',
            })
            .then((resp) => {
              const list = resp?.data?.events
                ?.filter((item) => item?.value)
                .sort((a, b) => (b?.count || 0) - (a?.count || 0));
              const total = list?.reduce(
                (acc, item) => acc + (item?.count || 0),
                0
              );
              return { list, total };
            }),
      },
    ],
    combine: (results) => {
      const [protectionData, changeData] = results;

      // Calculate percentage change for total
      const currentTotal = protectionData?.data?.total || 0;
      const previousTotal = changeData?.data?.total || 0;

      return {
        data: protectionData.data,
        currentTotal,
        previousTotal,
        isLoading: protectionData.isLoading || changeData.isLoading,
      };
    },
  });

  const { data, currentTotal, previousTotal, isLoading } = results;

  if (isLoading) {
    return (
      <Card>
        <CardHeader>Protection By Singulr Extension</CardHeader>
        <CardContent>
          <SuiLoader />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader className="text-txt-primary font-medium text-base">
        Protection By Singulr Extension
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 border px-4 py-3 rounded-md bg-page">
            <div className="flex items-center gap-2">
              <ShieldCheck className="size-[18px] text-txt-label" />
              <p className="text-sm text-txt-label">
                Total Protection Enforced
              </p>
            </div>
            <div className="flex items-center gap-2.5">
              <CountLink
                count={getCompactNumber(data?.total, 1)}
                link={`/all-events?duration=${duration}&selectedFilters=${JSON.stringify({ sourceNames: ['SINGULR_CHROME_EXTENSION'] })}&tab=POLICY_ENFORCEMENT_EVENT`}
                fontSize="text-3xl"
                fontWeight="font-semibold"
                noPadding
              />

              <PercentageChange
                oldValue={previousTotal}
                currentValue={currentTotal}
                duration={duration}
                isDurationDescriptionEnabled
                tooltipPosition="right"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {data?.list?.map((item) => (
              <div
                className="flex items-center gap-2 px-2 py-1 border rounded-md"
                key={item.value}
              >
                <CheckCircle2 className="w-4 h-4 text-txt-primary" />
                <p className="text-sm text-txt-label">
                  {getNormalizedText(item?.value)}
                </p>
                <CountLink
                  count={getCompactNumber(item?.count, 1)}
                  link={`/all-events?duration=${duration}&selectedFilters=${JSON.stringify({ enforcementAction: [item?.value], sourceNames: ['SINGULR_CHROME_EXTENSION'] })}&tab=POLICY_ENFORCEMENT_EVENT`}
                  fontSize="text-sm"
                  noPadding
                />
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default connector(ProtectionContainer);
