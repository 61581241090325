import { CellContext, ColumnDef } from '@tanstack/react-table';
import { format, fromUnixTime } from 'date-fns';
import { NavLink } from 'react-router-dom';
import {
  AiServiceAccessEvent,
  EventResponse,
  RawEventType,
} from 'src/generated';
import {
  eventTypeLabelMap,
  getNameFromId,
  getNormalizedText,
} from './activity-utils';
import { formatBytes } from 'src/utils/common';

export function RenderCell<TEntityType>({
  info,
  isTextTransformDisabled,
}: {
  info: CellContext<TEntityType, unknown>;
  isTextTransformDisabled?: boolean;
}) {
  if (isTextTransformDisabled)
    return <div>{(info.getValue() as string) || '--'}</div>;

  if (Array.isArray(info.getValue())) {
    if ((info.getValue() as string[]).length === 0) return <div>--</div>;
    return (
      <div>
        {(info.getValue() as string[])
          .map((item) => getNormalizedText(item as string))
          .join(', ')}
      </div>
    );
  }
  return <div>{getNormalizedText(info.getValue() as string) || '--'}</div>;
}

export const HeaderCell = ({ columnDef }: { columnDef: any }) => {
  return <div>{columnDef?.header || columnDef?.id}</div>;
};

export function TimeCell<TEntityType>({
  info,
}: {
  info: CellContext<TEntityType, unknown>;
}) {
  return (
    <div>
      {format(
        fromUnixTime((info.getValue() as number) || 0),
        'dd MMM yyyy,hh:mm aaa'
      )}
    </div>
  );
}

export function UserCell({
  info,
}: {
  info: CellContext<EventResponse, unknown>;
}) {
  const userId = info?.row?.original?.user_ref?.id;
  let params = new URLSearchParams(window.location.search);
  params.set('id', userId || '');
  const url = `/dashboard/user?${params.toString()}`;

  return (
    <NavLink className="text-blue-400" to={url} target="_blank">
      {(info.getValue() as string) || getNameFromId(userId) || '--'}
    </NavLink>
  );
}

const commonCols = ['Time', 'User', 'Source', 'Event Type'];

const commonColumnDefs: ColumnDef<EventResponse, any>[] = commonCols.map(
  (colName) => {
    let accessorPath = '';

    switch (colName) {
      case 'Time': {
        return {
          accessorKey: 'timestamp',
          id: 'timestamp',
          minSize: 200,
          enableSorting: true,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <TimeCell info={props} />
          ),
          size: 250,
          header: () => <HeaderCell columnDef={{ header: colName }} />,
        };
      }
      case 'User': {
        return {
          accessorKey: 'user_ref.name',
          id: 'userId',
          minSize: 150,
          enableSorting: false,
          enableColumnFilter: true,
          meta: {
            filterType: 'select',
            facetId: 'USER',
          },
          cell: (props: CellContext<EventResponse, unknown>) => (
            <UserCell info={props} />
          ),
          size: 300,
          header: () => <HeaderCell columnDef={{ header: colName }} />,
        };
      }
      case 'Source':
        accessorPath = 'source_name';
        return {
          accessorKey: 'source_name',
          id: 'sourceNames',
          enableSorting: false,
          enableColumnFilter: true,
          meta: {
            filterType: 'select',
            facetId: 'SOURCE',
          },
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 150,
          minSize: 150,
          header: () => <HeaderCell columnDef={{ header: colName }} />,
        };

      case 'Event Type':
        return {
          accessorKey: 'event_details.event_type',

          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <div>{eventTypeLabelMap[(props.getValue() as string) || '']}</div>
          ),
          size: 250,
          minSize: 150,
          header: () => <HeaderCell columnDef={{ header: colName }} />,
        };
      default:
        accessorPath = `event_details.${colName.toLowerCase().replace(/\s+/g, '_')}`;
    }

    return {
      accessorKey: accessorPath,

      enableSorting: false,
      enableColumnFilter: false,
      cell: (props: CellContext<EventResponse, unknown>) => (
        <RenderCell info={props} />
      ),
      size: 250,
      minSize: 150,
      header: () => <HeaderCell columnDef={{ header: colName }} />,
    };
  }
);

export function getColumnDefs(eventType: string) {
  switch (eventType) {
    case RawEventType.AiServiceAccessEvent:
      return [
        ...commonColumnDefs,
        {
          accessorKey: 'event_details.metric_name',
          id: 'Metric Name',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell
              columnDef={{ id: 'metric_name', header: 'Metric Name' }}
            />
          ),
        },
        {
          accessorKey: 'event_details.metric_value',
          id: 'Metric Value',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => {
            const text = props.getValue() as string;
            if (
              (
                props.row?.original.event_details as AiServiceAccessEvent
              )?.metric_name?.includes('bytes')
            ) {
              return formatBytes(Number(text));
            }
            return text;
          },

          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell
              columnDef={{ id: 'metric_value', header: 'Metric Value' }}
            />
          ),
        },
      ];

    case RawEventType.FileUploadEvent:
      return [
        ...commonColumnDefs,
        {
          accessorKey: 'event_details.file_name',
          id: 'File Name',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} isTextTransformDisabled />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell columnDef={{ id: 'file_name', header: 'File Name' }} />
          ),
        },
        {
          accessorKey: 'event_details.file_size',
          id: 'File Size',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell columnDef={{ id: 'file_size', header: 'File Size' }} />
          ),
        },
        {
          accessorKey: 'event_details.file_type',
          id: 'File Type',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell columnDef={{ id: 'file_type', header: 'File Type' }} />
          ),
        },
        {
          accessorKey: 'event_details.process',
          id: 'Process',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell columnDef={{ id: 'process', header: 'Process' }} />
          ),
        },
        {
          accessorKey: 'event_details.detections',
          id: 'Detections',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell
              columnDef={{ id: 'detections', header: 'Detections' }}
            />
          ),
        },
      ];

    case RawEventType.CopyPasteEvent:
      return [
        ...commonColumnDefs,
        {
          accessorKey: 'event_details.destination_user.name',
          id: 'Destination Logged In User',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell
              columnDef={{
                id: 'destination_logged_in_user',
                header: 'Destination Logged In User',
              }}
            />
          ),
        },
        {
          accessorKey: 'event_details.detections',
          id: 'Detections',
          enableSorting: false,
          enableColumnFilter: false,
          cell: (props: CellContext<EventResponse, unknown>) => (
            <RenderCell info={props} />
          ),
          size: 250,
          minSize: 150,
          header: () => (
            <HeaderCell
              columnDef={{ id: 'detections', header: 'Detections' }}
            />
          ),
        },
      ];

    default:
      return commonColumnDefs;
  }
}
