import { useQueries } from '@tanstack/react-query';
import allApi from 'src/api/allApi';

import { getUnixTime, subDays } from 'date-fns';
import { useMemo } from 'react';
import {
  DURATION_OPTIONS,
  DURATION_RESOLUTION,
  TDurationKeys,
} from 'src/pages/aiService/shared';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';

const statusList = ['Unvetted', 'Approved', 'Unapproved', 'Restricted'];

function useTrendChartData(entityId: number) {
  const duration = useSelector(
    (state: ReduxRootState) => state.execDashboard.duration,
    {
      equalityFn: shallowEqual,
    }
  );

  const startTime =
    getUnixTime(
      subDays(new Date(), DURATION_OPTIONS[duration as TDurationKeys] || 7)
    ) * 1000;

  const {
    data: trendResponses,
    isLoading,
    isError,
  } = useQueries({
    queries: statusList.map((statusName) => ({
      queryKey: [`governance-${statusName.toLowerCase()}`, duration, entityId],
      enabled: !!duration,
      queryFn: () =>
        allApi.getEntityTrend({
          entityTrendRequest: {
            entity_type: entityId,
            end_time: Date.now(),
            resolution_bucket_secs:
              DURATION_RESOLUTION[duration as TDurationKeys],
            start_time: startTime,
            filters: [
              {
                property_name: 'approvalStatus',
                value: statusName,
              },
            ],
          },
        }),
    })),
    combine(responses) {
      return {
        data: responses,
        isLoading: responses?.some((result) => result?.isLoading),
        isError: responses?.every((result) => result?.isError),
      };
    },
  });

  const governanceTrendChartData = useMemo(() => {
    if (!trendResponses || trendResponses?.length === 0) {
      return [];
    }

    // Extract responses for each status
    const [unvettedResult, approvedResult, unapprovedResult, restrictedResult] =
      trendResponses;

    const unvettedValues =
      unvettedResult?.data?.data?.metric_series?.[0]?.values ?? [];
    const approvedValues =
      approvedResult?.data?.data?.metric_series?.[0]?.values ?? [];
    const unapprovedValues =
      unapprovedResult?.data?.data?.metric_series?.[0]?.values ?? [];
    const restrictedValues =
      restrictedResult?.data?.data?.metric_series?.[0]?.values ?? [];

    const result = [];
    for (let i = 0; i < unvettedValues.length; i++) {
      const timestamp = unvettedValues[i]?.ts ?? 0;
      const unvetted = unvettedValues[i]?.value ?? 0;
      const approved = approvedValues[i]?.value ?? 0;
      const unapproved = unapprovedValues[i]?.value ?? 0;
      const restricted = restrictedValues[i]?.value ?? 0;
      const total = approved + unapproved + unvetted + restricted;

      result.push({
        timestamp,
        total,
        approved,
        unapproved,
        unvetted,
        restricted,
      });
    }

    return result;
  }, [trendResponses]);

  return {
    governanceTrendChartData,
    isLoading,
    isError,
  };
}

export default useTrendChartData;
