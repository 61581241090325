import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { DURATION_LABELS } from '../aiService/shared';

interface execDashboardState {
  duration: string;
}

const initialState: execDashboardState = {
  duration: DURATION_LABELS[1],
};

export const execDashboardSlice = createSlice({
  name: 'execDashboard',
  initialState,
  reducers: {
    setExecDuration: (state, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
  },
});

export const { setExecDuration } = execDashboardSlice.actions;

export default execDashboardSlice.reducer;
