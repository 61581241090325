import { IconProps } from '..';

function AZURE(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.47675 12.8233C9.27542 12.5056 10.761 12.2427 10.7781 12.239L10.8091 12.2325L9.11098 10.2126C8.17701 9.10167 7.41286 8.18842 7.41286 8.18316C7.41286 8.17319 9.16632 3.34454 9.17617 3.32735C9.17947 3.32162 10.3728 5.38179 12.0688 8.32135C13.6566 11.0734 14.9657 13.3425 14.9779 13.3638L15 13.4024L9.60321 13.4017L4.20643 13.401L7.47675 12.8233ZM1 12.2074C1 12.2045 1.80015 10.8155 2.77811 9.12071L4.55622 6.03922L6.6284 4.30025C7.7681 3.34381 8.70365 2.5601 8.7074 2.55863C8.71115 2.55724 8.69616 2.59498 8.67408 2.64252C8.65201 2.69007 7.63946 4.86187 6.42398 7.46874L4.21403 12.2085L2.60701 12.2105C1.72316 12.2116 1 12.2102 1 12.2074Z"
        fill="#0089D6"
      />
    </svg>
  );
}

export default AZURE;
