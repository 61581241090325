import clsx from 'clsx';
import TooltipWrapper from 'src/components/tooltipWrapper';

export function SuiTriangleIcon({ totalChange }: { totalChange: number }) {
  return (
    <div
      className={clsx(
        'w-0 h-0',
        'border-l-[5px] border-l-transparent',
        'border-r-[5px] border-r-transparent',
        'border-b-[8px] border-b-slate-300',
        totalChange < 0 && 'rotate-180'
      )}
    />
  );
}

export function PercentageChange({
  oldValue,
  currentValue,
  duration,
  isDurationDescriptionEnabled = false,
  tooltipPosition = 'bottom',
}: {
  oldValue?: number;
  currentValue?: number;
  duration?: string;
  isDurationDescriptionEnabled?: boolean;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
}) {
  if (
    typeof oldValue !== 'number' ||
    typeof currentValue !== 'number' ||
    oldValue === 0
  ) {
    return null;
  }

  const totalChange =
    (((currentValue || 0) - (oldValue || 0)) / (oldValue || 0)) * 100;
  if (totalChange === 0) {
    return (
      <div className="flex border rounded px-2 items-center gap-1 bg-[#64748B1A]">
        <p className="text-[11px] text-slate-300">No change</p>
      </div>
    );
  }

  return (
    <TooltipWrapper
      title={`${duration?.replace('Last', '')} ago: ${oldValue}`}
      side={tooltipPosition}
    >
      <div className="flex border rounded px-2 py-1 items-center gap-1 bg-[#64748B1A]">
        <SuiTriangleIcon totalChange={totalChange} />
        <p className="text-11 text-slate-300 ">
          {totalChange?.toFixed(0)}%{' '}
          {isDurationDescriptionEnabled
            ? `over ${duration?.toLowerCase()}`
            : ''}
        </p>
      </div>
    </TooltipWrapper>
  );
}
