import { IconProps } from '..';

function AWS_EKS(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <defs />
      <defs>
        <linearGradient id="a" x1="0" x2="1" y1="1" y2="0">
          <stop offset="0%" stopColor="#C8511B" />
          <stop offset="100%" stopColor="#F90" />
        </linearGradient>
      </defs>
      <g>
        <path
          fill="url(#a)"
          fillRule="evenodd"
          d="m 0,0 h 100 v 100 h -100 z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m 58.552,40.249 -8.536,9.901 9.285,10.83 h -3.465 l -8.336,-9.73 v 10 h -2.5 v -21.25 h 2.5 v 8.75 l 7.726,-8.501 z m 21.448,20.044 -10,-6.002 v -14.291 c 0,-0.445 -0.235,-0.856 -0.62,-1.08 l -14.38,-8.387 v -12.093 l 25,14.774 z m 1.886,-28.869 -27.5,-16.25 c -0.388,-0.229 -0.864,-0.231 -1.256,-0.01 -0.389,0.222 -0.63,0.637 -0.63,1.086 v 15 c 0,0.444 0.236,0.855 0.62,1.08 l 14.38,8.388 v 14.282 c 0,0.439 0.231,0.846 0.607,1.071 l 12.5,7.5 c 0.198,0.119 0.42,0.179 0.643,0.179 0.213,0 0.425,-0.054 0.616,-0.162 0.393,-0.221 0.634,-0.637 0.634,-1.088 v -30 c 0,-0.443 -0.232,-0.851 -0.614,-1.076 z m -31.33,52.155 -29.306,-15.58 v -34.785 l 25,-14.774 v 12.124 l -13.171,8.381 c -0.36,0.23 -0.579,0.627 -0.579,1.055 v 21.25 c 0,0.466 0.26,0.894 0.672,1.109 l 16.817,8.75 c 0.361,0.188 0.791,0.189 1.151,0.001 l 16.315,-8.43 10.04,6.025 z m 30.088,-15.901 -12.5,-7.5 c -0.372,-0.222 -0.833,-0.237 -1.218,-0.038 l -16.357,8.451 -15.569,-8.1 v -19.805 l 13.171,-8.381 c 0.36,-0.23 0.579,-0.627 0.579,-1.055 v -15 c 0,-0.449 -0.24,-0.864 -0.63,-1.086 -0.39,-0.221 -0.867,-0.219 -1.256,0.01 l -27.5,16.25 c -0.38,0.225 -0.614,0.634 -0.614,1.076 v 36.25 c 0,0.461 0.255,0.886 0.662,1.104 l 30.568,16.25 c 0.184,0.098 0.385,0.146 0.586,0.146 0.209,0 0.416,-0.053 0.605,-0.156 l 29.434,-16.25 c 0.391,-0.216 0.636,-0.625 0.645,-1.071 0.007,-0.447 -0.224,-0.865 -0.606,-1.095 z"
        />
      </g>
    </svg>
  );
}

export default AWS_EKS;
