import { IconProps } from '..';

function ANTHROPIC_API(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2068_87738)">
        <path
          d="M9.06641 3L12.899 12.613H15.0007L11.1681 3H9.06641Z"
          fill="#E1E7EF"
        />
        <path
          d="M4.61937 8.80897L5.93076 5.4307L7.24215 8.80897H4.61937ZM4.83198 3L1 12.613H3.14261L3.92632 10.5942H7.93533L8.71891 12.613H10.8615L7.02954 3H4.83198Z"
          fill="#E1E7EF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2068_87738">
          <rect
            width="14"
            height="9.625"
            fill="white"
            transform="translate(1 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ANTHROPIC_API;
