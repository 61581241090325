import { CellContext } from '@tanstack/react-table';
import { AlertOctagon, Hexagon } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import { HeaderCell } from 'src/pages/aiService/ai-service-dashboard/selected-service/usage/activity/activity-table-config';
import { getCompactNumber } from 'src/utils/common';
import { CountLink } from 'src/utils/common-components';

export interface IBusinessAppEntity {
  id: string;
  name: string;
  entityType: number;
  userTags: string[];
  vendorName: string[];
  genAiVms: GenAIServicesBeingAccessed[];
  neighbourHosts: string[];
  isGenAiApplication: boolean;
  vms: GenAIServicesBeingAccessed[];
  ips: string[];
  role: string;
  genAiServicesBeingAccessed: GenAIServicesBeingAccessed[];
  bucketsBeingAccessed: string[];
  vectorIndexBeingAccessed: string[];
  mlModelBeingAccessed: string[];
  aiServiceEndpoints: string[];
  mlPlatforms: string[];
  aiServices: string[];
  numUsers: number;
}

export interface GenAIServicesBeingAccessed {
  id: string;
  name: string;
  entityType: number;
  serviceName?: string;
  region?: GenAIServicesBeingAccessed;
}
export function TitleCell({
  info,
}: {
  info: CellContext<IBusinessAppEntity, unknown>;
}) {
  const name = info?.row?.original?.name;
  const vendorName = info?.row?.original?.vendorName
    ?.filter((name) => name)
    ?.join(', ');

  return (
    <div className="flex items-center gap-3">
      <Hexagon className="size-4 text-txt-label" />
      <div className="flex flex-col gap-0.5">
        <NavLink
          target="_blank"
          to={`/dashboard/app?appId=${info?.row?.original?.id}`}
          className="text-sm font-medium text-blue-400 hover:text-blue-500 line-clamp-2"
        >
          {name}
        </NavLink>
        <p className="text-xs text-txt-help line-clamp-2">{vendorName}</p>
      </div>
    </div>
  );
}
export function NumericCell({
  info,
}: {
  info: CellContext<IBusinessAppEntity, unknown>;
}) {
  const serviceName = info?.row?.original?.name;
  return (
    <div className="flex items-center">
      <CountLink
        count={getCompactNumber(info.getValue() as number)}
        link={`/all-assets/user_table?filters=${encodeURIComponent(
          JSON.stringify([
            { column: 'application', values: [serviceName || ''] },
          ])
        )}`}
      />
    </div>
  );
}

export function RiskCell({
  info,
}: {
  info: CellContext<IBusinessAppEntity, unknown>;
}) {
  const length = (info.getValue() as string[])?.length;
  // if (!length) return <p className="text-xs text-txt-secondary">{length}</p>;
  return (
    <div className="flex items-center gap-1.5">
      <AlertOctagon className="size-4 text-red-500" />
      <p className="text-xs text-txt-secondary">{length}</p>
    </div>
  );
}

export const inHouseColumns = [
  {
    accessorKey: 'name',
    id: 'name',
    minSize: 200,
    enableSorting: false,
    enableColumnFilter: false,
    cell: (props: CellContext<IBusinessAppEntity, unknown>) => (
      <TitleCell info={props} />
    ),
    size: 200,
    header: () => <HeaderCell columnDef={{ header: 'Name' }} />,
  },
  {
    accessorKey: 'criticalRiskIds',
    id: 'criticalRiskIds',
    minSize: 100,
    enableSorting: false,
    enableColumnFilter: false,

    cell: (props: CellContext<IBusinessAppEntity, unknown>) => (
      <RiskCell info={props} />
    ),
    size: 100,
    header: () => <HeaderCell columnDef={{ header: 'Risk' }} />,
  },
  {
    accessorKey: 'aiServices',
    id: 'aiServices',
    minSize: 130,
    enableSorting: false,
    enableColumnFilter: false,
    cell: (props: CellContext<IBusinessAppEntity, unknown>) => (
      <div className="flex items-center">
        <CountLink
          count={(props?.getValue() as string[])?.length}
          link={`/dashboard/aiServices?aiServicesFilters=${JSON.stringify([
            {
              values: (props.getValue() as { id: string }[])?.map(
                ({ id }) => id
              ),
              column: 'id',
            },
          ])}`}
          fontSize="text-xs"
        />
      </div>
    ),
    size: 130,
    header: () => <HeaderCell columnDef={{ header: 'AI Services' }} />,
  },
  {
    accessorKey: 'numUsers',
    id: 'numUsers',
    minSize: 130,
    enableSorting: false,
    enableColumnFilter: false,
    cell: (props: CellContext<IBusinessAppEntity, unknown>) => (
      <NumericCell info={props} />
    ),
    size: 130,
    header: () => <HeaderCell columnDef={{ header: 'Users' }} />,
  },
];
