import { IconProps } from '.';

function UNKNOWN(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1H4.33333C3.97971 1 3.64057 1.14048 3.39052 1.39052C3.14048 1.64057 3 1.97971 3 2.33333V13C3 13.3536 3.14048 13.6928 3.39052 13.9428C3.64057 14.1929 3.97971 14.3333 4.33333 14.3333H12.3333C12.687 14.3333 13.0261 14.1929 13.2761 13.9428C13.5262 13.6928 13.6667 13.3536 13.6667 13V4.66667L10 1Z"
        stroke="#E1E7EF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6.53336C7.13333 6.26669 7.33333 6.00002 7.6 5.86669C7.87609 5.70707 8.1984 5.6465 8.51359 5.69499C8.82879 5.74349 9.11799 5.89814 9.33333 6.13336C9.53333 6.40002 9.66667 6.66669 9.66667 7.00002C9.66667 7.86669 8.33333 8.33336 8.33333 8.33336"
        stroke="#E1E7EF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33398 11H8.34065"
        stroke="#E1E7EF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UNKNOWN;
