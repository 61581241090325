/* tslint:disable */
/* eslint-disable */
/**
 * merged spec
 * merged spec
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RawEventType = {
    AiServiceAccessEvent: 'AI_SERVICE_ACCESS_EVENT',
    SaasServiceAccessEvent: 'SAAS_SERVICE_ACCESS_EVENT',
    FileUploadEvent: 'FILE_UPLOAD_EVENT',
    CopyPasteEvent: 'COPY_PASTE_EVENT',
    PolicyEnforcementEvent: 'POLICY_ENFORCEMENT_EVENT',
    FileUploadToSaasEvent: 'FILE_UPLOAD_TO_SAAS_EVENT'
} as const;

export type RawEventType = typeof RawEventType[keyof typeof RawEventType];



