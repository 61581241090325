import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/shadcn/card';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useQuery } from '@tanstack/react-query';
import { getUnixTime, subDays } from 'date-fns';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { allApi } from 'src/api';
import SuiFilterTable from 'src/components/sui-table/filter-table';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { GetEntityDataParam, SortParamSortOrderEnum } from 'src/generated';
import {
  DURATION_OPTIONS,
  IAiServiceEntity,
  TDurationKeys,
} from '../../aiService/shared';
import { extServiceColumns } from './ext-service-config';
import SuiLoader from 'src/utils/sui-loader';

const mapState = (state: ReduxRootState) => ({
  duration: state.execDashboard.duration,
});
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function TopExternalAiServices(props: PropsFromRedux) {
  const { duration } = props;
  const startTime =
    getUnixTime(
      subDays(new Date(), DURATION_OPTIONS[duration as TDurationKeys] || 30)
    ) * 1000;

  const {
    data: list,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['top-3-external-ai-services', duration],
    queryFn: async () =>
      allApi
        .getEntityPage({
          entityType: 49,
          queryId: 'ai_services_all_table',
          getEntityDataParam: {
            filters: [
              {
                values: ['VERIFIED'],
                column: 'lifecycleStatus',
              },
            ],
            sort: {
              sort_by: 'countOfUsers',
              sort_order: SortParamSortOrderEnum.Desc,
            },
            pagination: {
              limit: 3,
              offset: 0,
            },
            context: {
              start_time: startTime,
              end_time: Date.now(),
            },
          } as GetEntityDataParam,
        })
        .then((res) => res?.data?.data?.list as IAiServiceEntity[]),
    // enabled: !!filterDetail?.name && isOpen,
  });

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-txt-primary text-base font-medium">
          Top 3 External AI Services
        </CardTitle>
        <NavLink
          to={`/dashboard/aiServices?duration=${duration}`}
          className="text-blue-400 text-sm font-medium"
          // target="_blank"
        >
          All Services
        </NavLink>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <SuiLoader heightClass="h-[255px]" />
        ) : (
          <SuiFilterTable<IAiServiceEntity>
            columns={extServiceColumns}
            data={list}
            isLoading={isLoading}
            isError={isError}
            height={255}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default connector(TopExternalAiServices);
