import { format, fromUnixTime } from 'date-fns';
import { useEffect, useRef } from 'react';
import {
  AiServiceAccessEvent,
  CopyPasteEvent,
  EventFacetProperty,
  EventResponse,
  FileUploadEvent,
  PolicyEnforcementEvent,
  RawEventType,
} from 'src/generated';
import { GetAllRawEvents200ResponsePagination } from 'src/generated/types/get-all-raw-events200-response-pagination';
import { formatBytes } from 'src/utils/common';

export const ACTIVITY_PAGE_SIZE = 50;

export function getEventTypeLabel(eventType: string) {
  switch (eventType) {
    case RawEventType.AiServiceAccessEvent:
      return 'AI Service Access';
    case RawEventType.FileUploadEvent:
      return 'File Upload';
    case RawEventType.CopyPasteEvent:
      return 'Copy/Paste';
    case RawEventType.PolicyEnforcementEvent:
      return 'Policy Enforcement';
    default:
      return (
        eventType
          ?.replaceAll('_', ' ')
          ?.toLowerCase()
          ?.replaceAll('event', '') || ''
      );
  }
}

const commonColumns = ['Time', 'User', 'Source', 'Event Type'];

export function getColumns(eventType: string) {
  switch (eventType) {
    case RawEventType.AiServiceAccessEvent:
      return commonColumns.concat(['Metric Name', 'Metric Value']);
    case RawEventType.FileUploadEvent:
      return commonColumns.concat([
        'File Name',
        'File Size',
        'File Type',
        'Process',
        'Detections',
      ]);
    case RawEventType.CopyPasteEvent:
      return commonColumns.concat(['Destination Logged In User', 'Detections']);
    case RawEventType.PolicyEnforcementEvent:
      return commonColumns.concat(['Policy ID', 'Action Type']);
    default:
      return commonColumns;
  }
}
export const eventTypeLabelMap: Record<string, string> = {
  PolicyEnforcementEvent: 'Policy Enforcement',
  FileUploadEvent: 'File Upload',
  AiServiceAccessEvent: 'Service Access',
  CopyPasteEvent: 'Copy-Paste',
  SaasServiceAccessEvent: 'Saas Service Access',
};

export function getNameFromId(id?: string) {
  const index = id?.lastIndexOf('[');

  const name = index ? id?.slice(index + 1)?.replace(']', '') : '';

  return name || '--';
}

export function getNormalizedText(text?: string) {
  if (typeof text !== 'string') return text;

  return (text ?? '')
    ?.replaceAll('_', ' ')
    ?.toLowerCase()
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
}

export function getRenderColumnLabel({
  text,
  colName,
  dependantColText,
}: {
  text?: string;
  colName: string;
  dependantColText?: string;
}) {
  switch (colName) {
    case 'Action Type':
    case 'Enforcement Point':
    case 'Metric Name':
    case 'File Type':
    case 'Detections':
    case 'Source':
    case 'Enforcement Action':
    case 'Enforcement Point Policy': {
      return getNormalizedText(text);
    }
    case 'Event Type':
      return eventTypeLabelMap[text || ''];
    case 'Metric Value': {
      if (dependantColText?.includes('bytes')) {
        return formatBytes(Number(text));
      }
      return text;
    }
    case 'User': {
      try {
        if (!text) {
          return getNameFromId(dependantColText);
        }
      } catch (e) {
        console.log(e);
      }

      return text || '--';
    }
    case 'File Size':
      return formatBytes(Number(text));
    default:
      return text;
  }
}

export function reduceEventResponse(event: EventResponse) {
  return {
    User: event?.user_ref?.name,
    Time: format(fromUnixTime(event?.timestamp || 0), 'dd MMM yyyy,hh:mm aaa'),
    'Event Type': event?.event_details?.event_type,
    'Enforcement Point': event?.source_name,
    Source: event?.source_name,
    userId: event?.user_ref?.id,
    'File Name': (event?.event_details as FileUploadEvent)?.file_name ?? '--',
    'File Size': (event?.event_details as FileUploadEvent)?.file_size ?? '--',
    'File Type': (event?.event_details as FileUploadEvent)?.file_type ?? '--',
    Process: (event?.event_details as FileUploadEvent)?.process ?? '--',
    'Metric Name':
      (event?.event_details as AiServiceAccessEvent)?.metric_name ?? '--',
    'Metric Value':
      (event?.event_details as AiServiceAccessEvent)?.metric_value ?? '--',
    'Source URL': (event?.event_details as CopyPasteEvent)?.source ?? '--',
    'Source Logged In User':
      (event?.event_details as CopyPasteEvent)?.source_logged_in_user ?? '--',
    'Destination Logged In User':
      (event?.event_details as CopyPasteEvent)?.destination_logged_in_user ??
      '--',
    Detections:
      (event?.event_details as CopyPasteEvent)?.detections
        ?.toString()
        ?.replaceAll(',', ', ') ?? '--',
    'Action Type':
      (event?.event_details as PolicyEnforcementEvent)?.action_type ?? '--',
    'Policy ID':
      (event?.event_details as PolicyEnforcementEvent)?.policy_id ?? '--',
    'Enforcement Action':
      (event?.event_details as PolicyEnforcementEvent)?.action_type ?? '--',
    'Enforcement Point Policy':
      (event?.event_details as PolicyEnforcementEvent)?.policy_name ?? '--',
    'Singulr Policy':
      (event?.event_details as PolicyEnforcementEvent)?.singulr_policy_ref
        ?.name ?? '--',
  } as Record<string, string | undefined>;
}

export interface IEventTransformedResponse {
  events: Record<string, string | undefined>[] | undefined;
  nextPage: string | undefined;
  pagination: GetAllRawEvents200ResponsePagination | undefined;
}

export function getDependantColumnText(
  colName: string,
  row: Record<string, string | undefined>
) {
  switch (colName) {
    case 'Metric Value': {
      return row?.['Metric Name'] || '';
    }
    case 'User': {
      return row?.['userId'] || '';
    }
  }
}

export const API_COL_MAP: Record<string, EventFacetProperty> = {
  EventType: 'EVENT_TYPE',
  AiService: 'AI_SERVICE',
  User: 'USER',
  Machine: 'MACHINE',
  Source: 'SOURCE',
  'Enforcement Point': 'SOURCE',
  'Singulr Policy': 'SINGULR_POLICY',
  'Enforcement Point Policy': 'ENFORCEMENT_POLICY',
  'Enforcement Action': 'ENFORCEMENT_ACTION',
};

export function useObserveLastAndMiddleElement({
  isLoading,
  isFetching,
  hasNextPage,
  fetchNextPage,
}: {
  isLoading: boolean;
  isFetching: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}) {
  const middleEleRef = useRef<HTMLDivElement | null>(null);
  const lastEleRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const midElement = middleEleRef.current;
    const lastElement = lastEleRef.current;
    if (isLoading) return;
    const observer = new IntersectionObserver((entries) => {
      for (let entry of entries) {
        if (entry.isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      }
    });
    if (midElement) observer.observe(midElement);
    if (lastElement) observer.observe(lastElement);

    return () => {
      observer.disconnect();
    };
  }, [isLoading, isFetching, hasNextPage]);

  return { lastEleRef, middleEleRef };
}
