import { useQuery } from '@tanstack/react-query';
import { Box, Hexagon } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import { allApi } from 'src/api';
import ProgressBar from 'src/components/progress-bar';
import { FilterOperation } from 'src/generated/types';
import {
  DURATION_OPTIONS,
  getDurationUnixInMillSeconds,
  TDurationKeys,
} from 'src/pages/aiService/shared';

import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { assetsVendorServicesIconMap } from 'src/constants/Assets';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { CountLink, ShimmerLoading } from 'src/utils/common-components';
import { PercentageChange } from '../utils';

const mapState = (state: ReduxRootState) => ({
  duration: state.execDashboard.duration,
});
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function getVendorDetails(vendor: string) {
  const vendorIcon = assetsVendorServicesIconMap[vendor] ?? Box;
  switch (vendor?.toLowerCase()) {
    case 'aws':
      return { icon: vendorIcon, label: 'AWS' };
    case 'azure':
      return { icon: vendorIcon, label: 'Azure' };
    case 'gcp':
      return { icon: vendorIcon, label: 'GCP' };
    default:
      return { icon: Box, label: vendor };
  }
}

function InHousePosture(props: PropsFromRedux) {
  const { duration } = props;
  const { data: totalCount, isLoading } = useQuery({
    queryKey: ['in-house-apps-count'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 79,
          queryId: 'business_app_table',
          includeCount: true,
          facetProperty: 'entityType',
        })
        .then((resp) =>
          resp?.data?.reduce((acc, cur) => acc + (cur?.count || 0), 0)
        ),
  });

  const { data: lastQtrCount, isLoading: isLoadingLastQtrCount } = useQuery({
    queryKey: ['in-house-apps-last-qtr-count', duration],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 79,
          queryId: 'business_app_table',
          includeCount: true,
          facetProperty: 'entityType',
          getFacetDataParam: {
            filters: [
              {
                column: 'createTimeMillis',
                values: [
                  getDurationUnixInMillSeconds(
                    DURATION_OPTIONS[duration as TDurationKeys]
                  ).toString(),
                ],
                operation: FilterOperation.Lt,
              },
            ],
          },
        })
        .then((resp) =>
          resp?.data?.reduce((acc, cur) => acc + (cur?.count || 0), 0)
        ),
  });

  const { data, isLoading: isLoadingRisk } = useQuery({
    queryKey: ['facets', 'risk-in-house-app-posture'],
    queryFn: async () =>
      allApi
        .getEntityPage({
          queryId: 'business_app_table',
          entityType: 79,
          getEntityDataParam: {
            pagination: {
              limit: -1,
              offset: 0,
            },
            sort: {
              sort_by: 'name',
              sort_order: 'DESC',
            },
          },
        })
        .then((res) => {
          const idList = (
            res?.data?.data?.list as {
              criticalRiskIds: string[];
              id: string;
            }[]
          )?.reduce((acc, cur) => {
            if (cur?.criticalRiskIds?.length) {
              acc.push(cur?.id);
            }
            return acc;
          }, [] as string[]);

          const totalCount = res?.data?.data?.total_count ?? 0;

          return {
            idList,
            highRiskCount: idList?.length,
            percentage: (idList?.length / totalCount) * 100,
          };
        }),
  });

  const { data: vendorList, isLoading: isLoadingVendors } = useQuery({
    queryKey: ['in-house-apps-vendors', 'executive-dashboard'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          facetProperty: 'vendorName',
          queryId: 'business_app_table',
          includeCount: true,
          entityType: 79,
        })
        .then((res) => res?.data),
  });

  return (
    <div className="bg-card rounded-md border border-border p-5">
      <div className="flex gap-1 items-center mb-2">
        <Hexagon className="size-4 text-txt-label" />
        <p className="text-txt-label text-sm">In-house Applications</p>
      </div>

      <div className="flex flex-col items-start gap-1 mb-3">
        <div className="flex items-center gap-2">
          <div className="flex flex-col gap-2">
            {isLoading ? (
              <ShimmerLoading height="h-8" width="w-10" className="mb-2" />
            ) : (
              <CountLink
                count={totalCount}
                link="/assets/business_app_table_page"
                fontSize="text-3xl"
                fontWeight="font-semibold"
                noPadding
              />
            )}
          </div>
          {isLoadingLastQtrCount ? (
            <ShimmerLoading height="h-5" width="w-20" />
          ) : (
            <PercentageChange
              oldValue={lastQtrCount}
              currentValue={totalCount}
              duration={duration}
              isDurationDescriptionEnabled
              tooltipPosition="right"
            />
          )}
        </div>

        <div className="flex gap-2 text-txt-secondary text-xs">
          {isLoadingVendors ? (
            <>
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <ShimmerLoading
                    key={index}
                    height="h-7"
                    width="w-16"
                    className="px-3 py-1"
                  />
                ))}
            </>
          ) : (
            vendorList?.map((vendor) => {
              const { icon: Icon, label } = getVendorDetails(
                vendor?.value ?? ''
              );
              return (
                <NavLink
                  to={`/assets/business_app_table_page?filters=${encodeURIComponent(
                    JSON.stringify([
                      { column: 'vendorName', values: [vendor.value] },
                    ])
                  )}`}
                  target="_blank"
                >
                  <div
                    className="flex items-center gap-2 bg-gray-900 border rounded px-3 py-1 hover:bg-gray-800 cursor-pointer"
                    key={vendor.value}
                  >
                    <Icon className="size-4" />
                    <p className="text-sm text-txt-secondary">{label}</p>
                    <p className="font-medium text-xs text-txt-secondary">
                      {vendor.count}
                    </p>
                  </div>
                </NavLink>
              );
            })
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {isLoadingRisk ? (
          <p className="h-4 bg-slate-800 animate-pulse w-32 rounded mt-0.5" />
        ) : (
          <div className="flex items-center gap-1">
            <ProgressBar
              percentage={data?.percentage}
              progressColor="border-red-500"
            />
            <CountLink
              count={data?.highRiskCount}
              link={`/assets/business_app_table_page?filters=${encodeURIComponent(
                JSON.stringify([{ column: 'id', values: data?.idList }])
              )}`}
              fontSize="text-xs"
            />
            {data?.percentage && data?.percentage > 0 ? (
              <p className="text-xs font-medium text-txt-secondary">
                ({Math.round(data?.percentage ?? 0)}%) with Critical Risks
              </p>
            ) : (
              <p className="text-xs font-medium text-txt-secondary">
                No Critical Risks
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default connector(InHousePosture);
