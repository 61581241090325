import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { EServiceTabs, IAiServiceEntity } from './shared';

interface onboardingState {
  selectedRow: IAiServiceEntity | null;
  selectedRowId: string;
  usageDuration: number;
  usageSubRange: number;
  updaterCount: number;
  serviceSearchText: string;
  enforcementPolicy?: {
    id: string;
    sourceName: string;
  };
  activeServiceTab: EServiceTabs;
}

const initialState: onboardingState = {
  selectedRow: null,
  selectedRowId: '',
  usageDuration: 30,
  usageSubRange: 0,
  updaterCount: 0,
  serviceSearchText: '',
  activeServiceTab: EServiceTabs.aiService,
  enforcementPolicy: undefined,
};

export const aiServiceSlice = createSlice({
  name: 'ai-service',
  initialState,
  reducers: {
    setSelectedRow: (state, action: PayloadAction<IAiServiceEntity | null>) => {
      state.selectedRow = action.payload;
      state.selectedRowId = action.payload?.id || '';
      state.usageSubRange = 0;
      state.updaterCount = state.updaterCount + 1;
    },
    setUsageDuration: (state, action: PayloadAction<number>) => {
      state.usageDuration = action.payload;
      state.usageSubRange = 0;
      state.updaterCount = state.updaterCount + 1;
    },
    setUsageSubRange: (state, action: PayloadAction<number>) => {
      state.usageSubRange = action.payload;
    },
    incrementUpdaterCount: (state) => {
      state.updaterCount = state.updaterCount + 1;
    },
    setServiceSearchText: (state, action: PayloadAction<string>) => {
      state.serviceSearchText = action.payload;
    },
    setActiveServiceTab: (state, action: PayloadAction<EServiceTabs>) => {
      state.activeServiceTab = action.payload;
      state.serviceSearchText = '';
    },
    setEnforcementPolicy: (
      state,
      action: PayloadAction<{ id: string; sourceName: string } | undefined>
    ) => {
      state.enforcementPolicy = action.payload;
    },
  },
});

export const {
  setSelectedRow,
  setUsageDuration,
  setUsageSubRange,
  incrementUpdaterCount,
  setServiceSearchText,
  setActiveServiceTab,
  setEnforcementPolicy,
} = aiServiceSlice.actions;

export default aiServiceSlice.reducer;
