import { IconProps } from '..';

function MISTRAL_API(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2068_87797)">
        <path d="M12.8015 2H10.4043V4.39719H12.8015V2Z" fill="black" />
        <path d="M14.0007 2H11.6035V4.39719H14.0007V2Z" fill="#F7D046" />
        <path d="M3.2136 2H0.816406V4.39719H3.2136V2Z" fill="black" />
        <path
          d="M3.2136 4.39746H0.816406V6.79465H3.2136V4.39746Z"
          fill="black"
        />
        <path
          d="M3.2136 6.79395H0.816406V9.19114H3.2136V6.79395Z"
          fill="black"
        />
        <path
          d="M3.2136 9.19141H0.816406V11.5886H3.2136V9.19141Z"
          fill="black"
        />
        <path
          d="M3.2136 11.5889H0.816406V13.9861H3.2136V11.5889Z"
          fill="black"
        />
        <path d="M4.41282 2H2.01562V4.39719H4.41282V2Z" fill="#F7D046" />
        <path
          d="M14.0007 4.39746H11.6035V6.79465H14.0007V4.39746Z"
          fill="#F2A73B"
        />
        <path
          d="M4.41282 4.39746H2.01562V6.79465H4.41282V4.39746Z"
          fill="#F2A73B"
        />
        <path
          d="M10.405 4.39746H8.00781V6.79465H10.405V4.39746Z"
          fill="black"
        />
        <path
          d="M11.6042 4.39746H9.20703V6.79465H11.6042V4.39746Z"
          fill="#F2A73B"
        />
        <path
          d="M6.8093 4.39746H4.41211V6.79465H6.8093V4.39746Z"
          fill="#F2A73B"
        />
        <path
          d="M9.20578 6.79395H6.80859V9.19114H9.20578V6.79395Z"
          fill="#EE792F"
        />
        <path
          d="M11.6042 6.79395H9.20703V9.19114H11.6042V6.79395Z"
          fill="#EE792F"
        />
        <path
          d="M6.8093 6.79395H4.41211V9.19114H6.8093V6.79395Z"
          fill="#EE792F"
        />
        <path
          d="M8.00852 9.19141H5.61133V11.5886H8.00852V9.19141Z"
          fill="black"
        />
        <path
          d="M9.20578 9.19141H6.80859V11.5886H9.20578V9.19141Z"
          fill="#EB5829"
        />
        <path
          d="M14.0007 6.79395H11.6035V9.19114H14.0007V6.79395Z"
          fill="#EE792F"
        />
        <path
          d="M4.41282 6.79395H2.01562V9.19114H4.41282V6.79395Z"
          fill="#EE792F"
        />
        <path
          d="M12.8015 9.19141H10.4043V11.5886H12.8015V9.19141Z"
          fill="black"
        />
        <path
          d="M14.0007 9.19141H11.6035V11.5886H14.0007V9.19141Z"
          fill="#EB5829"
        />
        <path
          d="M12.8015 11.5889H10.4043V13.9861H12.8015V11.5889Z"
          fill="black"
        />
        <path
          d="M4.41282 9.19141H2.01562V11.5886H4.41282V9.19141Z"
          fill="#EB5829"
        />
        <path
          d="M14.0007 11.5889H11.6035V13.9861H14.0007V11.5889Z"
          fill="#EA3326"
        />
        <path
          d="M4.41282 11.5889H2.01562V13.9861H4.41282V11.5889Z"
          fill="#EA3326"
        />
      </g>
      <defs>
        <clipPath id="clip0_2068_87797">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MISTRAL_API;
