import { IconProps } from '..';

function AWS(props: IconProps) {
  return (
    <svg
      width="32"
      height="38"
      viewBox="0 0 32 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2652_27289)">
        <path
          d="M9.36483 34.5739L15.9961 37.8898L16.6438 37.4066L16.3712 0.441045L15.9961 0L9.36483 3.31593V34.5739Z"
          fill="#9D5025"
        />
        <path
          d="M22.6273 34.5739L15.9961 37.8898V0L22.6273 3.31593V34.5739Z"
          fill="#F68536"
        />
        <path
          d="M13.4958 13.7917L11.1687 14.1305L0.309677 12.907L0.355645 12.7865L2.11774 12.2578L2.30517 12.364L2.34758 12.2578L13.4258 13.5526L13.4958 13.7917Z"
          fill="#6B3A19"
        />
        <path
          d="M0.309677 7.844L2.30517 6.8457V12.3645L0.309677 12.9076V7.844Z"
          fill="#9D5025"
        />
        <path
          d="M31.6903 16.0658L20.577 16.6627L18.4962 16.52L18.8882 16.357L29.7213 15.7363L31.6903 16.0658Z"
          fill="#6B3A19"
        />
        <path
          d="M13.4958 13.7922L2.30517 12.3645V6.8457L13.4958 9.47065V13.7922Z"
          fill="#F68536"
        />
        <path
          d="M29.6869 10.0791L18.4962 12.0025V16.5199L29.6869 15.8484L29.752 15.7209L29.729 10.1818L29.6869 10.0791Z"
          fill="#9D5025"
        />
        <path
          d="M31.6903 16.0657L29.6869 15.8052V10.0791L31.6903 10.8127V16.0657Z"
          fill="#F68536"
        />
      </g>
      <defs>
        <clipPath id="clip0_2652_27289">
          <rect
            width="31.388"
            height="38"
            fill="white"
            transform="translate(0.306)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AWS;
