import ImageWithFallback from '@/components/singulr/ImageWithFallback';
import { getRiskIcon } from '@/components/singulr/riskPills';
import { useQuery } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import { getUnixTime, subDays } from 'date-fns';
import { Box } from 'lucide-react';
import { shallowEqual, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { allApi } from 'src/api';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { HeaderCell } from 'src/pages/aiService/ai-service-dashboard/selected-service/usage/activity/activity-table-config';

import {
  DURATION_OPTIONS,
  IAiServiceEntity,
  TDurationKeys,
} from 'src/pages/aiService/shared';
import { getCompactNumber, getLogoUrl } from 'src/utils/common';
import { CountLink } from 'src/utils/common-components';

export function TitleCell({
  info,
}: {
  info: CellContext<IAiServiceEntity, unknown>;
}) {
  const name = info?.row?.original?.name;
  const categories = info?.row?.original?.categories;
  const logoUrl = getLogoUrl(name);

  return (
    <div className="grid grid-cols-[30px_auto] items-center justify-items-center gap-3">
      <ImageWithFallback
        className="size-4"
        src={logoUrl}
        fallback={
          <div className="size-3.5 p-1 border box-content border-indigo-500 border-opacity-50 rounded-full flex items-center justify-center">
            <Box />
          </div>
        }
      />
      <div className="flex flex-col gap-0.5">
        <NavLink
          to={`/dashboard/aiServices?aiServicesFilters=${JSON.stringify([
            { values: [name], column: 'name' },
          ])}`}
          className="text-sm font-medium text-blue-400 hover:text-blue-500 line-clamp-2"
          target="_blank"
        >
          {name}
        </NavLink>
        <p className="text-xs text-txt-help line-clamp-2">{categories}</p>
      </div>
    </div>
  );
}
export function NumericCell({
  info,
}: {
  info: CellContext<IAiServiceEntity, unknown>;
}) {
  return (
    <div className="tabular-nums text-xs">{info.getValue() as string}</div>
  );
}
const allowedRiskValues = ['low', 'medium', 'high'];
export function RiskCell({
  info,
}: {
  info: CellContext<IAiServiceEntity, unknown>;
}) {
  const label = (info.getValue() as string)?.toLowerCase();

  return (
    <div className="flex items-center gap-1">
      {getRiskIcon({ risk: info.getValue() as string })}
      <p className="text-xs text-txt-secondary capitalize">
        {allowedRiskValues.includes(label) ? label : '--'}
      </p>
    </div>
  );
}

export function TotalUserCell({
  info,
}: {
  info: CellContext<IAiServiceEntity, unknown>;
}) {
  const serviceName = info.row?.original?.name;
  const duration = useSelector(
    (state: ReduxRootState) => state.execDashboard.duration,
    {
      equalityFn: shallowEqual,
    }
  );

  const startTime =
    getUnixTime(
      subDays(new Date(), DURATION_OPTIONS[duration as TDurationKeys] || 7)
    ) * 1000;

  const { data: departmentsData, isLoading } = useQuery({
    queryKey: ['user-departments-facets', duration, serviceName],
    enabled: !!serviceName,
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 5,
          queryId: 'user_table',
          facetProperty: 'department',
          includeCount: true,
          getFacetDataParam: {
            filters: [
              {
                values: [serviceName || ''],
                column: 'aiServices',
              },
            ],
            context: {
              start_time: startTime,
              end_time: Date.now(),
            },
          },
        })
        .then((res) => {
          const topDepartment = (res.data?.[0]?.value?.toLowerCase() ===
          'unknown'
            ? res.data?.[1]
            : res.data?.[0]) || {
            value: '--',
            count: 0,
          };
          return topDepartment;
        }),
  });

  return (
    <div className="flex flex-col items-start justify-center">
      <CountLink
        count={getCompactNumber(info?.getValue() as number)}
        link={`/all-assets/user_table?filters=${encodeURIComponent(
          JSON.stringify([
            { column: 'aiServices', values: [serviceName || ''] },
          ])
        )}`}
        fontSize="text-xs"
      />
      {isLoading ? (
        <div className="w-20 h-4 animate-pulse bg-slate-800 rounded" />
      ) : (
        <div className="text-xs text-txt-help">
          {departmentsData?.value}-
          <CountLink
            count={getCompactNumber(departmentsData?.count)}
            link={`/all-assets/user_table?filters=${encodeURIComponent(
              JSON.stringify([
                { column: 'aiServices', values: [serviceName || ''] },
                {
                  column: 'department',
                  values: [departmentsData?.value || ''],
                },
              ])
            )}`}
            fontSize="text-xs"
          />
        </div>
      )}
    </div>
  );
}

export const extServiceColumns = [
  {
    accessorKey: 'name',
    id: 'name',
    minSize: 200,
    enableSorting: false,
    enableColumnFilter: false,
    cell: (props: CellContext<IAiServiceEntity, unknown>) => (
      <TitleCell info={props} />
    ),
    size: 250,
    header: () => <HeaderCell columnDef={{ header: 'Name' }} />,
  },
  {
    accessorKey: 'riskScoreCategory',
    id: 'riskScoreCategory',
    minSize: 100,
    enableSorting: false,
    enableColumnFilter: false,

    cell: (props: CellContext<IAiServiceEntity, unknown>) => (
      <RiskCell info={props} />
    ),
    size: 100,
    header: () => <HeaderCell columnDef={{ header: 'Risk' }} />,
  },
  // {
  //   accessorKey: 'endpoint_departments',
  //   id: 'endpoint_departments',
  //   minSize: 250,
  //   enableSorting: false,
  //   enableColumnFilter: false,
  //   cell: (props: CellContext<IAiServiceEntity, unknown>) => (
  //     <DepartmentsCell info={props} />
  //   ),
  //   size: 200,
  //   header: () => <HeaderCell columnDef={{ header: 'Departments' }} />,
  // },
  {
    accessorKey: 'countOfUsers',
    id: 'countOfUsers',
    minSize: 250,
    enableSorting: false,
    enableColumnFilter: false,
    cell: (props: CellContext<IAiServiceEntity, unknown>) => (
      <TotalUserCell info={props} />
    ),
    size: 250,
    header: () => <HeaderCell columnDef={{ header: 'Users' }} />,
  },
];
