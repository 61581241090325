import clsx from 'clsx';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '../../../@/components/shadcn/tooltip';

function TooltipWrapper({
  children,
  title,
  sideOffset = 4,
  side = 'top',
  contentClassName,
}: {
  children: React.ReactNode;
  title: React.ReactNode;
  sideOffset?: number;
  side?: 'top' | 'right' | 'bottom' | 'left';
  contentClassName?: string;
}) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="cursor-default">{children}</div>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          sideOffset={sideOffset}
          side={side}
          className={clsx(
            'dark:bg-gray-900 dark:text-txt-secondary max-w-[50ch]',
            contentClassName
          )}
        >
          {title}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
}

export default TooltipWrapper;
