import { ChevronDownIcon, ChevronRight } from 'lucide-react';
import { useCallback } from 'react';
import { Event, StringTemplate } from 'src/generated';
import LightBulb from '../../assets/common/LightBulb';
import { getContentFromRecommendation } from '../../utils/common';
import { useToggle } from '../../utils/hooks';

interface InsightProps {
  ev: Event;
  isLast?: boolean;
}
function Insight({ ev }: InsightProps) {
  const [showRecommendation, toggleShowRecommendation] = useToggle(true);
  const getContent = useCallback(
    (
      content: StringTemplate | undefined,
      fallbackText: string
    ): React.ReactNode => {
      const pieces = getContentFromRecommendation(content, fallbackText, true);
      return Array.isArray(pieces)
        ? pieces
            .filter((p) => !!p.text)
            ?.map((piece: any) => (
              <span
                key={piece.text + piece.type}
                className={
                  piece.type === 'HIGHLIGHT'
                    ? 'text-sm text-slate-300 bg-slate-800 px-1 py-0.5 rounded-sm'
                    : 'text-sm text-slate-300'
                }
              >
                {piece.text}
              </span>
            ))
        : pieces;
    },
    [ev]
  );
  return (
    <div className="p-2.5 ">
      <div className="flex gap-2 items-start">
        <LightBulb className="min-w-[18px] mt-1" />
        <p className="text-txt-primary text-sm leading-7">
          {getContent(ev?.summary, ev.description ?? '')}
        </p>
      </div>
      {ev.recommendation ? (
        <div className="flex flex-col gap-2 p-2.5 pl-6">
          <div
            className="text-blue-400 flex cursor-pointer gap-2 items-center"
            onClick={toggleShowRecommendation}
          >
            {showRecommendation ? (
              <ChevronDownIcon className="size-4" />
            ) : (
              <ChevronRight className="size-4" />
            )}
            <p className="text-sm">
              {showRecommendation ? 'Hide' : 'Show'} Recommendation
            </p>
          </div>
          {showRecommendation ? (
            <div className="pl-6 text-sm text-txt-primary">
              {getContent(ev.recommendation, ev.description ?? '')}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default Insight;
