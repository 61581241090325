import { IconProps } from '..';

function SINGULR(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2068_87850)">
        <path
          d="M10.4482 3.88143C10.7487 3.37577 10.5842 2.71178 10.0325 2.50763C9.24446 2.21603 8.39883 2.09593 7.55276 2.16073C6.35068 2.2528 5.2063 2.71362 4.27585 3.48028C3.34541 4.24694 2.67425 5.28207 2.354 6.44436C2.03374 7.60665 2.08 8.83946 2.48647 9.97449C2.89293 11.1095 3.63978 12.0914 4.62507 12.7862C5.61035 13.481 6.78605 13.8547 7.99165 13.8564C9.19726 13.8582 10.374 13.4878 11.3613 12.7958C12.0561 12.3088 12.6333 11.6792 13.0571 10.9536C13.3537 10.4457 13.0594 9.82814 12.5061 9.62824C11.9529 9.42834 11.3529 9.72864 11.0052 10.2031C10.766 10.5294 10.4738 10.8164 10.1386 11.0514C9.51048 11.4916 8.76176 11.7273 7.99469 11.7262C7.22762 11.7251 6.47958 11.4873 5.85268 11.0453C5.22579 10.6032 4.75061 9.97845 4.49199 9.25629C4.23338 8.53413 4.20394 7.74975 4.40771 7.01023C4.61147 6.27072 5.0385 5.61211 5.6305 5.12432C6.2225 4.63653 6.95061 4.34334 7.71544 4.28476C8.12357 4.2535 8.53154 4.28986 8.92349 4.39013C9.49339 4.53592 10.1476 4.3871 10.4482 3.88143Z"
          fill="#F1F1F1"
        />
        <circle cx="12.4417" cy="6.48665" r="1.61556" fill="#84CC16" />
      </g>
      <defs>
        <clipPath id="clip0_2068_87850">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SINGULR;
