import { configureStore } from '@reduxjs/toolkit';
import policySlice from 'src/pages/Policies/newPolicy/policySlice';
import trustFeedSlice from 'src/pages/TrustFeed/trustFeedSlice';
import onboardingSlice from 'src/pages/onboarding/onboardingSlice';
import aiServiceSlice from 'src/pages/aiService/ai-service-slice';
import topologySlice from 'src/pages/Dashboards/appDetails/app-topology/topology-slice';
import riskSlice from 'src/pages/Discovery/ai-risk/risk-slice';
import detailsTableSlice from 'src/components/DetailsTable/slice/details-table-slice';
import execDashboardSlice from 'src/pages/executive-dashboard/exec-dashboard-slice';
import saasServiceSlice from 'src/pages/Dashboards/EntityDetails/ThirdartyAiService/saas-service-slice';
import { emptySplitApi } from './emptyApi';
import appCommonSlice from './app-common-slice';

const reduxStore = configureStore({
  reducer: {
    trustFeed: trustFeedSlice,
    onboarding: onboardingSlice,
    aiService: aiServiceSlice,
    policy: policySlice,
    appCommon: appCommonSlice,
    topology: topologySlice,
    detailsTable: detailsTableSlice,
    risk: riskSlice,
    execDashboard: execDashboardSlice,
    saasService: saasServiceSlice,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([emptySplitApi.middleware]),
});

export default reduxStore;
