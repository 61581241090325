import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface ISaasServiceEntity {
  id: string;
  name: string;
}

interface SaasServiceState {
  selectedService: ISaasServiceEntity | null;
  selectedServiceId: string;
  usageDuration: number;
  usageSubRange: number;
  aiFeatures: {
    id: string;
    name: string;
    subFeatures: {
      id: string;
      name: string;
      isDisabled: boolean;
    }[];
    isDisabled: boolean;
  }[];
}

const initialState: SaasServiceState = {
  selectedService: null,
  selectedServiceId: '',
  usageDuration: 30,
  usageSubRange: 0,
  aiFeatures: [],
};

export const saasServiceSlice = createSlice({
  name: 'saas-service',
  initialState,
  reducers: {
    setSelectedService: (
      state,
      action: PayloadAction<ISaasServiceEntity | null>
    ) => {
      state.selectedService = action.payload;
      state.selectedServiceId = action.payload?.id || '';
      state.usageSubRange = 0;
    },
    setUsageDuration: (state, action: PayloadAction<number>) => {
      state.usageDuration = action.payload;
      state.usageSubRange = 0;
    },
    setUsageSubRange: (state, action: PayloadAction<number>) => {
      state.usageSubRange = action.payload;
    },
    setAiFeatures: (
      state,
      action: PayloadAction<
        {
          id: string;
          name: string;
          subFeatures: {
            id: string;
            name: string;
            isDisabled: boolean;
          }[];
          isDisabled: boolean;
        }[]
      >
    ) => {
      state.aiFeatures = action.payload;
    },
  },
});

export const {
  setSelectedService,
  setUsageDuration,
  setUsageSubRange,
  setAiFeatures,
} = saasServiceSlice.actions;

export default saasServiceSlice.reducer;
