import { IconProps } from '..';

function EC2(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2068_87394)">
        <path
          d="M3.83797 12.0416L2.55273 12.3499V3.59961L3.83797 3.90847V12.0416Z"
          fill="#F68536"
        />
        <path
          d="M5.44145 12.5582L3.83789 13.0372V2.92441L5.44145 3.4029V12.5582ZM7.49849 13.0888L5.44145 13.8802V2.08143L7.49849 2.87248V13.0888ZM12.9987 12.0681L7.49849 14.9592V1L12.9987 3.89109V12.0681Z"
          fill="#F68536"
        />
        <path
          d="M6.40281 1.57639L7.4992 1V14.9592L6.40281 14.383V1.57639ZM4.59344 2.52735L5.44217 2.08125V13.8779L4.59344 13.4318V2.52735ZM3.16252 3.27961L3.83861 2.92423V13.0349L3.16252 12.6793V3.27961ZM2.55337 3.59971L2.00195 3.88955V12.0698L2.55337 12.3595V3.59971Z"
          fill="#9D5025"
        />
      </g>
      <defs>
        <clipPath id="clip0_2068_87394">
          <rect
            width="11"
            height="14"
            fill="white"
            transform="translate(2 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EC2;
