import { memo, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PrimaryNavigation } from 'src/components/Navigations/PrimaryNavigation';
import PublicRouteNotFound from 'src/layout/PublicRouteNotFound';
import UnAuthorized from 'src/layout/UnAuthorized';
import FileNotFound from '../layout/RouteNotFound';
import { privateRoutesConfig, publicRoutesConfig } from './routes';

function AllRoutes({ isAuthenticated }: { isAuthenticated: boolean }) {
  const { pathname } = useLocation();
  const pseudoPublicPage = pathname === '/welcome';

  if (isAuthenticated && !pseudoPublicPage)
    return (
      <div className="main-content-box">
        <PrimaryNavigation />
        <Suspense
          fallback={
            <div className="grid place-items-center w-full h-screen">
              <div className="sui-loader" />
            </div>
          }
        >
          <div className="flex transition-all duration-300 pr-4 pl-6 bg-[--page] h-screen">
            <Routes>
              {privateRoutesConfig.map((route) => (
                <Route
                  path={route.path}
                  Component={route.component}
                  key={route.path}
                />
              ))}
              <Route
                path="/"
                element={<Navigate to="/executive-dashboard" />}
              />
              <Route path="/unAuthorized" Component={UnAuthorized} />
              <Route path="*" Component={FileNotFound} />
            </Routes>
          </div>
        </Suspense>
      </div>
    );

  return (
    <Suspense
      fallback={
        <div className="grid place-items-center w-full h-screen">
          <div className="sui-loader" />
        </div>
      }
    >
      <Routes>
        {publicRoutesConfig.map((route) => (
          <Route
            path={route.path}
            Component={route.component}
            key={route.path}
          />
        ))}
        <Route path="*" Component={PublicRouteNotFound} />
      </Routes>
    </Suspense>
  );
}

export default memo(AllRoutes);
