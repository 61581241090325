import React from 'react';
import LightBulb from 'root/src/assets/common/LightBulb';

import { ScrollArea } from '@/components/shadcn/scroll-area';
import Separator from '@/components/shadcn/separator';
import { useQuery } from '@tanstack/react-query';
import { allApi } from 'src/api';
import SomethingWentWrong from 'src/utils/something-went-wrong';
import Insight from './Insight';
import clsx from 'clsx';

interface SInsightsListProps {
  title?: string;
  height?: number;
  titleClass?: string;
}

function InsightsList({ title, height, titleClass }: SInsightsListProps) {
  const {
    data: insightsResponse,
    isLoading: insightsLoading,
    isError,
  } = useQuery({
    queryKey: ['asset-insights'],
    queryFn: () =>
      allApi.eventsGet({
        eventType: 'INSIGHT',
        anchorEntityTypes: [
          'VM',
          'ML_MODEL',
          'AI_SERVICE',
          'VECTOR_DB',
          'APP_GROUP',
          'TENANT_ENTITY',
        ],
      }),
  });
  const insights = insightsResponse?.data?.events ?? [];

  return (
    <div className="bg-card border rounded-10 p-5 shadow-[0_4px_4px_0_rgba(0,0,0,0.45)] flex flex-col gap-[18px]">
      <h2
        className={clsx(
          'text-txt-primary font-medium',
          titleClass || 'text-lg'
        )}
      >
        {title ?? 'Asset Insights'}
      </h2>
      {insightsLoading ? (
        <div className=" flex flex-col">
          <div className="h-8 w-full border-b animate-pulse bg-opacity-30 flex gap-3">
            <LightBulb className=" text-orange-500" />{' '}
            <p className=" flex-1 h-4 bg-slate-800" />
          </div>
          <Separator className="mt-4 mb-6" />
          <div className="h-8 w-full border-b animate-pulse bg-opacity-30 flex gap-3">
            <LightBulb className=" text-orange-500" />{' '}
            <p className=" flex-1 h-4 bg-slate-800" />
          </div>
          <Separator className="mt-4 mb-6" />
          <div className="h-8 w-full border-b animate-pulse bg-opacity-30 flex gap-3">
            <LightBulb className=" text-orange-500" />{' '}
            <p className=" flex-1 h-4 bg-slate-800" />
          </div>
        </div>
      ) : null}
      {!insightsLoading && isError ? (
        <SomethingWentWrong heightClass="h-[60vh]" />
      ) : null}
      {!insightsLoading && insights?.length > 0 ? (
        <ScrollArea
          style={{ height: height ?? window.screen.availHeight - 140 }}
          type="always"
        >
          <>
            {insights?.map((ev, ind) => (
              <React.Fragment key={`${ev.entity_id}_${ind}}`}>
                <Insight ev={ev} />
                {ind !== insights.length - 1 ? (
                  <Separator className="my-2" />
                ) : null}
              </React.Fragment>
            ))}
          </>
        </ScrollArea>
      ) : null}
    </div>
  );
}

export default InsightsList;
