import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as React from 'react';
import type { ElementRef, ComponentPropsWithoutRef } from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import cn from '@/lib/utils';
interface CheckboxProps extends CheckboxPrimitive.CheckboxProps {
  size?: 'sm' | 'md';
}
const Checkbox = React.forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & CheckboxProps
>(({ className, size = 'md', ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer  shrink-0 rounded-[2px] border  shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-slate-900 data-[state=checked]:text-slate-50 dark:border-primary dark:focus-visible:ring-slate-300 dark:data-[state=checked]:bg-slate-50  dark:data-[state=checked]:!bg-primary dark:data-[state=checked]:text-slate-100 dark:data-[state=checked]:border-0',
      size === 'sm' ? 'h-3.5 w-3.5' : 'h-4 w-4',
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      <CheckIcon className={size === 'sm' ? 'h-3.5 w-3.5' : 'h-4 w-4'} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;
