import { Fragment, ImgHTMLAttributes, useEffect, useState } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: JSX.Element;
}

function ImageWithFallback({ fallback, src, ...props }: ImageProps) {
  const [showFallback, setShowFallback] = useState<boolean>(false);

  useEffect(() => {
    setShowFallback(false);
  }, [src]);

  if (showFallback) {
    return <Fragment key={`facllback-img-${src}`}>{fallback}</Fragment>;
  }

  return (
    <img
      key={`img-${src}`}
      alt="!"
      src={src}
      {...props}
      onError={() => {
        setShowFallback(true);
      }}
    />
  );
}

export default ImageWithFallback;
