import { useQuery } from '@tanstack/react-query';
import { Hexagon } from 'lucide-react';
import { allApi } from 'src/api';
import ProgressBar from 'src/components/progress-bar';
import { CountLink, ShimmerLoading } from 'src/utils/common-components';

function EmbeddedAiPosture() {
  const { data: totalCount, isLoading } = useQuery({
    queryKey: ['embedded-ai-services-count'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 89,
          queryId: 'saas_service_table',
          includeCount: true,
          facetProperty: 'entityType',
        })
        .then((resp) => resp?.data?.[0]?.count),
  });
  const { data: allCount, isLoading: isAllLoading } = useQuery({
    queryKey: ['embedded-ai-services-all-count'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 89,
          queryId: 'saas_service_all_table',
          includeCount: true,
          facetProperty: 'entityType',
        })
        .then((resp) => resp?.data?.[0]?.count),
  });
  const percentage = ((totalCount ?? 0) / (allCount ?? 0)) * 100;

  const { data: defaultOnCount, isLoading: isDefaultOnLoading } = useQuery({
    queryKey: ['facets', 'defaultOptInAiFeatures'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          facetProperty: 'isDefaultOptInAi',
          queryId: 'saas_service_table',
          includeCount: true,
          entityType: 89,
        })
        .then(
          (resp) => resp?.data?.find((item) => item?.value === 'TRUE')?.count
        ),
  });

  return (
    <div className="bg-card rounded-md border border-border p-5 h-full">
      <div className="flex gap-1 items-center mb-2">
        <Hexagon className="size-4 text-txt-label" />
        <p className="text-txt-label text-sm">Vendors With Embedded AI </p>
      </div>

      <div className="flex items-start gap-8">
        <div className="flex flex-col gap-2">
          {isLoading ? (
            <ShimmerLoading height="h-8" width="w-10" />
          ) : (
            <CountLink
              count={totalCount}
              link="/dashboard/thirdparty-aiServices"
              fontSize="text-3xl"
              fontWeight="font-semibold"
              noPadding
            />
          )}
          <div className="flex flex-col gap-2">
            {isAllLoading ? (
              <ShimmerLoading height="h-4" width="w-28" className="mt-0.5" />
            ) : (
              <div className="flex items-center gap-2">
                <ProgressBar percentage={percentage} />
                <div className="flex items-center gap-1 whitespace-nowrap">
                  <p className="text-xs text-txt-secondary">
                    {Math.round(percentage ?? 0)}% of Total
                  </p>
                  <CountLink
                    count={allCount}
                    link="/all-assets/saas_service_all_table"
                    fontSize="text-xs"
                    fontWeight="font-semibold"
                    noPadding
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {isDefaultOnLoading ? (
            <ShimmerLoading height="h-8" width="w-10" />
          ) : (
            <CountLink
              count={defaultOnCount}
              link={`/dashboard/thirdparty-aiServices?filters=${encodeURIComponent(
                JSON.stringify([
                  { column: 'isDefaultOptInAi', values: ['TRUE'] },
                ])
              )}`}
              fontSize="text-3xl"
              fontWeight="font-semibold"
              noPadding
            />
          )}
          <p className="text-txt-secondary text-xs">Default-on AI Features</p>
        </div>
      </div>
      <div className="flex flex-col gap-2"></div>
    </div>
  );
}

export default EmbeddedAiPosture;
