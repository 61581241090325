import { Node as FlowNode } from '@xyflow/react';
import {
  Cog,
  Component,
  Database,
  Dice1,
  Dice5,
  Dice6,
  FileBox,
  FileDigit,
  Laptop2,
  Network,
  ServerCog,
  SquareDashed,
  User,
} from 'lucide-react';
import { GraphResponse, Link, Node, Property, SubGraph } from 'src/generated';

export type TopologyEntityNodeData = {
  id: string;
  entity_id: string;
  entity_type: number;
  name: string;
  parent_id?: string;
  properties?: Array<Property>;
  isLeftEnabled?: boolean;
  isRightEnabled?: boolean;
  isCenterNode?: boolean;
  subGraphTitle: string;
  isPartOfSubGraph: boolean;
  entity_ids?: string[];
};

export const getEntityColor = (entity_type: number) => {
  switch (entity_type) {
    case 19:
      return '#6366f1';
    case 34:
    case 73:
      return '#10b981';
    case 27:
    case 39:
    case 23:
    case 30:
    case 50:
      return '#ec4899';
    case 80:
    case 1014:
      return '#0ea5e9';
    case 5:
      return '#d946ef';
    case 49:
      return '#c084fc';
    case 405:
    case 123:
      return '#64748b';
    case 21:
      return '#a855f7';

    default:
      return '#475569';
  }
};

export const getEntityBgClass = (entity_type: number) => {
  switch (entity_type) {
    case 19:
      return 'group-hover:bg-indigo-500/40'; // #6366f1
    case 34:
    case 73:
      return 'group-hover:bg-emerald-500/40'; // #10b981
    case 27:
    case 39:
    case 23:
    case 30:
    case 50:
      return 'group-hover:bg-pink-500/40'; // #ec4899
    case 80:
    case 1014:
      return 'group-hover:bg-sky-500/40'; // #0ea5e9
    case 5:
      return 'group-hover:bg-fuchsia-500/40'; // #d946ef
    case 49:
      return 'group-hover:bg-purple-400/40'; // #c084fc
    case 405:
    case 123:
      return 'group-hover:bg-slate-500/40'; // #64748b
    case 21:
      return 'group-hover:bg-purple-500/40'; // #a855f7
    default:
      return 'group-hover:bg-slate-600/40'; // #475569
  }
};

export function transformEdges(edges: Link[] = [], nodes: Node[] = []) {
  return edges?.map((link) => {
    const srcEntityType =
      nodes?.find((node) => node.id === link.src_id)?.entity_type ?? 0;
    const startColor = getEntityColor(srcEntityType);
    const targetEntityType =
      nodes?.find((node) => node.id === link.dst_id)?.entity_type ?? 0;
    const endColor = getEntityColor(targetEntityType);
    let gradient;

    if (startColor && endColor) {
      gradient = `url(#gradient-${startColor}=${endColor})`;
    }
    if (startColor === endColor) {
      gradient = startColor;
    }

    return {
      id: link?.id || self.crypto.randomUUID(),
      source: link?.src_id || '',
      target: link?.dst_id || '',
      style: {
        stroke: gradient ? gradient : 'rgb(71 85 105)',
        strokeWidth: 1.5,
      },
      type: 'floating',
      data: { startColor, endColor, ...link },
    };
  });
}

export function transformNodes({
  nodes,
  layers,
  subGraph,
  isPartOfSubGraph = false,
  parentNodeId,
}: {
  nodes: Array<Node>;
  layers: string[][];
  subGraph: SubGraph[];
  isPartOfSubGraph?: boolean;
  parentNodeId?: string;
}) {
  // Find the layer with maximum length
  const maxLayerLength = Math.max(...layers.map((layer) => layer.length));

  return nodes?.map((item) => {
    const horizontalIndex = layers.findIndex((layer) =>
      layer.includes(item?.id || '')
    );
    const verticalIndex = layers[horizontalIndex]?.findIndex(
      (layer) => layer === item?.id
    );
    const currentLayerLength = layers[horizontalIndex]?.length || 0;
    const childGraph = subGraph.find((sg) => sg.parent_node_id === item?.id);

    // Calculate vertical offset to center the current layer
    const verticalOffset = ((maxLayerLength - currentLayerLength) * 300) / 2;

    return {
      id: item?.id || self.crypto.randomUUID(),
      position: {
        x: horizontalIndex * 400 + 50 + verticalIndex,
        y: verticalIndex * 300 + verticalOffset + horizontalIndex + 100,
      },
      type: 'entityNode',
      parentId: isPartOfSubGraph ? parentNodeId : item?.parent_id || '',
      data: {
        ...item,
        isLeftEnabled: true,
        subGraphTitle: childGraph?.label || '',
        isPartOfSubGraph,
      },
    };
  });
}

export function transformTopologyResponse(data: GraphResponse) {
  const layers = data?.ui_hints?.layers?.filter((list) => list?.length) || [];
  const subGraph = data?.sub_graph || [];
  const transformedSubGraph = subGraph?.map((detail) => {
    const subLayers =
      detail?.graph?.ui_hints?.layers?.filter((list) => list?.length) || [];
    const maxEntityCount = subLayers?.reduce((acc, cur) => {
      if (cur?.length > acc) return cur?.length;
      return acc;
    }, 1);
    const parentNodeId = `parent:${detail?.parent_node_id || ''}`;
    const parentNode: FlowNode[] = [
      {
        id: parentNodeId,
        position: { x: 0, y: 0 },
        type: 'containerNode',
        style: {
          width: subLayers?.length * 400,
          height: maxEntityCount * 350,
        },
        data: {
          name: detail?.label,
          entity_type: data?.nodes?.find(
            (item) => item?.id === detail?.parent_node_id
          )?.entity_type,
        },
      },
    ];
    return {
      id: parentNodeId,
      label: detail?.label,
      parentId: detail?.parent_node_id,
      layers: subLayers,
      nodes: parentNode.concat(
        transformNodes({
          nodes: detail?.graph?.nodes || [],
          layers: subLayers,
          subGraph,
          isPartOfSubGraph: true,
          parentNodeId,
        })
      ),
      edges: transformEdges(detail?.graph?.links, detail?.graph?.nodes) || [],
      subGraph: detail?.graph?.sub_graph || [],
    };
  });

  return {
    layers,
    nodes: transformNodes({
      nodes: data?.nodes || [],
      layers,
      subGraph,
    }),
    edges: transformEdges(data?.links, data?.nodes) || [],
    subGraph: transformedSubGraph,
  };
}

const entityTypeColors = [
  '#6366f1', // Entity Type 19
  '#10b981', // Entity Types 34, 73
  '#ec4899', // Entity Types 27, 39, 23, 30, 50
  '#0ea5e9', // Entity Types 80, 1014
  '#d946ef', // Entity Type 5
  '#c084fc', // Entity Type 49
  '#64748b', // Entity Types 405, 123
  '#a855f7', // Entity Type 21,
  '#475569',
];

function getPermutations(set: Set<string>, r: number) {
  const result = [] as string[][];
  const arr = Array.from(set);
  const used = new Array(arr.length).fill(false);

  function generate(current: string[]) {
    if (current.length === r) {
      result.push([...current]);
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      if (!used[i]) {
        used[i] = true;
        current.push(arr[i]);
        generate(current);
        current.pop();
        used[i] = false;
      }
    }
  }

  generate([]);
  return result;
}

// Generate SVG <defs> dynamically
export function generateSvgGradients() {
  const colorPairs = getPermutations(new Set(entityTypeColors), 2);

  return (
    <svg viewBox="0 0 10 10" style={{ height: 0, width: 0 }}>
      <defs>
        {colorPairs.map(([color1, color2]) => {
          const gradientId = `gradient-${color1}=${color2}`;
          return (
            <linearGradient key={gradientId} id={gradientId}>
              <stop offset="5%" stopColor={color1} />
              <stop offset="95%" stopColor={color2} />
            </linearGradient>
          );
        })}
      </defs>
    </svg>
  );
}

export function getEntityTypeIcon(entityType: number) {
  switch (entityType) {
    case 19:
    case 73: //AI Service Endpoint
      return Laptop2;
    case 34:
      return Dice5;
    case 23:
    case 27:
      return Database;
    case 80:
    case 1014: // ML Pipeline
      return Cog;
    case 39:
      return FileDigit;
    case 49: // ai service
    case 123: // app service
      return ServerCog;
    case 5:
      return User;
    case 21: // IP Entity
      return Network;
    case 42: // LLM Definition
      return Dice1;
    case 30: // ML Model
      return Dice6;
    case 405: //API Gateway Management
      return Component;
    case 50: //ML Platform
      return SquareDashed;

    default:
      return FileBox;
  }
}
