import { IconProps } from '..';

function ZSCALER(props: IconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 1570 974"
      width="1570"
      height="974"
    >
      <g id="nav">
        <path
          id="Layer"
          fillRule="evenodd"
          fill="#236bf5"
          d="m1566.1 496.2c25.3 155.6-109.7 243.2-246.6 253.3-87.1 179.3-394.3 300.2-660 169.8-113.2 17.2-176.3-11.3-224.4-65.6 96.9-125.3 391.6-350.7 751.6-234.1 192.4 62 242.3-84 193.9-138.7-181.4-206-591-20.6-605.5-5.1 160.8-246.4 736.2-315.4 791 20.4zm-551.3-283c0.9 0-130.9-47-315 32.6q-2.8-1-5.5-2.1-2.8-1.1-5.5-2.2-2.8-1.2-5.5-2.5-2.7-1.3-5.3-2.6c177.2-117.7 332.4-167.7 465.4-148.3-80.6-92.4-466.9-160.5-688.3 61.5-273.6-53.9-465.2 178.1-454.3 390 10.9 211.8 242.1 344.1 373.8 308q1.1-0.1 2.3-0.2 1.2 0 2.4 0 1.2 0 2.3 0 1.2 0.1 2.4 0.2c29.4-139.9 148.2-465.7 630.8-634.4z"
        />
      </g>
    </svg>
  );
}

export default ZSCALER;
