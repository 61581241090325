import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/shadcn/card';
import { useQueries } from '@tanstack/react-query';
import { useState } from 'react';
import { allApi } from 'src/api';
import GovernanceTrendChart from 'src/pages/aiService/trendCharts/governanceTrendChart';
import SuiLoader from 'src/utils/sui-loader';
import ApprovalStatusChartLegend, {
  getApprovalStatusClass,
} from './approval-status-chart-legend';
import useTrendChartData from './useTrendChartData';
import { FacetData } from 'src/generated';
import clsx from 'clsx';
import { DividerVerticalIcon } from '@radix-ui/react-icons';
import SomethingWentWrong from 'src/utils/something-went-wrong';

const entityList = [
  { id: 49, name: 'External AI Services', queryId: 'ai_services_table' },
  { id: 89, name: 'Embedded AI', queryId: 'saas_service_table' },
  { id: 34, name: 'LLMs', queryId: 'llm_instance_table' },
];

type EntityData = {
  id: number;
  name: string;
  data: FacetData[];
};

type CombinedDataType = EntityData[];

function GovernanceTrend() {
  const [selectedEntity, setSelectedEntity] = useState(entityList[0]?.id);
  const {
    governanceTrendChartData,
    isLoading: isLoadingTrendData,
    isError: isErrorTrendData,
  } = useTrendChartData(selectedEntity);

  const { data: combinedData, isLoading: isLoadingAllEntities } = useQueries({
    queries: entityList.map((entity) => ({
      queryKey: ['facets-governance-all', entity.id],
      queryFn: async () =>
        allApi
          .getEntityFacets({
            facetProperty: 'approvalStatus',
            queryId: entity.queryId,
            includeCount: true,
            entityType: entity.id,
          })
          .then((res) => ({
            id: entity.id,
            name: entity.name,
            data: res?.data,
          })),
    })),
    combine(responses) {
      return {
        data: responses?.reduce<CombinedDataType>((acc, query) => {
          if (query?.data) {
            acc.push(query?.data);
          }
          return acc;
        }, []),
        isLoading: responses.some((response) => response?.isPending),
      };
    },
  });

  const isLoading = isLoadingTrendData || isLoadingAllEntities;

  if (isLoading)
    return (
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-txt-primary text-base font-medium">
            Governance Trend
          </CardTitle>
        </CardHeader>
        <CardContent className="w-full grid place-items-center">
          <SuiLoader heightClass="h-72" />
        </CardContent>
      </Card>
    );

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-txt-primary text-base font-medium">
          Governance Trend
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-0">
        <div className="flex flex-wrap gap-4 mb-6 items-center">
          {combinedData?.map((entity) => {
            const totalCount = entity?.data?.reduce(
              (sum, item) => sum + (item?.count || 0),
              0
            );
            return (
              <div
                key={entity.id}
                onClick={() => setSelectedEntity(entity.id)}
                className={clsx(
                  'flex items-center justify-between px-4 py-2 rounded-md cursor-pointer transition-all border hover:bg-slate-800/50 bg-page',
                  selectedEntity === entity.id &&
                    'bg-background shadow-sm border-primary'
                )}
              >
                <div className="flex items-center gap-3 flex-wrap">
                  <p className="text-sm font-normal text-txt-secondary">
                    {entity?.name}
                  </p>
                  <p className="text-sm font-medium text-txt-primary">
                    {totalCount}
                  </p>
                  {totalCount ? (
                    <>
                      <DividerVerticalIcon className="h-4 text-slate-700" />
                      {entity?.data?.map((item) => {
                        return (
                          <div className="flex items-center gap-2">
                            <div
                              className={`w-2.5 h-1 rounded-md ${getApprovalStatusClass(item?.value?.toLocaleLowerCase() || '')}`}
                            ></div>
                            <p className="text-xs font-medium text-white">
                              {item?.count}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex flex-col w-full h-60">
          {isErrorTrendData ? (
            <SomethingWentWrong
              hideReloadButton
              heightClass="h-48"
              imgSize="small"
            />
          ) : (
            <GovernanceTrendChart
              labelFormat="d MMM"
              data={governanceTrendChartData}
              yAxisLabel={
                entityList?.find((entity) => entity?.id === selectedEntity)
                  ?.name || ''
              }
            />
          )}
          <ApprovalStatusChartLegend />
        </div>
      </CardContent>
    </Card>
  );
}

export default GovernanceTrend;
