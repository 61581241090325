import { IconProps } from '..';

function AZURE_AI_CONTENT(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2068_87697)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.95928 2.23629C10.3015 2.23629 10.6048 2.49296 10.7137 2.86629C10.8226 3.23963 11.4604 5.54963 11.4604 5.54963V10.1385H9.15039L9.19706 2.22852H9.95928V2.23629Z"
          fill="url(#paint0_linear_2068_87697)"
        />
        <path
          d="M13.3521 5.82196C13.3521 5.65862 13.2199 5.53418 13.0643 5.53418H11.7032C10.7465 5.53418 9.96875 6.31196 9.96875 7.26862V10.1464H11.6176C12.5743 10.1464 13.3521 9.36862 13.3521 8.41196V5.82196Z"
          fill="url(#paint1_linear_2068_87697)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.95948 2.23633C9.69504 2.23633 9.48504 2.44633 9.48504 2.71077L9.43837 11.4452C9.43837 12.7208 8.40392 13.7552 7.12837 13.7552H2.93615C2.73392 13.7552 2.6017 13.5608 2.66392 13.3741L6.02392 3.78411C6.35059 2.85855 7.2217 2.23633 8.2017 2.23633H9.96726H9.95948Z"
          fill="url(#paint2_linear_2068_87697)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2068_87697"
          x1="10.9237"
          y1="10.3096"
          x2="8.97928"
          y2="2.52407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#712575" />
          <stop offset="0.09" stopColor="#9A2884" />
          <stop offset="0.18" stopColor="#BF2C92" />
          <stop offset="0.27" stopColor="#DA2E9C" />
          <stop offset="0.34" stopColor="#EB30A2" />
          <stop offset="0.4" stopColor="#F131A5" />
          <stop offset="0.5" stopColor="#EC30A3" />
          <stop offset="0.61" stopColor="#DF2F9E" />
          <stop offset="0.72" stopColor="#C92D96" />
          <stop offset="0.83" stopColor="#AA2A8A" />
          <stop offset="0.95" stopColor="#83267C" />
          <stop offset="1" stopColor="#712575" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2068_87697"
          x1="11.6643"
          y1="2.39973"
          x2="11.6643"
          y2="13.382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA7ED0" />
          <stop offset="0.08" stopColor="#B17BD5" />
          <stop offset="0.19" stopColor="#8778DB" />
          <stop offset="0.3" stopColor="#6276E1" />
          <stop offset="0.41" stopColor="#4574E5" />
          <stop offset="0.54" stopColor="#2E72E8" />
          <stop offset="0.67" stopColor="#1D71EB" />
          <stop offset="0.81" stopColor="#1471EC" />
          <stop offset="1" stopColor="#1171ED" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2068_87697"
          x1="11.0017"
          y1="2.64855"
          x2="3.7217"
          y2="14.323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA7ED0" />
          <stop offset="0.05" stopColor="#B77BD4" />
          <stop offset="0.11" stopColor="#9079DA" />
          <stop offset="0.18" stopColor="#6E77DF" />
          <stop offset="0.25" stopColor="#5175E3" />
          <stop offset="0.33" stopColor="#3973E7" />
          <stop offset="0.42" stopColor="#2772E9" />
          <stop offset="0.54" stopColor="#1A71EB" />
          <stop offset="0.68" stopColor="#1371EC" />
          <stop offset="1" stopColor="#1171ED" />
        </linearGradient>
        <clipPath id="clip0_2068_87697">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AZURE_AI_CONTENT;
