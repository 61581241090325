import { IconProps } from '..';

function GCP_STORAGE(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <title>Icon_24px_CloudStorage_Color</title>
      <g data-name="Product Icons">
        <rect fill="#aecbfa" x="2" y="4" width="20" height="7" />
        <rect fill="#669df6" x="20" y="4" width="2" height="7" />
        <polygon fill="#4285f4" points="22 4 20 4 20 11 22 4" />
        <rect fill="#669df6" x="2" y="4" width="2" height="7" />
        <rect fill="#fff" x="6" y="7" width="6" height="1" />
        <rect fill="#fff" x="15" y="6" width="3" height="3" rx="1.5" />
        <rect fill="#aecbfa" x="2" y="13" width="20" height="7" />
        <rect fill="#669df6" x="20" y="13" width="2" height="7" />
        <polygon fill="#4285f4" points="22 13 20 13 20 20 22 13" />
        <rect fill="#669df6" x="2" y="13" width="2" height="7" />
        <rect fill="#fff" x="6" y="16" width="6" height="1" />
        <rect fill="#fff" x="15" y="15" width="3" height="3" rx="1.5" />
      </g>
    </svg>
  );
}

export default GCP_STORAGE;
