import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortParamSortOrderEnum } from 'src/generated';

export interface VendorHealthStatus {
  [vendor: string]: { healthy: number; unhealthy: number };
}

export interface CloudAccountDetails {
  name: string;
  rowId: string;
  vendor: string;
  tenantId: string;
  errors: number;
  datasource: string;
  aiAssetsFound?: boolean;
}

interface DetailsTableState {
  activeRowKey: string;
  sortState: {
    sort_by: string;
    sort_order: SortParamSortOrderEnum | 'UNKNOWN';
  };
  pagination: { offset: number; limit: number };
  selection: string[];
  visibleColumnIds: string[];
  showHeader?: boolean;
  addTag?: boolean;
  canExport?: boolean;
  cloudAccountDetails?: CloudAccountDetails;
  activeColumn?: { entityId: number; id: string };
  showSidePanel?: boolean;
}

const initialState: DetailsTableState = {
  activeRowKey: '',
  sortState: { sort_by: '', sort_order: 'UNKNOWN' },
  pagination: { offset: 0, limit: 20 },
  selection: [],
  visibleColumnIds: [],
  addTag: true,
  canExport: true,
  showHeader: true,
  cloudAccountDetails: undefined,
  activeColumn: undefined,
  showSidePanel: true,
};

const detailsTableSlice = createSlice({
  name: 'detailsTableState',
  initialState,
  reducers: {
    setSortState: (
      state,
      action: PayloadAction<{
        sort_by: string;
        sort_order: SortParamSortOrderEnum;
      }>
    ) => {
      state.sortState = action.payload;
    },
    setPagination: (
      state,
      action: PayloadAction<{ offset: number; limit: number }>
    ) => {
      state.pagination = action.payload;
    },
    setSelection: (state, action: PayloadAction<string[]>) => {
      state.selection = action.payload;
    },
    setVisibleColumnIds: (state, action: PayloadAction<string[]>) => {
      state.visibleColumnIds = action.payload;
    },
    resetTableState: (state) => {
      state.sortState = { sort_by: '', sort_order: 'UNKNOWN' };
      state.pagination = { offset: 0, limit: 20 };
      state.selection = [];
      state.visibleColumnIds = [];
      state.showHeader = true;
      state.addTag = true;
      state.cloudAccountDetails = undefined;
      state.activeColumn = undefined;
      state.activeRowKey = '';
      state.showSidePanel = true;
    },
    setShowHeader: (state, action: PayloadAction<boolean>) => {
      state.showHeader = action.payload;
    },
    setAddTag: (state, action: PayloadAction<boolean>) => {
      state.addTag = action.payload;
    },
    setCloudAccountDetails: (
      state,
      action: PayloadAction<CloudAccountDetails | undefined>
    ) => {
      state.cloudAccountDetails = action.payload;
    },
    setActiveRowKey: (state, action: PayloadAction<string>) => {
      state.activeRowKey = action.payload;
    },
    setActiveColumn: (
      state,
      action: PayloadAction<{ entityId: number; id: string } | undefined>
    ) => {
      state.activeColumn = action.payload;
    },
    setCanExport: (state, action: PayloadAction<boolean>) => {
      state.canExport = action.payload;
    },
    setShowSidePanel: (state, action: PayloadAction<boolean>) => {
      state.showSidePanel = action.payload;
    },
    resetForCloudAccount: (state) => {
      state.cloudAccountDetails = undefined;
      state.activeColumn = undefined;
      state.activeRowKey = '';
      state.showSidePanel = false;
      state.addTag = false;
      state.canExport = false;
      state.showHeader = false;
    },
  },
});

export const {
  setSortState,
  setPagination,
  setSelection,
  setVisibleColumnIds,
  resetTableState,
  setShowHeader,
  setAddTag,
  setCloudAccountDetails,
  setActiveRowKey,
  setActiveColumn,
  setCanExport,
  setShowSidePanel,
  resetForCloudAccount,
} = detailsTableSlice.actions;
export default detailsTableSlice.reducer;
