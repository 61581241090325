import InHouseApps from './in-house-app/in-house-apps';
import ExecHeader from './exec-header';
import { InsightsList } from 'src/components/InsightsList';
import TopExternalAiServices from './external-services/top-external-ai-services';
import GovernanceTrend from './governance-chart/governanceTrend';
import ExternalServicesPosture from './posture/external-services-posture';
import InHousePosture from './posture/in-house-posture';
import EmbeddedAiPosture from './posture/embedded-ai-posture';
import ProtectionContainer from './protection-extension/protection-container';
import EnvContainer from './environment/env-container';
import RecentChanges from './environment/recent-changes';
import ErrorBoundary from 'src/utils/errorBoundary';

function ExecDashboardContainer() {
  return (
    <div className="p-6 bg-background w-full h-full overflow-auto">
      <ErrorBoundary heightClass="h-20" noImage>
        <ExecHeader />
      </ErrorBoundary>

      <div className="grid grid-cols-[3fr_minmax(400px,1fr)] gap-4">
        <div className="flex flex-col gap-4">
          <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 items-start flex-wrap">
            <ErrorBoundary heightClass="h-20" noImage>
              <InHousePosture />
            </ErrorBoundary>
            <ErrorBoundary heightClass="h-20" noImage>
              <ExternalServicesPosture />
            </ErrorBoundary>
            <ErrorBoundary heightClass="h-20" noImage>
              <EmbeddedAiPosture />
            </ErrorBoundary>
          </div>
          <div className="grid grid-cols-[minmax(550px,1fr)] xl:grid-cols-[minmax(550px,1fr)_minmax(550px,1fr)] gap-4">
            <ErrorBoundary heightClass="h-60">
              <InHouseApps />
            </ErrorBoundary>
            <ErrorBoundary heightClass="h-60">
              <TopExternalAiServices />
            </ErrorBoundary>
          </div>
          <ErrorBoundary heightClass="h-60">
            <GovernanceTrend />
          </ErrorBoundary>
          <ErrorBoundary heightClass="h-96">
            <EnvContainer />
          </ErrorBoundary>
        </div>
        <div className="flex flex-col gap-4">
          <ErrorBoundary heightClass="h-60">
            <ProtectionContainer />
          </ErrorBoundary>
          <ErrorBoundary heightClass="h-60">
            <InsightsList
              height={600}
              title="Insights"
              titleClass="text-base"
            />
          </ErrorBoundary>
          <ErrorBoundary heightClass="h-60">
            <RecentChanges />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default ExecDashboardContainer;
