/* eslint-disable react/style-prop-object */
import { IconProps } from '..';

function ATLASSIAN(props: IconProps) {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 256 256"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <defs>
        <linearGradient
          x1="99.6865531%"
          y1="15.8007988%"
          x2="39.8359011%"
          y2="97.4378355%"
          id="linearGradient-1"
        >
          <stop stopColor="#0052CC" offset="0%" />
          <stop stopColor="#2684FF" offset="92.3%" />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M75.7929022,117.949352 C71.973435,113.86918 66.0220743,114.100451 63.4262382,119.292123 L0.791180865,244.565041 C-0.370000214,246.886207 -0.24632242,249.643151 1.11803323,251.85102 C2.48238888,254.058889 4.89280393,255.402741 7.48821365,255.402516 L94.716435,255.402516 C97.5716401,255.468706 100.19751,253.845601 101.414869,251.262074 C120.223468,212.37359 108.82814,153.245434 75.7929022,117.949352 Z"
          fill="url(#linearGradient-1)"
        />
        <path
          d="M121.756071,4.0114918 C86.7234975,59.5164098 89.0348008,120.989508 112.109989,167.141287 L154.170383,251.262074 C155.438703,253.798733 158.031349,255.401095 160.867416,255.401115 L248.094235,255.401115 C250.689645,255.401339 253.10006,254.057487 254.464416,251.849618 C255.828771,249.64175 255.952449,246.884805 254.791268,244.563639 C254.791268,244.563639 137.44462,9.83670492 134.492768,3.96383607 C131.853481,-1.29371311 125.14944,-1.36519672 121.756071,4.0114918 Z"
          fill="#2681FF"
        />
      </g>
    </svg>
  );
}

export default ATLASSIAN;
