import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React from 'react';

import clsx from 'clsx';
import { ArrowDownIcon } from 'lucide-react';
import { SortParamSortOrderEnum } from 'src/generated';
import SomethingWentWrong from 'src/utils/something-went-wrong';
import SuiLoader from 'src/utils/sui-loader';
import TableFilter from './filter-component';
import {
  EmptyTableState,
  getCommonPinningStyles,
  getResizeHandlerBorderClass,
  HEADER_GROUP_CLASS,
  ISuiTableProps,
  LineLoader,
  RESIZE_CLASS,
  SORT_ICON_CLASS,
  TABLE_BODY_CLASS,
  TABLE_CELL_CLASS,
  TABLE_CLASS,
  TABLE_CONTAINER_CLASS,
  TABLE_HEADER_CELL_CLASS,
  TABLE_HEADER_CLASS,
  TABLE_ROW_CLASS,
} from './utils';

function SuiFilterTable<TEntityType>(props: ISuiTableProps<TEntityType>) {
  const {
    columns,
    height = 600,
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    singleSortOrder,
    setSingleSortOrder,
    onFilterApply,
    onFilterClear,
    selectedFilters,
    isFilterLoading,
    isFilterError,
    filterList,
    setSelectedFilterName,
    pinnedColumns = [],
  } = props;
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 1800 &&
          !isFetching &&
          hasNextPage
        ) {
          fetchNextPage?.();
        }
      }
    },
    [fetchNextPage, isFetching, hasNextPage]
  );

  // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data: data ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualFiltering: true,
    columnResizeMode: 'onChange',
    state: {
      columnPinning: {
        left: pinnedColumns,
      },
    },
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  if (isLoading) {
    return <SuiLoader />;
  }

  if (isError) {
    return <SomethingWentWrong height={height}></SomethingWentWrong>;
  }

  return (
    <div className="">
      <div
        className={TABLE_CONTAINER_CLASS}
        onScroll={(e) => fetchMoreOnBottomReached(e.currentTarget)}
        ref={tableContainerRef}
        style={{
          height, //should be a fixed height
        }}
      >
        <table className={TABLE_CLASS} style={{ display: 'grid' }}>
          <thead className={TABLE_HEADER_CLASS}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border-b border-border flex w-full"
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      style={{
                        width: header.getSize(),
                        ...getCommonPinningStyles(header.column),
                      }}
                      className={TABLE_HEADER_CELL_CLASS}
                    >
                      <div className={HEADER_GROUP_CLASS}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <div className="flex gap-2 items-center">
                          {header.column.getCanSort() ? (
                            <ArrowDownIcon
                              onClick={() => {
                                setSingleSortOrder?.(
                                  singleSortOrder === SortParamSortOrderEnum.Asc
                                    ? SortParamSortOrderEnum.Desc
                                    : SortParamSortOrderEnum.Asc
                                );
                              }}
                              className={clsx(
                                SORT_ICON_CLASS,
                                singleSortOrder === SortParamSortOrderEnum.Asc
                                  ? 'rotate-180 '
                                  : 'rotate-0'
                              )}
                            />
                          ) : null}
                          {header.column.getCanFilter() ? (
                            <TableFilter
                              facetId={
                                (
                                  header.column.columnDef.meta as {
                                    facetId: string;
                                  }
                                )?.facetId || ''
                              }
                              columnId={header.column.id}
                              onApply={onFilterApply}
                              onClear={onFilterClear}
                              selectedFilters={selectedFilters}
                              isLoading={isFilterLoading}
                              isError={isFilterError}
                              filterList={filterList}
                              setSelectedFilterName={setSelectedFilterName}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: RESIZE_CLASS,
                        }}
                      >
                        <div
                          className={clsx(
                            'border-l-[1.5px]  h-4 rounded-10',
                            getResizeHandlerBorderClass(
                              header.column.getIsResizing()
                            )
                          )}
                        ></div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {rowVirtualizer.getVirtualItems()?.length === 0 ? (
            <EmptyTableState />
          ) : (
            <tbody
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              }}
              className={TABLE_BODY_CLASS}
            >
              {rowVirtualizer?.getVirtualItems()?.map((virtualRow) => {
                const row = rows?.[virtualRow?.index];
                return (
                  <tr
                    data-index={virtualRow?.index} //needed for dynamic row height measurement
                    ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                    key={row.id}
                    className={TABLE_ROW_CLASS}
                    style={{
                      transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className={TABLE_CELL_CLASS}
                          style={{
                            width: cell.column.getSize(),
                            ...getCommonPinningStyles(cell.column),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <LineLoader isLoading={!isLoading && isFetching} />
    </div>
  );
}

export default SuiFilterTable;
