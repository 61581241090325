/* eslint-disable react/style-prop-object */
import { IconProps } from '..';

function MICROSOFT_DEFENDER_FOR_ENDPOINT(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <rect width="24" height="24" stroke="none" fill="#000000" opacity="0" />

      <g transform="matrix(0.42 0 0 0.42 12 12)">
        <g>
          <g transform="matrix(1 0 0 1 -0.5 19)">
            <path
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(3,112,200)"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-23.5, -43)"
              d="M 24 44 C 23.448 44 23 43.552 23 43 C 23 42.448 23.448 42 24 42 L 24 44 z"
              strokeLinecap="round"
            />
          </g>
          <g transform="matrix(1 0 0 1 0.5 19)">
            <path
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(15,80,148)"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-24.5, -43)"
              d="M 25 43 C 25 43.552 24.552 44 24 44 L 24 42 C 24.552 42 25 42.448 25 43 z"
              strokeLinecap="round"
            />
          </g>
          <g transform="matrix(1 0 0 1 18 -13)">
            <circle
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(8,131,217)"
              fillRule="nonzero"
              opacity="1"
              cx="0"
              cy="0"
              r="1"
            />
          </g>
          <g transform="matrix(1 0 0 1 -18 -13)">
            <circle
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(51,191,240)"
              fillRule="nonzero"
              opacity="1"
              cx="0"
              cy="0"
              r="1"
            />
          </g>
          <g transform="matrix(1 0 0 1 9.25 9.95)">
            <path
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(15,80,148)"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-33.25, -33.95)"
              d="M 24 43 L 24.427 43.907 C 24.427 43.907 39.571 36.007 42.507 23.999999999999996 L 24 23.999999999999996 L 24 43 z"
              strokeLinecap="round"
            />
          </g>
          <g transform="matrix(1 0 0 1 9.5 -10)">
            <path
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(8,131,217)"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-33.5, -14)"
              d="M 43 11 L 42 10 C 30.878 10 30.722 4 24 4 L 24 24 L 42.507000000000005 24 C 42.822 22.712 43 21.378 43 20 C 43 16.856 43 11 43 11 z"
              strokeLinecap="round"
            />
          </g>
          <g transform="matrix(1 0 0 1 -9.25 9.95)">
            <path
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(3,112,200)"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-14.75, -33.95)"
              d="M 24 43 L 23.573 43.907 C 23.573 43.907 8.429 36.007 5.493000000000002 23.999999999999996 L 24 23.999999999999996 L 24 43 z"
              strokeLinecap="round"
            />
          </g>
          <g transform="matrix(1 0 0 1 -9.5 -10)">
            <path
              stroke="none"
              strokeWidth="1"
              strokeDasharray="none"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="rgb(51,191,240)"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-14.5, -14)"
              d="M 5 11 L 6 10 C 17.122 10 17.278 4 24 4 L 24 24 L 5.493 24 C 5.178 22.712 5 21.378 5 20 C 5 16.856 5 11 5 11 z"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MICROSOFT_DEFENDER_FOR_ENDPOINT;
