import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/shadcn/card';
import EllipsisContent from '@/components/singulr/EllipsisContent';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useQueries } from '@tanstack/react-query';
import { getUnixTime, subDays } from 'date-fns';
import { Box } from 'lucide-react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { allApi } from 'src/api';

import { assetsVendorServicesIconMap } from 'src/constants/Assets';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { RecentChangeResponse } from 'src/generated/types';
import { DURATION_OPTIONS, TDurationKeys } from 'src/pages/aiService/shared';
import { getCompactNumber } from 'src/utils/common';
import { CountLink } from 'src/utils/common-components';
import SomethingWentWrong from 'src/utils/something-went-wrong';
import SuiLoader from 'src/utils/sui-loader';
import { PercentageChange } from '../utils';
import TooltipWrapper from 'src/components/tooltipWrapper';

function getVendorSuffix(vendor: string) {
  switch (vendor?.toLowerCase()) {
    case 'aws':
      return 'Accounts';
    case 'azure':
      return 'Subscriptions';
    case 'gcp':
    case 'google cloud':
      return 'Projects';

    default:
      return '';
  }
}

export const entityNumList = [79, 34, 27, 39, 50, 80];
interface IRecentChangeResponse extends RecentChangeResponse {
  changeNumber?: number;
  changeData?: IRecentChangeResponse;
}

const mapState = (state: ReduxRootState) => {
  const { displayNameMap, queryMap } = entityNumList.reduce(
    (acc, num) => {
      const entity = state.appCommon.entityList?.find(
        (item) => item?.entityType === num
      );
      if (entity) {
        acc.displayNameMap[num] = entity?.display_name || '';
        acc.queryMap[num] = entity?.query_id || '';
      }
      return acc;
    },
    {
      queryMap: {},
      displayNameMap: {},
    } as {
      queryMap: Record<number, string>;
      displayNameMap: Record<number, string>;
    }
  );
  return {
    duration: state.execDashboard.duration,
    entityList: state.appCommon.entityList,
    entityDisplayNameMap: displayNameMap,
    entityQueryMap: queryMap,
    venderList: state.appCommon.enumLabelList
      ?.find((item) => item?.name === 'VendorName')
      ?.values?.reduce(
        (acc, item) => {
          acc[item?.name || ''] = item?.display_name || '';
          return acc;
        },
        {} as Record<string, string>
      ),
  };
};
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function EnvContainer(props: PropsFromRedux) {
  const {
    duration,
    entityList,
    venderList,
    entityDisplayNameMap,
    entityQueryMap,
  } = props;

  const startTime =
    getUnixTime(
      subDays(new Date(), DURATION_OPTIONS[duration as TDurationKeys] || 30)
    ) * 1000;

  const results = useQueries({
    queries: [
      {
        queryKey: ['environment-data', duration],
        enabled: entityList.length > 0,
        queryFn: async () =>
          allApi
            .getRecentChanges({
              startTime: 0,
              endTime: Date.now(),
            })
            .then((res) => res?.data),
      },
      {
        queryKey: ['environment-data-change', duration],
        enabled: entityList.length > 0,
        queryFn: async () =>
          allApi
            .getRecentChanges({
              startTime: 0,
              endTime: startTime,
            })
            .then((res) => res?.data),
      },
    ],
    combine: (results) => {
      const [protectionData, changeList] = results;
      const details = protectionData?.data?.reduce(
        (acc, item) => {
          const vendorId = item.filters?.find(
            (filter) => filter.column === 'vendorName'
          )?.values?.[0];
          const dataEntityType = item?.entity_type;
          const changeData = changeList?.data?.find(
            (change) =>
              change?.entity_type === dataEntityType &&
              change?.filters?.find((filter) => filter?.column === 'vendorName')
                ?.values?.[0] === vendorId
          );
          const entityType = item?.entity_type || 0;

          if (vendorId && !acc[vendorId]) {
            acc[vendorId] = {};
          }
          if (vendorId) {
            acc[vendorId] = {
              ...acc[vendorId],
              [entityType]: {
                ...item,
                changeData,
                changeNumber: changeData?.change_count,
              },
            };
          }

          return acc;
        },
        {} as Record<string, Record<number, IRecentChangeResponse>>
      );

      return {
        groupedByVendor: Object.entries(details || {})?.map(([key, value]) => ({
          vendorName: venderList?.[key],
          venderId: key,
          accountNumber: value?.[125]?.change_count,
          data: value,
        })),
        isLoading: results?.some((result) => result.isPending),
        isError: protectionData?.isError || changeList?.isError,
      };
    },
  });

  const healthDetails = useQueries({
    queries: results?.groupedByVendor?.map((details) => {
      return {
        queryKey: ['vendor-health-details', details?.venderId],
        enabled: !!details?.venderId,
        queryFn: async () =>
          allApi
            .getEntityFacets({
              entityType: 125,
              facetProperty: 'accountStatus',
              includeCount: true,
              queryId: 'cloud_account_table',
              getFacetDataParam: {
                filters: [
                  {
                    column: 'vendorName',
                    values: [details?.venderId],
                  },
                ],
              },
            })
            .then((res) => {
              const data = res?.data?.reduce(
                (acc, cur) => {
                  const value = cur?.value?.toLowerCase() || '--';
                  const count = cur?.count ?? 0;
                  if (['healthy', 'unhealthy'].includes(value)) {
                    acc[value] = count;
                  }
                  return acc;
                },
                {} as Record<string, number>
              );

              return { [details?.venderId]: data };
            }),
      };
    }),
    combine: (results) => {
      return {
        data: results?.reduce(
          (acc, item) => {
            Object.entries(item?.data || {})?.forEach(([key, value]) => {
              acc[key] = value;
            });
            return acc;
          },
          {} as Record<string, Record<string, number>>
        ),
        isLoading: results?.some((result) => result?.isPending),
        isError: results?.some((result) => result?.isError),
      };
    },
  });

  if (results?.isLoading) {
    return (
      <Card className="text-base text-txt-primary">
        <CardHeader>
          <CardTitle className="text-base font-medium text-txt-primary">
            Environment
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-center py-8">
            <SuiLoader />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (results?.isError) {
    return (
      <Card className="text-base text-txt-primary">
        <CardHeader>
          <CardTitle className="text-base font-medium text-txt-primary">
            Environment
          </CardTitle>
        </CardHeader>
        <CardContent>
          <SomethingWentWrong />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="text-base text-txt-primary">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-base font-medium text-txt-primary">
          Environment
        </CardTitle>
        <NavLink
          to="/all-assets/cloud_account_table"
          className="text-blue-400 hover:text-blue-500 text-sm font-medium"
        >
          View All Assets
        </NavLink>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 xl:grid-cols-2 3xl:flex 3xl:flex-wrap gap-4">
          {results?.groupedByVendor?.length === 0 ? (
            <p className="text-txt-label text-sm">No data found</p>
          ) : null}
          {results?.groupedByVendor?.map((item) => {
            const Icon =
              assetsVendorServicesIconMap[item?.venderId || ''] ?? Box;
            return (
              <div
                key={item?.vendorName}
                className="flex flex-col gap-3 items-start justify-between rounded-md border p-4"
              >
                <div className="flex items-center gap-2">
                  <Icon className="size-5" />
                  <p className="text-sm text-txt-secondary">
                    {item?.vendorName}
                  </p>
                  {item?.accountNumber ? (
                    <>
                      <div className="h-4 rounded border-l border-slate-800" />
                      <CountLink
                        count={item?.accountNumber}
                        link={`/all-assets/cloud_account_table?filters=${JSON.stringify(
                          item?.data?.[125]?.filters
                        )}`}
                        fontSize="text-sm"
                        noPadding
                      />
                      <p className="text-sm text-txt-secondary">
                        {getVendorSuffix(item?.vendorName || '')}
                      </p>
                    </>
                  ) : null}
                  <div className="h-4 rounded border-l border-slate-800" />
                  <div className="flex items-center gap-3">
                    {healthDetails?.data?.[item?.venderId]?.healthy ? (
                      <TooltipWrapper title="Healthy">
                        <div className="flex items-center gap-2">
                          <div className="bg-lime-500 rounded-full w-3 h-1" />
                          <CountLink
                            count={
                              healthDetails?.data?.[item?.venderId]?.healthy ||
                              0
                            }
                            link={`/all-assets/cloud_account_table?filters=${JSON.stringify(
                              item?.data?.[125]?.filters?.concat({
                                column: 'accountStatus',
                                values: ['HEALTHY'],
                              })
                            )}`}
                          />
                        </div>
                      </TooltipWrapper>
                    ) : null}
                    {healthDetails?.data?.[item?.venderId]?.unhealthy ? (
                      <TooltipWrapper title="Unhealthy">
                        <div className="flex items-center gap-2">
                          <div className="bg-red-500 rounded-full w-3 h-1" />
                          <CountLink
                            count={
                              healthDetails?.data?.[item?.venderId]
                                ?.unhealthy || 0
                            }
                            link={`/all-assets/cloud_account_table?filters=${JSON.stringify(
                              item?.data?.[125]?.filters?.concat({
                                column: 'accountStatus',
                                values: ['UNHEALTHY'],
                              })
                            )}`}
                          />
                        </div>
                      </TooltipWrapper>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  {entityNumList.map((num) => {
                    const element = item?.data?.[num];

                    return (
                      <div
                        key={element?.entity_type}
                        className="flex items-center"
                      >
                        <div className="flex flex-col gap-2 px-3 py-4">
                          <EllipsisContent
                            title={entityDisplayNameMap?.[num || 0]}
                            maxWidth="20ch"
                            className="text-xs text-txt-label"
                          />
                          <div className="flex gap-2 items-center">
                            <CountLink
                              count={getCompactNumber(
                                element?.change_count || 0,
                                1
                              )}
                              link={`/all-assets/${entityQueryMap?.[num || 0]}?filters=${JSON.stringify(
                                element?.filters
                              )}`}
                              fontSize="text-lg"
                              fontWeight="font-semibold"
                            />
                            <PercentageChange
                              oldValue={element?.changeNumber}
                              currentValue={element?.change_count}
                              duration={duration}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
}

export default connector(EnvContainer);
