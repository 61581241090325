import { useQuery } from '@tanstack/react-query';
import { getUnixTime, subDays } from 'date-fns';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import allApi from 'src/api/allApi';

export enum EDurationRange {
  'last7Days' = 'Last 7 days',
  'last30Days' = 'Last 30 days',
  'last90Days' = 'Last 90 days',
  'last1Year' = 'Last 1 year',
}

export const DURATION_OPTIONS = {
  [EDurationRange.last7Days]: 7,
  [EDurationRange.last30Days]: 30,
  [EDurationRange.last90Days]: 90,
  [EDurationRange.last1Year]: 365,
};

export const DURATION_SHORT_LABELS = {
  [EDurationRange.last7Days]: '7 d',
  [EDurationRange.last30Days]: '30 d',
  [EDurationRange.last90Days]: '90 d',
  [EDurationRange.last1Year]: '1 y',
};

export const DURATION_RESOLUTION = {
  [EDurationRange.last7Days]: 86400 / 2,
  [EDurationRange.last30Days]: 86400, // every day
  [EDurationRange.last90Days]: 86400 * 3,
  [EDurationRange.last1Year]: 86400 * 11,
};

export type TDurationKeys = keyof typeof DURATION_OPTIONS;

export const DURATION_LABELS = Object.keys(DURATION_OPTIONS);

export function useSelectedDurationUnix() {
  const [searchParams] = useSearchParams();

  return (
    getUnixTime(
      subDays(
        new Date(),
        DURATION_OPTIONS[searchParams.get('duration') as TDurationKeys] || 7
      )
    ) * 1000
  );
}

export function getDurationUnixInMillSeconds(days: number) {
  return getUnixTime(subDays(new Date(), days)) * 1000;
}

export function getDurationUnixInSeconds(days: number) {
  return getUnixTime(subDays(new Date(), days));
}

export function useSelectedCategory() {
  const [searchParams] = useSearchParams();
  const activeCategory = searchParams.get('active-category');
  const significantActivityFilter =
    searchParams.get('significantActivityServices') === 'true';

  const startTime = useSelectedDurationUnix();

  const { data: categoriesResponse, isLoading: categoriesDataLoading } =
    useQuery({
      queryKey: [
        'entity-categories',
        significantActivityFilter,
        searchParams.get('duration'),
      ],
      queryFn: () =>
        allApi.getEntityFacets({
          entityType: 49,
          includeCount: true,
          queryId: 'ai_services_table',
          facetProperty: 'categories',
          getFacetDataParam: {
            context: {
              start_time: startTime,
              end_time: Date.now(),
            },
            filters: significantActivityFilter
              ? [
                  {
                    values: ['true'],
                    column: 'isFocusedUsagePreComputed',
                  },
                ]
              : [],
          },
        }),
    });

  const categoryList =
    categoriesResponse?.data?.filter((cat) => cat?.value) || [];

  const selectedCategories = useMemo(() => {
    if (categoriesResponse) {
      if (activeCategory === 'All') {
        return categoryList?.map((cat) => cat?.value || '') ?? [];
      } else {
        return [activeCategory || ''];
      }
    }
    return [];
  }, [categoriesResponse, activeCategory]);

  return {
    selectedCategories,
    categoriesDataLoading,
    categoryList,
    activeCategory,
  };
}

export function useSelectedAiServiceFilters() {
  const [searchParams] = useSearchParams();
  const isSignificantActivityEnabled =
    searchParams.get('significantActivityServices') === 'true';
  const { selectedCategories, activeCategory } = useSelectedCategory();
  const startTime = useSelectedDurationUnix();

  return {
    isSignificantActivityEnabled,
    selectedCategories,
    startTime,
    activeCategory,
  };
}

export function useAiServiceFacetParams({ column }: { column: string }) {
  const { isSignificantActivityEnabled, startTime, activeCategory } =
    useSelectedAiServiceFilters();
  const filters = [];

  if (activeCategory !== 'All') {
    filters.push({ values: [activeCategory as string], column });
  }
  if (isSignificantActivityEnabled) {
    filters.push({ column: 'isFocusedUsagePreComputed', values: ['true'] });
  }

  return {
    context: {
      start_time: startTime,
      end_time: Date.now(),
    },
    filters,
  };
}
export interface IAiServiceEntity {
  id: string;
  name: string;
  vendorName: string;
  aiFeatures: string[];
  privacySecurityUrls: string[];
  aiServiceRiskScore: number;
  headquarter: string;
  complianceStandard: string;
  dataRetention: number;
  allowDataUpload: boolean;
  dataUpload: string;
  allowThirdPartyPlugin: boolean;
  thirdPartyPlugin: string;
  serviceStatus: string;
  complianceStandards: string[];
  categories: string[];
  approvalStatus: string;
  aiServiceType: string[];
  serviceName: string;
  endpoint_departments: string[];
  endpoint_organizations: string[];
  region: string[];
  tag_app: string[];
  business_app: { id: string; name: string }[];
  mlModel: string[];
  accessModes: string[];
  available_extensions_definitions: Definition[];
  are_extensions_available: boolean;
  in_use_extensions_definitions: string[];
  installed_extensions: string[];
  domainName: string;
  is_extension_installed: boolean;
  ai_service_def: AIServiceDef;
  lifecycleStatus: string;
  availableEntityFields: string[];
  vectorIndex: string[];
  vms: string[];
  aiApiEndpoints: string[];
  aiServiceDefinitionDescription: string;
  aiServiceDefinitionDnsNames: string[];
  aiServiceDefinitionLabels: string[];
  countOfEndpoints: number;
  aiServiceEndpoints: AIServiceEndpoint[];
  riskScoreCategory: string;
  createTimeMillis: number;
  createTimeMillisTmpFix: number;
  countOfUsers: number;
  lastSeen: number;
  isFocusedUsagePreComputed: boolean;
  countActiveUsersPreComputed: number;
}

export interface AIServiceEndpoint {
  id: string;
  name: string;
}

export interface AIServiceDef {
  id: string;
  name: string;
  entityType: number;
  accessModes: string[];
  publisherDefinition: Definition;
  dataInputTypes: string[];
}

export interface Definition {
  id: string;
  name: string;
  entityType: number;
}

export interface ISimilarServiceEntity {
  id: string;
  name: string;
  entityType: number;
  labels: string[];
  description: string;
  inUse: boolean;
  serviceTypes: ServiceType[];
  dataRetention: number;
  available_extensions_definitions: string[];
  hostedRegions: string[];
  allowed3rdPartyPlugin: boolean;
  thirdPartyPlugin: DataUpload;
  headquarter: string;
  allowDataUpload: boolean;
  dataUpload: DataUpload;
  accessModes: string[];
  categories: string[];
  aiFeatures: string[];
  complianceStandards: string[];
  dnsNames: string[];
  secondaryDnsNames: string[];
  privacySecurityUrls: string[];
  dataInputTypes: string[];
  aiServiceRiskScore: number;
  aiServiceRiskCategory: string;
  complianceStandard: string;
  availableEntityFields: string[];
  lifecycleStatus: LifecycleStatus;
  isModelTrainingClauseAvailable: DisclosureOfSubProcessor[];
  disclosureOfSubProcessors: DisclosureOfSubProcessor[];
  isOptOutAvailableForModelTraining: DisclosureOfSubProcessor[];
  isUserDataUsedForModelTraining: DisclosureOfSubProcessor[];
  subProcessorsUpdateNotification: DisclosureOfSubProcessor[];
  publisherDefinition: PublisherDefinition;
  mlModelDefinitions: string[];
  llmDefinition: null;
  isOnboarded: boolean;
  approvalStatus: string;
}

export enum AIServiceRiskCategory {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum DataUpload {
  False = 'False',
  True = 'True',
}

export enum DisclosureOfSubProcessor {
  AnyNo = 'ANY_NO',
  AnyUnclear = 'ANY_UNCLEAR',
  AnyYes = 'ANY_YES',
  UnKnown = 'UN_KNOWN',
}

export enum LifecycleStatus {
  Verified = 'VERIFIED',
}

export interface PublisherDefinition {
  id: string;
  name: string;
  type: Type;
  ownership: Ownership;
  headquarter: string;
  numEmployee: number;
  organizationAge: number;
  reputationScore: string;
  twitterFollowers: number;
}

export enum Ownership {
  UnknownOwnershipType = 'UNKNOWN_OWNERSHIP_TYPE',
}

export enum Type {
  UnknownPublisherType = 'UNKNOWN_PUBLISHER_TYPE',
}

export enum ServiceType {
  AIService = 'AI_SERVICE',
  Chat = 'CHAT',
  Llm = 'LLM',
}

export enum EServiceTabs {
  aiService = 'aiService',
  potential = 'potential',
}
