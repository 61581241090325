import { IconProps } from '..';

function GCP_INVENTORY(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(2.000000, 4.000000)" fill="#4285F4">
          <polygon
            id="Fill-1"
            points="12.114144 0 11.139744 0.9744 18.228504 8.04552 12.114144 14.15988 6.000624 8.04552 8.920464 5.124 7.955304 4.15968 4.069464 8.04552 12.114144 16.09104 20.160504 8.04552"
          />
          <polygon
            id="Fill-2"
            points="10.122672 9.813216 8.355312 8.045856 10.122672 6.277656 11.890032 8.045856"
          />
          <polygon
            id="Fill-3"
            points="8.045352 0 -0.000168 8.04552 8.045352 16.09104 9.008832 15.12672 1.930992 8.04552 8.045352 1.93116 14.158872 8.04552 11.239032 10.9662 12.205032 11.9322 16.090872 8.04552"
          />
        </g>
      </g>
    </svg>
  );
}

export default GCP_INVENTORY;
