import { IconProps } from '..';

function GCP_GKE(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <title>Icon_24px_K8Engine_Color</title>
      <g data-name="Product Icons">
        <g>
          <polygon
            fillRule="evenodd"
            fill="#4285f4"
            points="14.68 13.06 19.23 15.69 19.23 16.68 14.29 13.83 14.68 13.06"
          />
          <polygon
            fill="#669df6"
            fillRule="evenodd"
            points="9.98 13.65 4.77 16.66 4.45 15.86 9.53 12.92 9.98 13.65"
          />
          <rect fill="#aecbfa" x="11.55" y="3.29" width="0.86" height="5.78" />
          <path
            fillRule="evenodd"
            fill="#aecbfa"
            d="M3.25,7V17L12,22l8.74-5V7L12,2Zm15.63,8.89L12,19.78,5.12,15.89V8.11L12,4.22l6.87,3.89v7.78Z"
          />
          <polygon
            fillRule="evenodd"
            fill="#aecbfa"
            points="11.98 11.5 15.96 9.21 11.98 6.91 8.01 9.21 11.98 11.5"
          />
          <polygon
            fill="#669df6"
            fillRule="evenodd"
            points="11.52 12.3 7.66 10.01 7.66 14.6 11.52 16.89 11.52 12.3"
          />
          <polygon
            fillRule="evenodd"
            fill="#4285f4"
            points="12.48 12.3 12.48 16.89 16.34 14.6 16.34 10.01 12.48 12.3"
          />
        </g>
      </g>
    </svg>
  );
}

export default GCP_GKE;
