import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

export function CountLink({
  count,
  link,
  fontSize = 'text-sm',
  fontWeight = 'font-medium',
  noPadding = false,
  openInNewTab = false,
}: {
  count?: number | string;
  link: string;
  fontSize?: string;
  fontWeight?: string;
  noPadding?: boolean;
  openInNewTab?: boolean;
}) {
  if (!count || count === '0')
    return (
      <p
        className={clsx(fontSize, 'text-txt-secondary', !noPadding && 'px-0.5')}
      >
        {count ?? '--'}
      </p>
    );

  return (
    <NavLink
      to={link}
      target={openInNewTab ? '_blank' : undefined}
      className={clsx(
        fontSize,
        fontWeight,
        !noPadding && 'px-0.5',
        'text-blue-400 hover:text-blue-500'
      )}
    >
      {count}
    </NavLink>
  );
}

export function ShimmerLoading({
  height = 'h-5',
  width = 'w-10',
  className,
}: {
  height?: string;
  width?: string;
  className?: string;
}) {
  return (
    <p
      className={clsx(
        'bg-slate-800 animate-pulse rounded',
        height,
        width,
        className
      )}
    />
  );
}
