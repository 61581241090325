import {
  Column,
  ColumnDef,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import { Check } from 'lucide-react';
import React from 'react';
import { CSSProperties, HTMLProps } from 'react';
import { SortParamSortOrderEnum } from 'src/generated';

export function getCommonPinningStyles<T>(column: Column<T>): CSSProperties {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  //   const isFirstRightPinnedColumn =
  //     isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    borderRight: isLastLeftPinnedColumn ? '1px solid #131B29' : undefined,
  };
}

export const TABLE_CONTAINER_CLASS =
  'border rounded-lg overflow-auto relative bg-card';

export const TABLE_CLASS = 'border-collapse border-spacing-0 table-fixed';

export const TABLE_HEADER_CLASS = 'grid bg-card sticky top-0 z-[1]';

export const TABLE_HEADER_CELL_CLASS =
  'text-left flex items-center px-4 py-2 relative bg-card';

export const HEADER_GROUP_CLASS =
  'flex grow text-sm font-semibold text-txt-label items-center justify-between gap-2';

export const SORT_ICON_CLASS =
  'size-4 p-1 rounded-md box-content hover:bg-slate-800 transition-all cursor-pointer';

export const RESIZE_CLASS =
  'absolute top-0 right-0 h-full cursor-col-resize select-none touch-none z-[2] flex items-center justify-center p-2 hover:bg-slate-900';

export const getResizeHandlerBorderClass = (isResizing: boolean) =>
  isResizing ? 'border-primary' : 'border-slate-700';

export const EMPTY_TABLE_STATE_CLASS =
  'h-[400px] flex justify-center py-10 flex-col gap-2 items-center';

export function EmptyTableState() {
  return (
    <tbody className={EMPTY_TABLE_STATE_CLASS}>
      <img src="/illustrations/no-results.svg" className="w-24" />
      <span className=" text-muted-foreground">Data not available</span>
    </tbody>
  );
}

export const TABLE_BODY_CLASS = 'grid relative';

export const TABLE_ROW_CLASS =
  'border-b border-border flex absolute w-full items-center';

export const TABLE_CELL_CLASS =
  'flex px-4 py-3 text-sm text-txt-secondary break-all bg-card hyphens-manual';

export function LineLoader({ isLoading }: { isLoading?: boolean }) {
  if (!isLoading) return <div className="w-full h-1 bg-transparent" />;
  return <div className="loader-line" />;
}

export interface ISuiTableCommonProps<TEntityType> {
  data?: TEntityType[];
  columns: ColumnDef<TEntityType>[];
  height?: number;
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  pinnedColumns?: string[];
}

export interface ISuiTableProps<TEntityType>
  extends ISuiTableCommonProps<TEntityType> {
  isFetching?: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  sorting?: SortingState;
  singleSortOrder?: SortParamSortOrderEnum;
  setSingleSortOrder?: React.Dispatch<
    React.SetStateAction<SortParamSortOrderEnum>
  >;
  onFilterApply?: (selectedItems: string[], colName: string) => void;
  onFilterClear?: (colName: string) => void;
  selectedFilters?: Record<string, string[]>;
  isFilterLoading?: boolean;
  isFilterError?: boolean;
  filterList?: {
    value: string;
    label: string;
  }[];
  setSelectedFilterName?: React.Dispatch<
    React.SetStateAction<{ colName: string; facetId: string }>
  >;
}

export interface ISuiLocalTableProps<TEntityType>
  extends ISuiTableCommonProps<TEntityType> {
  setSelectedRows?: (rows: string[]) => void;
  rowSelectionId?: string;
  rowSelection?: RowSelectionState;
  setRowSelection?: (rows: RowSelectionState) => void;
}

export function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest?.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <div className="relative flex items-center justify-center">
      <input
        type="checkbox"
        ref={ref}
        className="checked:bg-primary size-4 border bg-page border-primary rounded-sm cursor-pointer appearance-none"
        {...rest}
      />
      {rest.checked ? (
        <Check
          className="absolute size-3 top-[3px] left-0.5 cursor-pointer text-white stroke-2"
          onClick={() => ref.current.click()}
        />
      ) : null}
    </div>
  );
}
