import clsx from 'clsx';
import { InfoIcon, AlertTriangle, AlertOctagon } from 'lucide-react';
import TooltipWrapper from 'src/components/tooltipWrapper';

export function getRiskIcon({
  risk,
  sizeClass = 'size-4',
}: {
  risk?: string;
  sizeClass?: string;
}) {
  switch (risk) {
    case 'LOW':
      return <InfoIcon className={clsx(sizeClass, 'text-amber-400')} />;
    case 'MEDIUM':
      return <AlertTriangle className={clsx(sizeClass, 'text-orange-400')} />;
    case 'HIGH':
      return <AlertOctagon className={clsx(sizeClass, 'text-red-500')} />;
    default:
      return null;
  }
}

export function renderRiskTooltip(risk?: string) {
  return (
    <TooltipWrapper
      contentClassName="capitalize"
      title={`${risk?.toLowerCase() ?? ''} Risk`}
    >
      <div>{getRiskIcon({ risk })}</div>
    </TooltipWrapper>
  );
}

export function renderRiskPill(risk?: string) {
  return (
    <div className="flex gap-1 items-center">
      {getRiskIcon({ risk })}
      <span className="text-xs capitalize text-foreground">
        {risk?.toLowerCase()} Risk
      </span>
      {risk === '--' ? <div>--</div> : null}
    </div>
  );
}
