import React, {
  InputHTMLAttributes,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { XIcon } from 'lucide-react';
import clsx from 'clsx';

interface NakedInputProps extends InputHTMLAttributes<HTMLInputElement> {
  Icon?: React.ReactNode;
  clearable?: boolean;
  onClear?: () => void;
  containerClassName?: string;
}

const NakedInput = forwardRef<HTMLInputElement, NakedInputProps>(
  (
    {
      className,
      type,
      Icon,
      clearable = true,
      onClear,
      value,
      containerClassName = '',
      ...props
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleClear = () => {
      if (onClear) onClear();
      inputRef.current?.focus();
    };

    return (
      <div
        className={clsx(
          'flex border border-[#1D283A] rounded-md focus-within:border-indigo-500 gap-1 items-center px-3 relative',
          containerClassName
        )}
      >
        {Icon && <div className="text-slate-500">{Icon}</div>}
        {Icon && <div className="w-0 h-5 border-r border-[#1D283A] mx-1" />}

        <input
          ref={inputRef}
          type={type}
          className={clsx(
            'flex h-10 grow py-2 placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-sm text-base bg-transparent w-full',
            className
          )}
          value={value}
          {...props}
        />

        {clearable && (
          <XIcon
            className={clsx(
              'w-4 absolute right-2 transition-all bg-inherit box-content p-1 animate-slideIn cursor-pointer text-slate-500 hover:text-slate-300',
              value ? 'opacity-100' : 'opacity-0 pointer-events-none'
            )}
            onClick={handleClear}
          />
        )}
      </div>
    );
  }
);

NakedInput.displayName = 'NakedInput';

export default NakedInput;
