import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useQuery } from '@tanstack/react-query';
import { Hexagon } from 'lucide-react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { allApi } from 'src/api';
import ProgressBar from 'src/components/progress-bar';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { FilterOperation } from 'src/generated/types';
import {
  DURATION_OPTIONS,
  getDurationUnixInMillSeconds,
  TDurationKeys,
} from 'src/pages/aiService/shared';
import { CountLink, ShimmerLoading } from 'src/utils/common-components';
import { PercentageChange } from '../utils';
const mapState = (state: ReduxRootState) => ({
  duration: state.execDashboard.duration,
});
const mapDispatch = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ExternalServicesPosture(props: PropsFromRedux) {
  const { duration } = props;
  const { data: totalCount, isLoading } = useQuery({
    queryKey: ['ai-services-count'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 49,
          queryId: 'ai_services_table',
          includeCount: true,
          facetProperty: 'entityType',
        })
        .then((resp) => resp?.data?.[0]?.count),
  });

  const { data: endpointCount, isLoading: isLoadingEndpointCount } = useQuery({
    queryKey: ['endpoint-count'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 49,
          queryId: 'ai_services_table',
          includeCount: true,
          facetProperty: 'entityType',
          getFacetDataParam: {
            filters: [
              {
                column: 'countOfUsers',
                values: ['0'],
                operation: FilterOperation.Gt,
              },
            ],
          },
        })
        .then((resp) => resp?.data?.[0]?.count),
  });

  const { data: lastQtrCount, isLoading: isLoadingLastQtrCount } = useQuery({
    queryKey: ['ai-posture', 'last-qtr-count', duration],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          entityType: 49,
          queryId: 'ai_services_table',
          includeCount: true,
          facetProperty: 'entityType',
          getFacetDataParam: {
            filters: [
              {
                column: 'createTimeMillis',
                values: [
                  getDurationUnixInMillSeconds(
                    DURATION_OPTIONS[duration as TDurationKeys]
                  ).toString(),
                ],
                operation: FilterOperation.Lt,
              },
            ],
          },
        })
        .then((resp) => resp?.data?.[0]?.count),
  });

  const { data, isLoading: isLoadingRisk } = useQuery({
    queryKey: ['facets', 'risk-ai-service-posture'],
    queryFn: async () =>
      allApi
        .getEntityFacets({
          facetProperty: 'riskScoreCategory',
          queryId: 'ai_services_table',
          includeCount: true,
          entityType: 49,
        })
        .then((res) => {
          const highRiskCount =
            res?.data?.find((facet) => facet?.value === 'HIGH')?.count ?? 0;
          const totalCount = res?.data?.reduce(
            (acc, facet) => acc + (facet?.count || 0),
            0
          );

          return {
            highRiskCount,
            percentage: (highRiskCount / totalCount) * 100,
          };
        }),
  });

  return (
    <div className="bg-card rounded-md border border-border p-5 h-full">
      <div className="flex gap-1 items-center mb-2">
        <Hexagon className="size-4 text-txt-label" />
        <p className="text-txt-label text-sm">External AI Services Accessed</p>
      </div>

      <div className="flex items-center gap-4 mb-1">
        <div className="flex flex-col gap-2">
          {isLoading ? (
            <p className="h-7 bg-slate-800 animate-pulse w-10 rounded mb-2" />
          ) : (
            <CountLink
              count={totalCount}
              link="/dashboard/aiServices?duration=Last+1+year"
              fontSize="text-3xl"
              fontWeight="font-semibold"
              noPadding
            />
          )}
        </div>
        {isLoadingLastQtrCount ? (
          <p className="h-5 bg-slate-800 animate-pulse w-20 rounded" />
        ) : (
          <PercentageChange
            oldValue={lastQtrCount}
            currentValue={totalCount}
            duration={duration}
            isDurationDescriptionEnabled
            tooltipPosition="right"
          />
        )}
      </div>
      <div className="py-1.5 mb-3">
        <div className="flex items-center gap-1">
          {isLoadingEndpointCount ? (
            <ShimmerLoading height="h-5" width="w-10" />
          ) : (
            <CountLink
              count={endpointCount}
              link={`/dashboard/aiServices?duration=Last+1+year&aiServicesFilters=${encodeURIComponent(
                JSON.stringify([
                  {
                    column: 'countOfUsers',
                    values: ['0'],
                    operation: FilterOperation.Gt,
                  },
                ])
              )}`}
              fontSize="text-xs"
              fontWeight="font-semibold"
              noPadding
            />
          )}
          <p className="text-xs text-txt-secondary">From Endpoints</p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {isLoadingRisk ? (
          <ShimmerLoading height="h-4" width="w-44" className="mt-0.5" />
        ) : (
          <div className="flex items-center gap-1">
            <ProgressBar
              percentage={data?.percentage}
              progressColor="border-red-500"
            />
            <CountLink
              count={data?.highRiskCount}
              link={`/dashboard/aiServices?duration=Last+1+year&aiServicesFilters=${encodeURIComponent(
                JSON.stringify([
                  { column: 'riskScoreCategory', values: ['HIGH'] },
                ])
              )}`}
              fontSize="text-xs"
              fontWeight="font-semibold"
            />

            <p className="text-xs font-medium text-txt-secondary">
              {Math.round(data?.percentage ?? 0)}% High Risk Services
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default connector(ExternalServicesPosture);
