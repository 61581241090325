import React, { Component, ErrorInfo } from 'react';
import SomethingWentWrong from './something-went-wrong';

interface Props {
  children: React.ReactNode;
  heightClass?: string;
  noImage?: boolean;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <SomethingWentWrong
          heightClass={this.props.heightClass || 'h-[60vh]'}
          imgSize="large"
          noImage={this.props.noImage}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
