import clsx from 'clsx';

function SuiLoader({
  widthClass = 'w-full',
  heightClass = 'h-96',
  className = '',
}: {
  widthClass?: string;
  heightClass?: string;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        'grid place-items-center',
        widthClass,
        heightClass,
        className
      )}
    >
      <div className="sui-loader" />
    </div>
  );
}

export default SuiLoader;
