import { IconProps } from '..';

function CODE42_API(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 198 216"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#FF5000"
        d="M99.04,178.16c13.45,0,26.46,1.87,38.81,5.36c-8.6,4.98-38.87,22.49-38.87,22.49s-28.05-16.19-38.87-22.46 C72.49,180.04,85.55,178.16,99.04,178.16z M52.53,38.81c0.21-0.78,0.42-1.56,0.62-2.35C44.74,41.31,14.13,59,14.13,59s0,35.23,0,45 c9.27-8.98,17.44-19.37,24.2-31.08C44.66,61.95,49.37,50.49,52.53,38.81z M183.86,140.74c0.01,6.56,0.01,16.26,0.01,16.26 s-9.31,5.39-14.05,8.12c-21.57-9.81-45.54-15.28-70.78-15.28c-25.25,0-49.23,5.47-70.81,15.29c-5.19-2.99-14.11-8.13-14.11-8.13 s0-8.8,0-16.19c16.75-11.95,31.57-27.1,43.45-45.18c1.83-2.78,3.59-5.64,5.28-8.56C75.49,65.2,82.74,41.68,85.02,18.07 c4.52-2.63,13.95-8.08,13.95-8.08s9.74,5.63,14.17,8.2c2.29,23.58,9.54,47.07,22.16,68.93 C147.92,108.96,164.61,126.97,183.86,140.74z M125.56,123.32c-5.37-6.96-10.32-14.31-14.77-22.03c-0.05-0.09-0.11-0.19-0.16-0.28 c-4.42-7.68-8.26-15.53-11.55-23.51c-3.33,8.09-7.24,16.02-11.7,23.74c-4.47,7.74-9.42,15.11-14.81,22.08 c8.64-1.19,17.49-1.79,26.48-1.79C108.04,121.53,116.9,122.13,125.56,123.32z M145.05,36.58c0.46,1.81,0.95,3.61,1.48,5.4 c3.13,10.6,7.55,20.99,13.31,30.97c6.72,11.64,14.83,21.97,24.03,30.92c0-12.84,0-44.88,0-44.88S153.25,41.31,145.05,36.58z"
      />
    </svg>
  );
}

export default CODE42_API;
