import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/shadcn/select';
import { useUser } from '@descope/react-sdk';
import type { Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';

import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { DURATION_LABELS } from '../aiService/shared';
import { setExecDuration } from './exec-dashboard-slice';
import { ReduxRootState } from 'src/contexts/redux/reduxTypes';
import { CalendarClock } from 'lucide-react';

const mapState = (state: ReduxRootState) => ({
  duration: state.execDashboard.duration,
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators({ setExecDuration }, dispatch);
const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

function ExecHeader(props: PropsFromRedux) {
  const { setExecDuration, duration } = props;

  const { user } = useUser();

  return (
    <div className="mb-6 flex items-center justify-between flex-wrap gap-4">
      <h3 className="text-2xl font-semibold text-txt-heading-page">
        Welcome {user?.name}
      </h3>
      <Select
        onValueChange={(key) => {
          setExecDuration(key);
        }}
        value={duration}
      >
        <SelectTrigger className="w-auto bg-page border gap-2">
          <CalendarClock className="size-4 text-txt-label " />
          <p className="text-xs text-txt-label font-medium">Time Period:</p>
          <p className="text-xs text-txt-primary font-medium">{duration}</p>
        </SelectTrigger>
        <SelectContent defaultValue={DURATION_LABELS[0]} position="popper">
          {DURATION_LABELS?.map((duration) => (
            <SelectItem key={duration} value={duration ?? ''}>
              {duration}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

export default connector(ExecHeader);
