import clsx from 'clsx';

interface ProgressBarProps {
  percentage?: number;
  backgroundColor?: string;
  progressColor?: string;
  width?: string;
}

function ProgressBar({
  percentage = 0,
  backgroundColor = 'border-gray-800',
  progressColor = 'border-primary',
  width = 'w-14',
}: ProgressBarProps) {
  // Ensure percentage is between 0 and 100
  const safePercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <div className="relative">
      <div
        className={clsx(width, 'h-0 border-4 rounded', backgroundColor)}
      ></div>
      <div
        style={{
          width: `${safePercentage}%`,
        }}
        className={clsx(
          'absolute h-0 border-4 rounded inset-0',
          percentage > 0 && progressColor
        )}
      ></div>
    </div>
  );
}

export default ProgressBar;
