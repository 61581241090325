import { lazy, LazyExoticComponent } from 'react';
import ExecDashboardContainer from 'src/pages/executive-dashboard/exec-dashboard-container';
import VerifyLogin from '../pages/Auth/VerifyLogin';
import { Integrations } from '../pages/Integrations';

const SettingsHub = lazy(() => import('../pages/settings/settings-hub'));
const Onboarding = lazy(() => import('../pages/onboarding'));
const Users = lazy(() => import('../pages/Users/Users'));
const TrustFeed = lazy(() => import('../pages/TrustFeed/TrustFeed'));
const SaasService = lazy(
  () =>
    import('../pages/Dashboards/EntityDetails/ThirdartyAiService/saas-service')
);
const AllEvents = lazy(
  () => import('../pages/Discovery/all-events-table/all-events-container')
);
const AiRisks = lazy(
  () => import('../pages/Discovery/ai-risk/ai-risk-container')
);
const AllAssets = lazy(() => import('../pages/Discovery/Assets/AllAssets'));
const Extensions = lazy(
  () => import('../pages/extensions/extensions-container')
);
const Cost = lazy(() => import('../pages/Discovery/Cost/Cost'));
const Dataflows = lazy(() => import('../pages/Discovery/Dataflows/Dataflows'));
const Login = lazy(() => import('../pages/Auth/Login'));
const Policies = lazy(() => import('../pages/Policies/Policies'));
const Collectors = lazy(() => import('../pages/Collectors/Collectors'));
const PromptCompletion = lazy(
  () => import('../pages/PromptCompletion/PromptCompletion')
);
// const TrustDB = lazy(() => import('./pages/TrustDB/TrustDB'));

const DataSources = lazy(
  () => import('../pages/Datasources/datasource-container')
);
const NewManualDataSourceUpload = lazy(
  () =>
    import(
      '../pages/Datasources/manualUploads/newManualUpload/NewManualDataSourceUpload'
    )
);

const Debug = lazy(() => import('../pages/Debug/Debug'));
const Playground = lazy(() => import('../pages/Playground/Playground'));

const Welcome = lazy(() => import('../pages/Auth/Welcome'));
const Dashboard = lazy(() => import('../pages/Dashboards/Dashboard'));
const Summary = lazy(() => import('../pages/Summary/Summary'));
const RouteRedirect = lazy(() => import('../pages/RouteRedirect'));
const AiPosture = lazy(() => import('../pages/aiPosture/aiPosture'));

const AuditLogs = lazy(() => import('../pages/auditLogs/AuditLogs'));
const NewAiService = lazy(
  () =>
    import(
      'src/pages/aiService/ai-service-dashboard/ai-service-dashboard-container'
    )
);
interface SRoute {
  path: string;
  component:
    | ((props: unknown) => JSX.Element)
    | LazyExoticComponent<
        () =>
          | string
          | number
          | boolean
          | Iterable<React.ReactNode>
          | JSX.Element
          | null
          | undefined
      >;
  exact?: boolean;
}

export const privateRoutesConfig: SRoute[] = [
  // URLS redirections for new urls
  {
    path: '/discovery/:page?/:subpage?',
    component: RouteRedirect,
  },
  { path: '/manage/:page?/:subpage?', component: RouteRedirect },
  { path: '/content/prompt-completion', component: RouteRedirect },
  //
  { path: '/assets/:page?', component: AiPosture },
  { path: '/risks', component: AiRisks },
  { path: '/dataflows', component: Dataflows },
  { path: '/cost', component: Cost },
  { path: '/dataSources/:page?', component: DataSources },
  {
    path: '/datasources/csv-uploads/new',
    component: NewManualDataSourceUpload,
  },
  { path: '/policies', component: Policies },
  { path: '/collectors', component: Collectors },
  { path: '/integrations', component: Integrations },
  { path: '/extensions', component: Extensions },
  { path: '/all-assets/:page?', component: AllAssets },
  { path: '/trust-db', component: Dataflows },
  { path: '/content', component: PromptCompletion },
  { path: '/debug', component: Debug },
  { path: '/playground', component: Playground },
  { path: '/trust-feed', component: TrustFeed },
  { path: '/dashboard/:page', component: Dashboard },
  { path: '/summary/:page?', component: Summary },
  { path: '/saas-service/:page?', component: SaasService },
  { path: '/audit-logs/:page?', component: AuditLogs },
  { path: '/settings', component: SettingsHub },
  { path: '/onboarding', component: Onboarding },
  { path: '/ai-service', component: NewAiService },
  { path: '/users', component: Users },
  { path: '/executive-dashboard', component: ExecDashboardContainer },
  { path: '/all-events', component: AllEvents },
];

export const publicRoutesConfig: SRoute[] = [
  { path: '/login', component: Login },
  { path: '/verify-login', component: VerifyLogin },
  { path: '/welcome', component: Welcome },
];
