import { Button } from '@/components/shadcn/button';
import Checkbox from '@/components/shadcn/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shadcn/popover';
import NakedInput from '@/components/singulr/NakedInput';
import clsx from 'clsx';
import { FilterIcon, Search } from 'lucide-react';
import { memo, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import SomethingWentWrong from 'src/utils/something-went-wrong';
import SuiLoader from 'src/utils/sui-loader';
import { getNormalizedText } from '../../pages/aiService/ai-service-dashboard/selected-service/usage/activity/activity-utils';
import EllipsisContent from '@/components/singulr/EllipsisContent';
interface FacetListProps {
  filteredFacets: {
    value: string;
    label: string;
  }[];
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
}
function TableHeaderFilterItem({
  data: { filteredFacets, selectedItems, setSelectedItems },
  index,
  style,
}: {
  data: {
    filteredFacets: {
      value: string;
      label: string;
    }[];
    selectedItems: string[];
    setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  };
  index: number;
  style: React.CSSProperties;
}) {
  const item = filteredFacets?.[index] || {};
  const checked = selectedItems?.includes(item?.value);
  return (
    <div
      onClick={() => {
        setSelectedItems((prev = []) =>
          checked
            ? prev?.filter((facet) => facet !== item?.value)
            : [...prev, item?.value]
        );
      }}
      className="flex gap-4 items-center cursor-pointer"
      key={item?.value}
      style={style}
    >
      <Checkbox
        id={item?.value}
        checked={selectedItems?.includes(item?.value)}
        // onCheckedChange={(checked) => {
        //   if (checked) {
        //     setSelectedItems((prev = []) => [...prev, item?.value]);
        //   } else {
        //     setSelectedItems((prev = []) =>
        //       prev?.filter((facet) => facet !== item?.value)
        //     );
        //   }
        // }}
      />
      <EllipsisContent
        maxWidth="100%"
        title={getNormalizedText(item?.label) || ''}
        className="text-txt-secondary capitalize truncate"
      />
      {/* <label
        htmlFor={item?.value}
        className="text-txt-secondary capitalize  cursor-pointer truncate"
        title={item?.label}
      >
        {getNormalizedText(item?.label)}
      </label> */}
    </div>
  );
}

interface TableFilterProps {
  facetId: string;
  columnId: string;
  onApply?: (selectedItems: string[], colName: string) => void;
  onClear?: (colName: string) => void;
  selectedFilters?: Record<string, string[]>;
  isLoading?: boolean;
  isError?: boolean;
  filterList?: {
    value: string;
    label: string;
  }[];
  setSelectedFilterName?: React.Dispatch<
    React.SetStateAction<{ colName: string; facetId: string }>
  >;
}

function TableFilter(props: TableFilterProps) {
  const {
    facetId,
    onApply,
    onClear,
    selectedFilters,
    isLoading,
    isError,
    filterList = [],
    setSelectedFilterName,
    columnId,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    setSelectedItems(selectedFilters?.[columnId] || []);
  }, [selectedFilters, columnId]);

  const filteredFacets = filterList?.filter((facet) =>
    facet?.label?.toLowerCase()?.includes(search?.toLowerCase())
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedFilterName?.({ colName: columnId, facetId });
    }
  }, [isOpen, facetId, setSelectedFilterName]);

  const handleSelectAll = (checked: boolean) => {
    setIsSelectAll(checked);
    if (checked) {
      setSelectedItems(filteredFacets?.map((facet) => facet.value) || []);
    } else {
      setSelectedItems((prev) =>
        prev.filter(
          (val) => !filteredFacets?.some((facet) => facet.value === val)
        )
      );
    }
  };

  return (
    <Popover modal open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div className="p-2 hover:bg-slate-900 cursor-pointer rounded-sm flex items-center gap-1">
          {(selectedFilters?.[columnId]?.length || 0) > 0 ? (
            <span className="border border-primary  rounded-full size-4 flex items-center justify-center p-1.5 w-full">
              <p className="text-primary text-xs text-center font-normal">
                {selectedFilters?.[columnId]?.length}
              </p>
            </span>
          ) : null}
          <div className="flex">
            <FilterIcon
              className={clsx('grow size-3', isOpen && 'text-primary')}
            />
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        className="p-5 w-72"
        align="end"
      >
        {isLoading ? <SuiLoader heightClass="h-72" /> : null}
        {isError ? (
          <SomethingWentWrong
            heightClass="h-72"
            imgSize="small"
            hideReloadButton
          />
        ) : null}
        {!isError && !isLoading ? (
          <div className="flex flex-col gap-3 items-start">
            <NakedInput
              placeholder="Search"
              value={search}
              containerClassName="flex-1"
              Icon={<Search className="w-4 text-inherit" />}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onClear={() => {
                setSearch('');
              }}
            />
            <div className="flex gap-4 items-center">
              <Checkbox
                id="select-all"
                checked={isSelectAll}
                onCheckedChange={handleSelectAll}
              />
              <label
                htmlFor="select-all"
                className="text-txt-secondary capitalize cursor-pointer"
              >
                Select All
              </label>
            </div>
            {filterList?.length === 0 ? (
              <div className="w-full h-72 grid place-items-center">
                <p className="text-txt-secondary text-sm">No filters found</p>
              </div>
            ) : null}
            <div className="max-h-72 overflow-auto w-full flex flex-col gap-4">
              <FixedSizeList<FacetListProps>
                height={Math.min(200, (filteredFacets?.length || 0) * 40)}
                itemCount={filteredFacets?.length ?? 0}
                itemSize={40}
                width="100%"
                itemData={{ filteredFacets, selectedItems, setSelectedItems }}
                itemKey={(idx, data) =>
                  data?.filteredFacets?.[idx]?.value ??
                  data?.filteredFacets?.[idx]?.label ??
                  ''
                }
                overscanCount={3}
              >
                {TableHeaderFilterItem}
              </FixedSizeList>
            </div>
            <div className="flex justify-end w-full gap-4 items-center">
              <Button
                disabled={!selectedItems?.length}
                onClick={() => {
                  onClear?.(columnId);
                  setSelectedItems([]);
                  setIsSelectAll(false);
                }}
                variant="ghost"
              >
                Clear
              </Button>
              <Button
                onClick={() => {
                  onApply?.(selectedItems, columnId);
                  setIsOpen(false);
                }}
                variant="default"
                size="compact"
              >
                Apply
              </Button>
            </div>
          </div>
        ) : null}
      </PopoverContent>
    </Popover>
  );
}

export default memo(TableFilter);
