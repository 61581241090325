import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UseFiltersProps, UseFiltersResponse } from '../types/common';

import { DetailsTableFilterRequestItem } from '../types/table';

export const useToggle = (isActive = false): [boolean, () => void] => {
  const [state, setState] = useState<boolean>(isActive);
  const toggleState = () => setState(!state);
  return [state, toggleState];
};

export const useTableFiltersAndSorters = ({
  defaultSortingState,
  filterKey = 'filters',
}: UseFiltersProps): UseFiltersResponse => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFiltersFromUrl = useCallback(() => {
    const filters = searchParams.getAll(filterKey).toString();
    if (!filters) {
      return [];
    }
    const filtersFromUrl = JSON.parse(filters);
    return filtersFromUrl as DetailsTableFilterRequestItem[];
  }, [filterKey, searchParams]);

  const getSortingState = useCallback(() => {
    const sortBy =
      searchParams.getAll('sortBy').toString() ||
      defaultSortingState?.sort_by.toString();
    const sortOrder =
      searchParams.getAll('sortOrder').toString() ||
      defaultSortingState?.sort_order.toString();
    if (sortBy === '' && (sortOrder === '' || sortOrder === 'UNKNOWN')) {
      return undefined;
    }
    if (sortOrder === '' || sortOrder === 'UNKNOWN') {
      return undefined;
    }
    return {
      sort_by: sortBy,
      sort_order: sortOrder,
    } as {
      sort_by: string;
      sort_order: 'ASC' | 'DESC' | 'UNKNOWN';
    };
  }, [searchParams, defaultSortingState]);

  const setSortingState = useCallback(
    (key: string, order: 'ASC' | 'DESC' | '') => {
      const urlSearch = new URLSearchParams();
      const filters = searchParams.getAll(filterKey).toString();
      if (filters) {
        urlSearch.append(filterKey, filters);
      }
      if (order !== '') {
        urlSearch.append('sortBy', key);
        urlSearch.append('sortOrder', order);
      }

      setSearchParams(urlSearch);
    },
    [setSearchParams, filterKey, searchParams]
  );

  const setFiltersOnUrl = useCallback(
    (filters: DetailsTableFilterRequestItem[]) => {
      const urlSearch = new URLSearchParams(window.location.search);
      const sortBy = searchParams.getAll('sortBy').toString();
      const sortOrder = searchParams.getAll('sortOrder').toString();

      if (filters.length > 0) {
        urlSearch.set(filterKey, JSON.stringify(filters));
      } else if (filters.length === 0) {
        urlSearch.set(filterKey, JSON.stringify(filters));
      }
      if (sortBy && sortOrder) {
        urlSearch.set('sortBy', sortBy);
        urlSearch.set('sortOrder', sortOrder);
      }
      setSearchParams(urlSearch);
    },
    [setSearchParams, searchParams, filterKey]
  );

  const getUrlSearchWithFilters = useCallback(
    (
      filters: DetailsTableFilterRequestItem[],
      extraData: Record<string, string> | undefined = undefined
    ) => {
      const urlSearch = new URLSearchParams();
      urlSearch.set(filterKey, JSON.stringify(filters));
      if (extraData !== undefined) {
        Object.entries(extraData).forEach(([key, value]) => {
          urlSearch.append(key, value);
        });
      }
      return urlSearch.toString();
    },
    [filterKey]
  );
  return {
    searchParams,
    setSearchParams,
    getFiltersFromUrl,
    setFiltersOnUrl,
    getUrlSearchWithFilters,
    getSortingState,
    setSortingState,
  };
};

interface SelectionResponse {
  selection: string[];
  toggleInSelection: (id: string) => void;
  resetSelection: () => void;
  updateSelection: (ids: string[]) => void;
}
export const useSelection = (defaultSelection = []): SelectionResponse => {
  const [selection, setSelection] = useState<string[]>(defaultSelection);
  const toggleInSelection = useCallback(
    (id: string) => {
      setSelection((sel) =>
        sel.includes(id) ? sel.filter((sid) => sid !== id) : [...sel, id]
      );
    },
    [setSelection]
  );

  const resetSelection = useCallback(() => {
    setSelection([]);
  }, [setSelection]);

  const updateSelection = useCallback(
    (ids: string[]) => {
      setSelection(ids);
    },
    [setSelection]
  );
  return { selection, toggleInSelection, resetSelection, updateSelection };
};
