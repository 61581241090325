export function getApprovalStatusClass(status: string): string {
  switch (status.toLowerCase()) {
    case 'unvetted':
      return 'border border-blue-500';
    case 'unapproved':
      return 'bg-rose-400';
    case 'restricted':
      return 'bg-gray-300';
    case 'approved':
      return 'bg-teal-500';
    default:
      return 'bg-gray-200'; // fallback color
  }
}

const statuses = ['Unvetted', 'Unapproved', 'Restricted', 'Approved'];
function ApprovalStatusChartLegend() {
  return (
    <div className="flex items-center flex-wrap justify-center w-full gap-4 mt-6 text-txt-secondary text-[11px] font-medium">
      {statuses.map((status) => (
        <div key={status} className="flex gap-2 items-center">
          <div
            className={`w-4 h-1 rounded-md ${getApprovalStatusClass(status)}`}
          ></div>
          <p>{status}</p>
        </div>
      ))}
    </div>
  );
}

export default ApprovalStatusChartLegend;
