import { IconProps } from '..';

function GCP_FLOW_LOGS(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <style>
          {'.cls-1{fill:#4285f4}.cls-2{fill:#669df6}.cls-3{fill:#aecbfa}'}
        </style>
      </defs>
      <title>Icon_24px_CloudAuditLogs_Color</title>
      <g data-name="Product Icons">
        <g data-name="colored-32/cloud-audit-logs">
          <path
            id="Fill-3"
            d="M12.77 12.72a1.07 1.07 0 0 1-1.52 0l-1.49-1.49.76-.76L12 11.93l4.2-4.2.81.79Z"
            className="cls-1"
          />
          <path
            id="Fill-10"
            d="M16 11.59a.91.91 0 0 0-.81.81 3.21 3.21 0 0 1-2.86 2.76 3.25 3.25 0 0 1-1.84-.36 3.19 3.19 0 0 1 1.2-6 3.07 3.07 0 0 1 1.21.13.9.9 0 0 0 1-.28.91.91 0 0 0-.4-1.45A5 5 0 0 0 7 12.49 5 5 0 0 0 12.48 17 5 5 0 0 0 17 12.63a.91.91 0 0 0-1-1Z"
            className="cls-2"
          />
          <path
            id="Fill-16"
            d="M12 18a2 2 0 1 1-2 2 2 2 0 0 1 2-2"
            className="cls-3"
          />
          <path
            id="Fill-16-2"
            d="M12 2a2 2 0 1 1-2 2 2 2 0 0 1 2-2"
            className="cls-3"
            data-name="Fill-16"
          />
          <path
            id="Rectangle"
            d="M0 0h24v24H0z"
            style={{
              fill: 'none',
            }}
          />
        </g>
        <path
          d="M18 20a1 1 0 0 1-.71-1.7L20 15.59V8.41L17.31 5.7a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l3 3A1 1 0 0 1 22 8v8a1 1 0 0 1-.29.7l-3 3a1 1 0 0 1-.71.3Z"
          className="cls-1"
        />
        <path
          d="M6 20a1 1 0 0 1-.71-.3l-3-3A1 1 0 0 1 2 16V8a1 1 0 0 1 .29-.71l3-3a1 1 0 0 1 1.42 0 1 1 0 0 1 0 1.41L4 8.41v7.18l2.69 2.71a1 1 0 0 1 0 1.42A1 1 0 0 1 6 20Z"
          className="cls-2"
        />
      </g>
    </svg>
  );
}

export default GCP_FLOW_LOGS;
