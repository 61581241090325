import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TopologyEntityNodeData } from './shared';
import { IAllTopics } from '../security/wordCloud/useEntityPolicy';
import { RowSelectionState } from '@tanstack/react-table';

interface topologyState {
  selectedEntity: TopologyEntityNodeData | null | undefined;
  selectedSubGraphId: string | null | undefined;
  allTopics: IAllTopics[];
  selectedTopics: RowSelectionState;
}

const initialState: topologyState = {
  selectedEntity: null,
  selectedSubGraphId: null,
  allTopics: [],
  selectedTopics: {},
};

export const topologySlice = createSlice({
  name: 'topology-slice',
  initialState,
  reducers: {
    setSelectedEntity: (
      state,
      action: PayloadAction<TopologyEntityNodeData | null>
    ) => {
      if (action?.payload?.id === state.selectedEntity?.id) {
        state.selectedEntity = null;
      } else {
        state.selectedEntity = action?.payload;
      }
    },
    setSelectedSubGraphId: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      state.selectedSubGraphId = action.payload;
    },
    setAllTopics: (state, action: PayloadAction<IAllTopics[]>) => {
      state.allTopics = action.payload;
    },
    setTopicStatus: (
      state,
      action: PayloadAction<{ topics: string[]; status: string }>
    ) => {
      const { topics, status } = action.payload;
      state.allTopics = state.allTopics.map((topic) => {
        return {
          ...topic,
          status: topics.includes(topic.annotation) ? status : topic.status,
        };
      });
    },
    setSelectedTopics: (state, action: PayloadAction<RowSelectionState>) => {
      state.selectedTopics = action.payload;
    },
    resetSelectedTopics: (state) => {
      state.selectedTopics = {};
    },
  },
});

export const {
  setSelectedEntity,
  setSelectedSubGraphId,
  setAllTopics,
  setTopicStatus,
  setSelectedTopics,
  resetSelectedTopics,
} = topologySlice.actions;

export default topologySlice.reducer;
