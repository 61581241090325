import { IconProps } from '..';

function CORTEX_XDR(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 46 46"
      // style="enable-background:new 0 0 46 46;"
      xmlSpace="preserve"
      {...props}
    >
      <rect
        fillRule="evenodd"
        clipRule="evenodd"
        fill="transparent"
        width="46"
        height="46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#00CC66"
        d="M25.7,9.1c0.1,0,0.1,0,0.2,0v5.4c-0.1,0-0.1,0-0.2,0c-4.8,0-8.6,3.8-8.6,8.5c0,4.7,3.9,8.5,8.6,8.5l0.2,0v5.4   l-0.2,0c-7.8,0-14.1-6.2-14.1-13.9S17.9,9.1,25.7,9.1z M25.9,31.5c4.7-0.1,8.4-3.9,8.4-8.5s-3.8-8.4-8.4-8.5V31.5z"
      />
    </svg>
  );
}

export default CORTEX_XDR;
