import { useEffect, useRef, useState } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@/components/shadcn/tooltip';

interface EllipsisContentProps {
  title: string;
  maxWidth: number | string;
  className?: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left' | undefined;
}
function EllipsisContent({
  title,
  maxWidth,
  className,
  tooltipPosition = 'top',
}: EllipsisContentProps) {
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);
  const contentRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setHasOverflow(
      (contentRef.current?.scrollWidth ?? 0) >
        (contentRef.current?.clientWidth ?? 0)
    );
  }, [title]);

  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger
        autoFocus={false}
        ref={contentRef}
        style={{ maxWidth }}
        asChild
      >
        <p
          className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${className}`}
        >
          {title}
        </p>
      </TooltipTrigger>
      {hasOverflow ? (
        <TooltipPortal container={document.body}>
          <TooltipContent
            side={tooltipPosition}
            className="dark:bg-[--page] dark:text-card-foreground max-w-[25vw]"
            autoFocus={false}
          >
            {title}
          </TooltipContent>
        </TooltipPortal>
      ) : null}
    </Tooltip>
  );
}

export default EllipsisContent;
