import {
  ANTHROPIC_API_ICON,
  ANTHROPIC_ICON,
  ANYSCALE_API_ICON,
  ANYSCALE_ICON,
  AWS_CLOUDTRAIL_ICON,
  AWS_EKS_ICON,
  AWS_ICON,
  AWS_ROUTE53_ICON,
  AZURE_AI_ICON,
  AZURE_AI_CONTENT_ICON,
  AZURE_ICON,
  AZURE_OPENAI_ICON,
  BEDROCK_ICON,
  CODE42_API_ICON,
  COHERE_API_ICON,
  COHERE_ICON,
  COMPUTE_ENGINE_ICON,
  CORTEX_XDR_ICON,
  CROWDSTRIKE_FDR_ICON,
  EBPF_ICON,
  EC2_ICON,
  EXTRAHOP_ICON,
  FIREWORKS_API_ICON,
  FIREWORKS_ICON,
  GCP_AI_PLATFORM_LOGS_ICON,
  GCP_CLOUD_AUDIT_LOGS_ICON,
  GCP_CLOUD_DNS_ICON,
  GCP_FLOW_LOGS_ICON,
  GCP_GKE_ICON,
  GCP_ICON,
  GCP_INVENTORY_ICON,
  GCP_STORAGE_ICON,
  GCP_VERTEX_AI_ICON,
  HUGGINGFACE_API_ICON,
  HUGGINGFACE_ICON,
  IAM_ICON,
  IconProps,
  K8S_ICON,
  MISTRAL_API_ICON,
  MISTRAL_ICON,
  OKTA_ICON,
  OPENAI_API_ICON,
  OPENAI_ICON,
  PINECONE_DB_ICON,
  PINECONE_ICON,
  S3_ICON,
  SAGEMAKER_ICON,
  SINGULR_ICON,
  UNKNOWN_ICON,
  GITLAB_ICON,
  MICROSOFT_DEFENDER_FOR_ENDPOINT_ICON,
  MICROSOFT_ENTRA_ID_ICON,
  ATLASSIAN_ICON,
  NOTION_ICON,
  ZOOM_ICON,
  HUBSPOT_ICON,
  SPLUNK_SERVICE_ICON,
  ZSCALER_ICON,
  AZURE_DATABRICKS,
} from '../assets/icons';

export const assetsVendorServicesIconMap: Record<
  string,
  (props: IconProps) => JSX.Element
> = {
  UNKNOWN: UNKNOWN_ICON,
  UNKNOWN_VENDOR_TYPE: UNKNOWN_ICON,
  UNKNOWN_SERVICE_TYPE: UNKNOWN_ICON,
  // SERVICES
  EC2: EC2_ICON,
  SAGEMAKER: SAGEMAKER_ICON,
  OPENAI_API: OPENAI_API_ICON,
  AZURE_AI: AZURE_AI_ICON,
  AZURE_OPENAI: AZURE_OPENAI_ICON,
  IAM: IAM_ICON,
  PINECONE_DB: PINECONE_DB_ICON,
  BEDROCK: BEDROCK_ICON,
  COMPUTE_ENGINE: COMPUTE_ENGINE_ICON,
  S3: S3_ICON,
  COHERE_API: COHERE_API_ICON,
  ANTHROPIC_API: ANTHROPIC_API_ICON,
  MISTRAL_API: MISTRAL_API_ICON,
  HUGGINGFACE_API: HUGGINGFACE_API_ICON,
  ANYSCALE_API: ANYSCALE_API_ICON,
  FIREWORKS_API: FIREWORKS_API_ICON,
  CODE42_API: CODE42_API_ICON,
  SPLUNK_SERVICE: SPLUNK_SERVICE_ICON,
  // VENDORS
  SINGULR: SINGULR_ICON,
  AWS: AWS_ICON,
  AWS_EKS: AWS_EKS_ICON,
  AWS_ROUTE53: AWS_ROUTE53_ICON,
  AWS_CLOUDTRAIL: AWS_CLOUDTRAIL_ICON,
  GCP: GCP_ICON,
  GCP_INVENTORY: GCP_INVENTORY_ICON,
  GCP_FLOW_LOGS: GCP_FLOW_LOGS_ICON,
  GCP_CLOUD_DNS: GCP_CLOUD_DNS_ICON,
  GCP_CLOUD_AUDIT_LOGS: GCP_CLOUD_AUDIT_LOGS_ICON,
  GCP_VERTEX_AI: GCP_VERTEX_AI_ICON,
  GCP_AI_PLATFORM_LOGS: GCP_AI_PLATFORM_LOGS_ICON,
  GCP_STORAGE: GCP_STORAGE_ICON,
  GCP_GKE: GCP_GKE_ICON,
  VERTEX_AI_SEARCH: GCP_VERTEX_AI_ICON,
  AZURE: AZURE_ICON,
  AZURE_INVENTORY: AZURE_ICON,
  AZURE_LOGS: AZURE_ICON,
  PINECONE: PINECONE_ICON,
  OPENAI: OPENAI_ICON,
  COHERE: COHERE_ICON,
  ANTHROPIC: ANTHROPIC_ICON,
  K8S: K8S_ICON,
  MICROSOFT_DEFENDER_FOR_ENDPOINT: MICROSOFT_DEFENDER_FOR_ENDPOINT_ICON,
  MICROSOFT_ENTRA_ID: MICROSOFT_ENTRA_ID_ICON,
  MISTRAL: MISTRAL_ICON,
  HUGGINGFACE: HUGGINGFACE_ICON,
  ANYSCALE: ANYSCALE_ICON,
  FIREWORKS: FIREWORKS_ICON,
  OKTA: OKTA_ICON,
  EBPF: EBPF_ICON,
  CORTEX_XDR: CORTEX_XDR_ICON,
  EXTRAHOP: EXTRAHOP_ICON,
  GITLAB: GITLAB_ICON,
  CROWDSTRIKE_FDR: CROWDSTRIKE_FDR_ICON,
  ATLASSIAN_SERVICE: ATLASSIAN_ICON,
  ZOOM_SERVICE: ZOOM_ICON,
  NOTION_SERVICE: NOTION_ICON,
  HUBSPOT_SERVICE: HUBSPOT_ICON,
  ZSCALER: ZSCALER_ICON,
  AZURE_AI_CONTENT: AZURE_AI_CONTENT_ICON,
  AZURE_DATABRICKS,
};

export const availablePublishersIcons: string[] = [
  'EleutherAI',
  'HuggingFaceH4',
  'ai4bharat',
  'Writer',
  'bigscience',
  'NousResearch',
  'NYTK',
  'nomic-ai',
  '01-ai',
  'AI-Sweden-Models',
  'tiiuae',
  'snorkelai',
  'cerebras',
  'THUDM',
  'augmxnt',
  'RWKV',
  'databricks',
  'openai',
  'sarvamai',
  'openchat',
  'mosaicml',
  'berkeley-nest',
  'nvidia',
  'deepseek-ai',
  'moreh',
  'upstage',
  'Salesforce',
  'cognitivecomputations',
  'lmsys',
  'mistralai',
  'microsoft',
  'anthropic',
  'openlm-research',
  'cohere',
  'stabilityai',
  'facebook',
  'codellama',
  'TencentARC',
  'OpenAssistant',
  'WizardLM',
  'teknium',
  'Deci',
  'allenai',
  'TinyLlama',
  'abacusai',
  'togethercomputer',
  'singulr',
  'okta',
  'ebpf',
].map((k) => k.toLowerCase());

export const GENERIC_TABLE_DEFAULT_TABLE_PAGESIZE = 20;

export const getAiServicesLogoIcon = (key: string) =>
  `https://ai-tools-favicons-reseyu8ug66.s3.amazonaws.com/${encodeURI(
    key
  )}.png`;
