/* eslint-disable @typescript-eslint/no-explicit-any */
import throttle from 'lodash.throttle';
import { MutableRefObject, useLayoutEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { FacetData, FilterOperation, StringTemplate } from 'src/generated';
import { ignoreDataKeys } from '../constants';

interface ContainerProps {
  isDisabled: boolean;
}

export function getValueFromAccessor(
  row: any,
  accessor: string,
  isReference: boolean
) {
  if (row) {
    if (isReference) {
      const rootKey = accessor?.split('.')?.[0];
      const dataObj = row?.[rootKey];
      if (!dataObj) {
        return [];
      }
      if (dataObj.length === 0) {
        return [];
      }
      if (typeof dataObj === 'object' && !Array.isArray(dataObj)) {
        return {
          id: dataObj?.id ?? '',
          name: dataObj?.name ?? '',
        };
      }
      return dataObj?.map(({ id, name, ...rows }: any) => {
        ignoreDataKeys?.forEach((k) => {
          if (rows?.[k]) {
            // eslint-disable-next-line no-param-reassign
            delete rows?.[k];
          }
        });
        return {
          id,
          name,
          rows: Object.keys(rows).length ? rows : null,
        };
      });
    }
    const keys = accessor?.split('.');
    if (keys?.length === 1) {
      if (keys?.[0] in row) return row?.[keys?.[0]];
    }
    let value = row;
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys?.[i];
      if (Array.isArray(value)) {
        value = value?.map((item) => item[key]);
      } else if (value && typeof value === 'object' && key in value) {
        value = value[key];
      } else {
        value = undefined;
        break;
      }
    }
    if (Array.isArray(value)) {
      return [...new Set(value)].join(', ');
    }
    return value !== undefined ? value : '';
  }
  return '';
}

const exceptionWords = [
  { singular: 'is', plural: 'are' },
  { singular: 'Policy', plural: 'Policies' },
  { singular: 'Property', plural: 'Properties' },
  { singular: 'Process', plural: 'Processes' },
  { singular: 'Process', plural: 'Processes' },
  { singular: 'Library', plural: 'Libraries' },
];
export const pluralise = (
  word: string,
  length: number,
  pluralSuffix = 's'
): string => {
  const exception = exceptionWords.find((w) => w.singular === word);
  if (exception) {
    if (length <= 1) {
      return exception.singular;
    }
    return exception.plural;
  }
  if (length <= 1) {
    return word;
  }
  return word + pluralSuffix;
};

export const onScrollStop = (elm: HTMLElement, callback: () => void) => {
  let isScrolling: NodeJS.Timeout;
  elm.addEventListener(
    'scroll',
    () => {
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        callback();
      }, 150);
    },
    false
  );
};

export const getCompactNumber = (
  num?: number | string,
  maximumFractionDigits: number = 0
) => {
  try {
    return (
      new Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits,
      }).format(Number(num) || 0) ?? '--'
    );
  } catch (e) {
    return num || '--';
  }
};

export const getContentFromRecommendation = (
  rec: StringTemplate | undefined,
  fallbackText = '--',
  getHighlights = false
): string | { type: string; text: string }[] => {
  if (!rec) {
    return fallbackText;
  }
  let res = rec.template;
  if (getHighlights) {
    const str = rec.template;
    if (rec?.parameters?.length === 0) {
      return rec.template || '';
    }
    const startTokens = str?.split('{{')[0]
      ? [{ type: 'TEXT', text: str?.split('{{')[0] }]
      : [];

    const restTokens =
      str
        ?.split('{{')
        ?.filter((t) => !!t)
        ?.map((t) => {
          const endTokens = t.split('}}');
          const text =
            rec?.parameters?.find((p) => p.key === endTokens?.[0])?.value ?? '';

          const formattedText = Number.isNaN(Number(text))
            ? text
            : getCompactNumber(Number(text), 2);

          return [
            {
              type: 'HIGHLIGHT',
              text: String(formattedText),
            },
            { type: 'TEXT', text: endTokens[1] },
          ];
        })
        ?.flat() || [];
    return [...startTokens, ...restTokens];
  }

  rec?.parameters?.forEach((param) => {
    const formattedValue = Number.isNaN(Number(param?.value))
      ? param?.value
      : getCompactNumber(Number(param?.value), 2);
    res = res?.replaceAll(`{{${param.key}}}`, String(formattedValue) ?? '');
  });

  return res || '';
};

export const snakeCaseToSmallCase = (str: string): string =>
  (str ?? '')?.replaceAll('_', ' ').toLowerCase();

export const CopyToClipBoard = (text: string): void => {
  navigator.clipboard.writeText(text);
};

export const getSelectCommonStyles = (isError: boolean = false) => ({
  container: ({ isDisabled }: ContainerProps) =>
    `!bg-transparent rounded-md ${isDisabled ? '!opacity-50 !cursor-not-allowed' : ''}`,
  control: () =>
    `!bg-transparent !border ${isError ? '!border-rose-400' : '!border-input'} !shadow-none rounded-md !text-sm`,
  menu: () => '!bg-background px-4 py-2 !border',
  menuList: () => '!bg-background !max-h-[180px] ',
  placeholder: () => '!text-slate-400 !text-sm',
  multiValue: () =>
    '!bg-transparent !rounded-full !border-slate-700 !border !mx-[3px]',
  multiValueLabel: () => '!text-white !tracking-wide !pl-2',
  multiValueRemove: () => '!bg-transparent !rounded-full hover:!text-slate-800',
  indicatorSeparator: () => 'dark:!bg-slate-800',
  input: () => '!text-foreground',
  singleValue: () => '!text-white',
  option: () =>
    'relative flex w-full rounded-md cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 !cursor-pointer',
});

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatMetricDisplayValue = (value: number, unit: string) => {
  switch (unit) {
    case 'NUMBER_METRIC_UNIT':
      return (value || 0) % 1 === 0 ? value?.toString() : value?.toFixed(2);
    case 'PERCENT_METRIC_UNIT':
      return `${value} %`;
    case 'BYTES_METRIC_UNIT':
      return formatBytes(value);
    default:
      return value?.toString();
  }
};

export const getTopNAndOthers = (
  data: { name: string; value: number }[],
  n: number
): { name: string; value: number; url?: string; centerUrl?: string }[] => {
  if (data.length > 0) {
    const sortedData = data.sort((a, b) => (a?.value > b?.value ? -1 : 1));
    const others = sortedData.slice(n);
    const othersSum = others.reduce((acc, curr) => acc + curr.value, 0);
    if (n >= data.length) {
      return sortedData.slice(0, n);
    }
    return [
      ...sortedData.slice(0, n),
      { value: othersSum, name: 'Others', url: undefined },
    ];
  }
  return [];
};

export const nullValues = ['', null, undefined];

// Show numbers as K,M,B etc
export const prettyNumber = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 2,
}).format;

export const getLogoUrl = (key: string) =>
  `https://ai-tools-favicons-reseyu8ug66.s3.amazonaws.com/${encodeURI(
    key
  )}.png`;

export interface IFilterEntity {
  column: string;
  values: string[];
  operation?: FilterOperation;
}

export function useFiltersFromUrl(filterKey = 'filters') {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const filters = searchParams.getAll(filterKey)?.[0];
    if (!filters) {
      return [];
    }
    const filtersFromUrl = JSON.parse(filters) as IFilterEntity[];
    return filtersFromUrl;
  }, [filterKey, searchParams]);
}

export function isItUrl(str: string) {
  try {
    return str?.toLowerCase()?.includes('http');
  } catch (e) {
    return false;
  }
}

export function renderNormalizedText(text?: string) {
  try {
    if (!text || text === 'NaN') {
      return 'Unavailable';
    }

    return isItUrl(text) ? text : text.replaceAll('_', ' ');
  } catch {
    return 'Unavailable';
  }
}

export function useChartResize<R, T>({
  ref,
  data,
}: {
  ref: MutableRefObject<R>;
  data: T;
}) {
  const [updateCount, setUpdateCount] = useState(0);

  useLayoutEffect(() => {
    const observeTarget = ref.current;
    if (!observeTarget) return;
    const throttledResize = throttle(() => {
      setUpdateCount((prev) => prev + 1);
    }, 500);

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      entries.forEach(() => {
        throttledResize();
      });
    });
    observer.observe(observeTarget as unknown as Element);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [data, ref]);

  return updateCount;
}

export const capitalize = (s: string) =>
  (s &&
    typeof s === 'string' &&
    String(s?.[0]).toUpperCase() + String(s).slice(1)) ||
  '';

export const capitalizeEachWord = (s: string) => {
  if (!s) return '';
  return s
    ?.toLowerCase()
    ?.split(' ')
    ?.map(
      (word) => (word?.charAt(0)?.toUpperCase() || '') + (word?.slice(1) || '')
    )
    ?.join(' ');
};

export const formatEpochTime = (
  epochTime: number | string | null | undefined,
  formatString = 'MMM d, yyyy h:mm a'
): string => {
  if (!epochTime) return '--';
  try {
    let timestamp =
      typeof epochTime === 'string' ? parseInt(epochTime, 10) : epochTime;
    if (Number.isNaN(timestamp)) return '--';
    if (timestamp?.toString()?.length <= 10) {
      timestamp *= 1000;
    }
    const date = new Date(timestamp);
    if (date?.getFullYear() < 1970 || date?.getFullYear() > 2100) {
      return '--';
    }
    return format(date, formatString);
  } catch (error) {
    return '--';
  }
};

export const getTopAndOthers = (
  data: FacetData[],
  n: number
): {
  name: string;
  value: number;
  url?: string[];
  centerUrl?: string;
  negate?: boolean;
}[] => {
  if (data.length > 0) {
    const sortedData = data?.sort((a, b) =>
      (a?.count || 0) > (b?.count || 0) ? -1 : 1
    );
    const list = sortedData.slice(0, n)?.map((facet) => ({
      name: facet?.value ?? '',
      value: facet?.count ?? 0,
      url: [facet?.value ?? ''],
    }));

    const others = sortedData.slice(n);
    const othersSum = others.reduce((acc, curr) => acc + (curr?.count || 0), 0);
    if (n >= data?.length) {
      return list;
    }

    return [
      ...list,
      {
        value: othersSum,
        name: 'Others',
        url: list?.map((d) => d?.url?.[0] ?? ''),
        negate: true,
      },
    ];
  }
  return [];
};
