import { IconProps } from '..';

function IAM(props: IconProps) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2068_87402)">
        <path
          d="M4.65625 5.73633L4 5.9322L4.67969 6.00416L4.65625 5.73633ZM4.62359 6.90925L8 7.1066L11.3764 6.90925L8 6.3077L4.62359 6.90925ZM11.3281 6.00416L12 5.9322L11.3281 5.76847V6.00416Z"
          fill="#3C4929"
        />
        <path
          d="M8.91875 11.083L10.6877 11.4657L8.91406 11.9547L8.91875 11.083Z"
          fill="#B7CA9D"
        />
        <path
          d="M4.62359 3.63562V2.54423L8 1.00098L8.01506 1.01255L8.00731 2.39802L8 2.40093L7.98734 2.43284L6.14053 3.11438L6.12359 5.51521L7.10647 5.36408L8 5.20064V15.0005L6.98847 14.5384V13.2307L6.10241 12.9068V6.77384L4.62359 6.90916V5.81798L4 5.93211V3.86351L4.62359 3.63562Z"
          fill="#4B612C"
        />
        <path
          d="M7.10566 5.3642L6.10156 5.54772V3.09449L7.10566 3.3807V5.3642ZM9.74681 1.79973L11.3751 2.54423V3.63562L11.9992 3.86351V5.93194L11.3751 5.81798V6.90916L10.5801 6.83666L9.89631 6.77384V8.00055H11.3756V9.12284L10.6882 9.17924V10.3109L9.01028 10.6158V11.9234L10.6843 11.4691L10.6882 12.6227L9.01028 13.2307V14.5384L7.99916 15.0005V5.20064L8.89203 5.36377L9.84775 5.47913V3.11409L8.02469 2.47135L7.99916 2.40093V1.00098L9.74681 1.79973Z"
          fill="#759C3E"
        />
        <path
          d="M9.01028 2.76981L7.99916 2.40039L6.10156 3.09375L7.10566 3.37996L9.01028 2.76981Z"
          fill="#3C4929"
        />
        <path
          d="M6.10156 3.09375L7.99916 2.40039L8.39838 2.54621L9.01028 2.76981L9.89631 3.09415L9.84897 3.13818L8.94659 3.39504L8.89222 3.37996L7.99916 3.09375L7.10566 3.37996L6.10156 3.09375Z"
          fill="#3C4929"
        />
        <path
          d="M8.89258 3.38053L9.89667 3.09473V5.54775L8.89258 5.3636V3.38053Z"
          fill="#4B612C"
        />
      </g>
      <defs>
        <clipPath id="clip0_2068_87402">
          <rect width="8" height="14" fill="white" transform="translate(4 1)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IAM;
